import { ABM_StatusEnum } from '@prisma/components/generic-status-select/generic-item-status-select.service';
import { IMV2_discriminator, IMV2_ItemAssortmentBehavior, IMV2_priceinitializationmethod } from '../edit/stores/IMV2_FormStateModel';

const IMV2ListWithoutMap = {
  items: [
    {
      id: 9158,
      code: 'B12345',
      name: 'Coca Cola Fantastica',
      shortName: 'Coca Fant.',
      ean: '79999990000',
      imageUrl: '',
      categoryId: 641,
      categoryCode: '70-01-00',
      parentCategoryName: 'Bebidas sin alcohol',
      categoryName: 'Gaseosas',
      baseUnitOfMeasureCode: 'U',
      isBlockedForSales: false,
      isBlockedForPurchase: false,
      salesTaxRate: 0.21,
      tax: null,
      purchaseTaxRate: null,
      itemCreatedOn: '2021-06-09T00:00:00',
      status: ABM_StatusEnum.Current,
      clonedFromItemId: null,
      recipe: null,
      combo: null,
      tagValues: [],
      unitsOfMeasure: [],
      codes: [],
      vendors: [],
      discriminator: IMV2_discriminator.Item,
      assortmentBehavior: IMV2_ItemAssortmentBehavior.BasedOnAssortment,
      priceInitializationMethod: IMV2_priceinitializationmethod.BasedOnComponents,
      customItemOfStoreId: null,
      responseSuccess: true,
      responseMessages: [],
      publishStatus: 'Processing',
    },
    {
      id: 9214,
      code: 'AT12345',
      name: 'AleTest1',
      shortName: 'AleTest Short Desc2',
      ean: '1234567890123',
      imageUrl: '',
      categoryId: 554,
      categoryCode: null,
      parentCategoryName: 'Bebidas sin alcohol',
      categoryName: 'Gaseosas',
      baseUnitOfMeasureCode: 'U',
      isBlockedForSales: false,
      isBlockedForPurchase: false,
      salesTaxRate: null,
      tax: null,
      purchaseTaxRate: null,
      itemCreatedOn: '2021-06-14T18:40:29.692',
      status: ABM_StatusEnum.New,
      clonedFromItemId: null,
      recipe: null,
      combo: null,
      tagValues: [],
      unitsOfMeasure: [],
      codes: [],
      vendors: [],
      discriminator: IMV2_discriminator.Item,
      assortmentBehavior: IMV2_ItemAssortmentBehavior.BasedOnAssortment,
      priceInitializationMethod: IMV2_priceinitializationmethod.BasedOnComponents,
      customItemOfStoreId: null,
      responseSuccess: true,
      responseMessages: [],
    },
    {
      id: 9217,
      code: 'AT212345',
      name: 'AleTest2',
      shortName: 'AleTest Short Desc2',
      ean: '123456789',
      imageUrl: '',
      categoryId: -1,
      categoryCode: 'NOCAT',
      parentCategoryName: 'Bebidas sin alcohol',
      categoryName: 'Gaseosas',
      baseUnitOfMeasureCode: 'U',
      isBlockedForSales: false,
      isBlockedForPurchase: false,
      salesTaxRate: null,
      tax: null,
      purchaseTaxRate: null,
      itemCreatedOn: '2021-06-18T17:50:17.032',
      status: ABM_StatusEnum.Modified,
      clonedFromItemId: null,
      recipe: null,
      combo: null,
      tagValues: [],
      unitsOfMeasure: [],
      codes: [],
      vendors: [],
      discriminator: IMV2_discriminator.Item,
      assortmentBehavior: IMV2_ItemAssortmentBehavior.BasedOnAssortment,
      priceInitializationMethod: IMV2_priceinitializationmethod.BasedOnComponents,
      customItemOfStoreId: null,
      responseSuccess: true,
      responseMessages: [],
    },
  ],
  totalItemCount: 3,
  pageIndex: 0,
  pageSize: 10,
};

/* 
{
  items: [
    {
      id: 44,
      imageUrl: null,
      name: 'Coca Cola 1.5L',
      code: 78965220,
      discriminator: 'Retail',
      parentCategoryName: 'Bebidas sin alcohol',
      categoryName: 'Gaseosas',
    },
  ],
  totalItemCount: 1,
  pageIndex: 0,
  pageSize: 10,
}; */

const filters = {
  itemTypes: [1, 2],
  isDeleted: false,
  tags: [],
  categories: [],
  subCategories: [],
  query: '',
  itemStatuses: [0, 1, 5],
  itemAssortmentBehaviors: [0, 1],
  customItemOfStoreIds: [],
  itemCreatedOnFrom: '2021-06-01T00:00:00+00:00',
  itemCreatedOnTo: '2022-06-01T00:00:00+00:00',
};

export const IMV2ListDummyData = {
  IMV2ListWithoutMap,
  filters,
};
