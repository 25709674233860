import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel-chatDemo/quick-panel.component';
import { SharedModule } from 'app/shared.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ColumnSelectorDialogComponent } from './components/column-selector-dialog.component';
import { DataBricksAiGenieQueryProcessChartComponent } from './components/databricks-genie-ai-query-process-chart.component';
import { DataBricksAiGenieQueryProcessTableComponent } from './components/databricks-genie-ai-query-process-table.component';
import { QuickPanelV2DBComponentAi } from './quick-panel-chatDataBricksGenie/quick-panel-data-chat-V2DB.component';
import { QuickPanelComponentAi } from './quick-panel-chatDemo/quick-panel-data-chat.component';
import { DataBricksAiGenieChatService } from './services/databricks-genie-ai-chat.service';

@NgModule({
  declarations: [
    QuickPanelComponent,
    QuickPanelComponentAi,
    QuickPanelV2DBComponentAi,
    DataBricksAiGenieQueryProcessTableComponent,
    DataBricksAiGenieQueryProcessChartComponent,
    ColumnSelectorDialogComponent,
  ],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    SharedModule,
    FuseSharedModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    ScrollingModule,
    MatButtonModule,
    NgApexchartsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  exports: [QuickPanelComponent, QuickPanelComponentAi, QuickPanelV2DBComponentAi],
  providers: [DataBricksAiGenieChatService],
})
export class QuickPanelModule {}
