import { _error, _warn } from '@shared/aux_helper_environment';
import {
  _cloneDeep,
  _get,
  _getNewNumberId,
  _setElementAttributes,
  _setElementStyles,
  _uniqueElementsByKey,
} from '@shared/aux_helper_functions';
import { _getStaticBrandCustomization } from 'core/services/ian-core-singleton.service';
import { _auxCalcGroupImgItemsAndTotalSize, _recalcPosAndSize_normalShelf } from '../svgComponents/svgShelfs/svgDecorator-normalShelf';
import { _recalcPosAndSize_pit } from '../svgComponents/svgShelfs/svgDecorator-pitShelf';
import { _recalcPosAndSize_simplePeg } from '../svgComponents/svgShelfs/svgDecorator-simplePegShelf';
import {
  _auxReduceSingleProdDataStoresInfo_NEW_DES_2009,
  _auxReduceSingleProdDataStoresInfo_OLD,
} from './auxReduceSingleProdDataStoresInfo';

function getBconf() {
  let rvConf: any = _get(_getStaticBrandCustomization(), 'spaces', {});

  //Map brandSpacesDefault values
  if (rvConf !== null && typeof rvConf === 'object') {
    for (let key in rvConf) {
      if (rvConf[key] == null || rvConf[key] === 'null') {
        delete rvConf[key];
      }
    }
  } else {
    rvConf = {};
  }

  return rvConf;
}

const _BCONF: any = getBconf();

export const DEFAULT_OFFSETSHELF = 2.5;
export const DEFAULT_OFFSETBOX = 3.5;
export const DEFAULT_OFFSET_MIN_PRD_SEP_X = 0; // or 0.5
export const DEFAULT_NORMAL_SHELF_GUIDE_WIDTH = 4;
export const DEFAULT_STAGE_COLOR = '#eee';
export const DEFAULT_SHELF_COLOR = '#fff';
export const DEFAULT_OFFSET_STAGE = 40;
export const DEFAULT_OFFSET_MODULES = 0.5; /*Separación de muebles / prevVal 15*/
export const DEFAULT_FINGER_SPACE = 0; // valor optimo: 2.5;
export const DEFAULT_MIN_RATIO_FOR_AUTOSPACE = 0.95;
export const DEFAULT_AUTOSPACE = false;
export const FORCE_DUMMY_PROD_LIST = false;
export const FORCE_DUMMY_POP_LIST = false;
export const DEFAULT_PROD_LIST_NO_IMAGE = './assets/images/spc_assets/no_prd_img.png';
export const DEFAULT_BLOCK_SIMPLE_FILTERS = false;
export const DEFAULT_SHOW_AUDIT = true;
export const DEFAULT_SHOW_COMMENTS = true;
export const DEFAULT_AVOID_PEGSHELF_OVERLAY = true; //DES-2079
export const DEFAULT_INTERNAL_UOM_PROP = 'unitOfMeasureId';
export const DEFAULT_PALLET_HEIGHT = 10;

//FIX DES-4115 constantes dinámicas
export let DEFAULT_INTERNAL_CODE_PROP = 'code';
export let DEFAULT_PRD_LIST_PAGE_SIZE = 25;

document?.addEventListener?.('CUSTOMIZATION_IS_LOADED', () => {
  const _BCONF: any = getBconf();
  DEFAULT_PRD_LIST_PAGE_SIZE = _BCONF.DEFAULT_PRD_LIST_PAGE_SIZE ?? DEFAULT_PRD_LIST_PAGE_SIZE;
  DEFAULT_INTERNAL_CODE_PROP = _BCONF.DEFAULT_INTERNAL_CODE_PROP ?? DEFAULT_INTERNAL_CODE_PROP;
});

export const DEFAULT_BOXDATA = _BCONF.DEFAULT_BOXDATA || {
  size: { w: 0, h: 0 },
  position: { x: 0, y: 0 },
  fill: '#d4d4d4',
};
export const DEFAULT_BOTTOMDATA = _BCONF.DEFAULT_BOTTOMDATA || {
  padding: 6,
  size: { w: 0, h: 4 },
  position: { x: 0, y: 0 },
  fill: '#a4a4a4',
};

/*MINI AXUSs*/
export const _round = (value, places = 2) => {
  const multiplier = Math.pow(10, places);
  return Math.round(value * multiplier) / multiplier;
};

export const _getTmpProdId = () => {
  return 'PRD-' + String(_getNewNumberId(false)).slice(0, 12);
};

export const _getTmpAreaId = () => {
  return 'ARE-' + String(_getNewNumberId(false)).slice(0, 12);
};

export const _getTmpModuleId = () => {
  return 'MOD-' + String(_getNewNumberId(false)).slice(0, 12);
};

export const _getTmpShelfId = () => {
  return 'SHELF-' + String(_getNewNumberId(false)).slice(0, 12);
};

export const _getTmpFloatfId = () => {
  return 'FLOAT-' + String(_getNewNumberId(false)).slice(0, 12);
};

export const _getTmpFloorPlanBGId = () => {
  return 'FP_BGFLOAT-' + String(_getNewNumberId(false)).slice(0, 12);
};

export const _getTmpFloorPlanModuleId = () => {
  return 'FP_FLOAT-' + String(_getNewNumberId(false)).slice(0, 12);
};

export const _getTmpFloorPlanModuleChildId = () => {
  return 'FP_FLOAT-CHILD' + String(_getNewNumberId(false)).slice(0, 12);
};

export const _getTmpStageId = () => {
  return 'STAGE-' + String(_getNewNumberId(false)).slice(0, 12);
};

export const _set_internalItemId = (itemId, unitOfMeasureId = 'null') => {
  if (!itemId) {
    _error('no itemId', itemId, unitOfMeasureId);
    return null;
  }
  return 'ID-' + itemId + '-' + unitOfMeasureId;
};

export const _aux_getHumanType = (type, translate = null) => {
  if (!type) return null;
  if (translate && translate.instant) return translate.instant('SPACES.EDITOR.TYPES.' + type, null, '');

  /*Muebles*/
  if (type === 'normalShelf-module') return 'Estantería';
  if (type === 'simplePeg-module') return 'Ganchera';
  if (type === 'pitShelf-module') return 'Pozo';
  if (type === 'refrigerator-module') return 'Heladera';

  /*Estantes*/
  if (type === 'normalShelf-level') return 'Estantería';
  if (type === 'simplePeg-level') return 'Ganchera';
  if (type === 'pitShelf-level') return 'Pozo';

  /*POP*/
  if (type === 'float-pop-impulse-strip-simple') return 'Tira de impulso';
};

export const _aux_getShelfTypeByModuleType = type => {
  let shelfType = null;
  if (type === 'normalShelf-module') shelfType = 'normalShelf-level';
  if (type === 'simplePeg-module') shelfType = 'simplePeg-level';
  if (type === 'pitShelf-module') shelfType = 'pitShelf-level';
  if (type === 'refrigerator-module') shelfType = 'normalShelf-level';
  if (!shelfType) {
    _warn('No type', type);
  }
  return shelfType;
};

export const _aux_popMaterialTypes = [
  {
    id: 'float-pop-impulse-strip-simple',
    value: 'Tira de Impulso',
  },
];

export const _getAllUniqueStoresFromPlanogram = planogram => {
  if (!planogram || !planogram.modules) return [];

  const allStores = _uniqueElementsByKey(
    planogram.modules
      .reduce((acc, mod) => {
        return [...acc, ...(mod.shelfs || [])];
      }, [])
      .reduce((acc, shelf) => {
        return [...acc, ...(shelf.prods || [])];
      }, [])
      .reduce((acc, prd) => {
        return [...acc, ...(_get(prd, 'itemData.storesInfo', []) || [])];
      }, [])
      .filter(store => store && store.storeId),
    'storeId'
  ).map(store => {
    return { storeId: store.storeId, storeName: store.storeName };
  });

  return allStores;
};

/*Calc sizes*/
export const _calcPlanogramPosAndSize = (modules, view, $offset_stage = DEFAULT_OFFSET_STAGE) => {
  if (!modules || !modules.length) return;
  const isForPrint = modules[0]._isPrint;

  const offset_stage = isForPrint ? 0 : $offset_stage;
  const offset_mod = isForPrint ? 0 : DEFAULT_OFFSET_MODULES;

  let totalWidth = offset_stage;
  let maxModuleHeight = 0;

  //Calc size & posx
  if (modules) {
    modules = modules.map((mod, i) => {
      mod = _calcModulePosAndSize(mod, view);

      mod._position = mod._position || { x: 0, y: 0 };
      mod._size = mod._size || { w: 0, h: 0 };

      let offset_mod_tmp = offset_mod + (mod.offsetBox || DEFAULT_OFFSETBOX) * 2;
      mod._position.x = totalWidth;
      totalWidth += mod.width + offset_mod_tmp;
      if (mod._size.h > maxModuleHeight) maxModuleHeight = mod._size.h;

      return mod;
    });

    totalWidth += -offset_mod + offset_stage;

    //Calc posY
    modules = modules.map(mod => {
      mod._position.y = _round((isForPrint ? 0 : offset_stage) + maxModuleHeight - mod._size.h);
      return mod;
    });
  }

  return { modules, totalWidth, totalHeight: maxModuleHeight + offset_stage };
};

export const _calcModulePosAndSize = ($module, view) => {
  if (!$module) return $module;

  const _tmp_size = { w: 0, h: 0 };
  const module = _clonePlanogram({ ...$module, shelfs: null });

  module.shelfs = $module.shelfs;

  module.offsetShelf = module.offsetShelf || DEFAULT_OFFSETSHELF;
  module.offsetBox = module.offsetBox || DEFAULT_OFFSETBOX;
  module.boxData = module.boxData || _cloneDeep(DEFAULT_BOXDATA);

  module.bottomData = module.bottomData || _cloneDeep(DEFAULT_BOTTOMDATA);
  module.shelfs = $module.shelfs.map((shelf, i) => _calcShelfPosAndSize(shelf, i, module, view));

  _tmp_size.w = module.width + module.offsetBox * 2;

  module.boxData = module.boxData || {};
  module.boxData.size = module.boxData.size || {};

  module.boxData.size.w = _tmp_size.w;
  module.boxData.size.h = _calcShelfPosY(-1, module, view);

  module.boxData.position = module.boxData.position || {};

  module.boxData.position.y = 0;
  module.boxData.position.x = 0;

  module.bottomData.position.x = _round(module.bottomData.padding);
  module.bottomData.position.y = _round(module.boxData.position.y + module.boxData.size.h);
  module.bottomData.size.w = _round(_tmp_size.w - module.bottomData.padding * 2);

  _tmp_size.h = module.bottomData.size.h + module.boxData.size.h;

  module._size = _tmp_size;
  return module;
};

export const _calcShelfPosAndSize = ($shelf, i, module, view) => {
  if (!$shelf) return $shelf;

  let shelf = _clonePlanogram({ ...$shelf, prods: null });
  shelf.prods = $shelf.prods;

  shelf._position = { x: _round(module.offsetBox), y: _calcShelfPosY(i, module, view) };
  shelf._size = { w: _round(module.width), h: _round(shelf.height) };

  if (view === 'PITSHELFVIEW' && shelf.type === 'pitShelf-level') {
    //Altura intercambiada para pozos en modo vista de planta
    shelf._size.h = _round(shelf.depth || module.depth);
  }

  return shelf;
};

export const _calcShelfPosY = (shelfIndex, module, view) => {
  if (!module.shelfs || !module.shelfs.length) return 0;
  let acc = module.offsetBox;
  if (!shelfIndex) return acc;
  let size = shelfIndex === -1 ? module.shelfs.length : shelfIndex;

  for (let i = 0; i < size; i++) {
    let height = module.shelfs[i].height;
    if (view === 'PITSHELFVIEW' && module.shelfs[i].type === 'pitShelf-level') {
      //Altura intercambiada para pozos en modo vista de planta
      height = module.shelfs[i].depth || module.depth;
    }
    const offsetShelf = module.offsetShelf;
    const offsetBox = module.offsetBox;
    acc += height + (i !== module.shelfs.length - 1 ? offsetShelf : offsetBox);
  }

  return _round(acc);
};

/*Solo para información*/
export const _calcModuleHeight = (module, withOffsets = true) => {
  if (!module.shelfs || !module.shelfs.length) return 0;
  let acc = withOffsets ? module.offsetBox : 0;
  let size = module.shelfs.length;

  for (let i = 0; i < size; i++) {
    const height = module.shelfs[i].height;
    const offsetShelf = withOffsets ? module.offsetShelf : 0;
    const offsetBox = withOffsets ? module.offsetBox : 0;
    acc += height + (i !== module.shelfs.length - 1 ? offsetShelf : offsetBox);
  }

  return _round(acc);
};

export const _auxSetModuleOrderInfo = modules => {
  if (!modules) return modules;

  return modules
    .map((obj, i) => {
      return {
        ...obj,
        order: i,
        _isFirst: i === 0,
        _isLast: i === modules.length - 1,
        shelfs: _auxSetShelfsOrderInfo(obj.shelfs || [], obj),
      };
    })
    .filter(Boolean);
};

export const _auxSetFloatInfo = floats => {
  if (!floats) return floats;

  return floats
    .map((float, i) => {
      if (!float.prods) return float;
      return {
        ...float,
        prods: _auxReduceProdsStoresInfoItemData(float.prods),
      };
    })
    .filter(Boolean);
};

export const _auxSetShelfsOrderInfo = (shelfs, module) => {
  const moduleWidth = module.width;
  const moduleDepth = module.depth || 0;

  if (!moduleWidth) {
    _warn('NO moduleWidth', moduleWidth);
    return;
  }

  return shelfs
    .map((obj, i) => {
      if (!obj) return null;
      return {
        ...obj,
        width: moduleWidth,
        order: i,
        _depth: moduleDepth || obj.depth || Infinity,
        _isFirst: i === 0,
        _isLast: i === shelfs.length - 1,
        prods: _auxReduceProdsStoresInfoItemData(
          _auxRecalcProdsPosAndSize({ ...obj, width: moduleWidth, depth: obj.depth || moduleDepth })
        ),
      };
    })
    .filter(Boolean);
};

export const _recalcPlanogramProdsDataByStoresFilter = (planogram, filterStores: (string | number)[] = null) => {
  if (!planogram || !filterStores || !filterStores.length) return planogram;

  const filteredPlanogramModules =
    planogram.modules && planogram.modules.length
      ? planogram.modules.map(mod => {
          if (!mod || !mod.shelfs || !mod.shelfs.length) return mod;
          return {
            ...mod,
            shelfs: mod.shelfs.map(shelf => {
              if (!shelf || !shelf.prods || !shelf.prods.length) return shelf;
              return {
                ...shelf,
                prods: _auxReduceProdsStoresInfoItemData(shelf.prods, false, filterStores),
              };
            }),
          };
        })
      : planogram.modules;

  const filteredPlanogramFloats =
    planogram.floats && planogram.floats.length
      ? planogram.floats.map(float => {
          if (!float || !float.prods || !float.prods.length) return float;
          return {
            ...float,
            prods: _auxReduceProdsStoresInfoItemData(float.prods, false, filterStores),
          };
        })
      : planogram.floats;

  const rv = { ...planogram, modules: filteredPlanogramModules, floats: filteredPlanogramFloats };

  return rv;
};

export const _auxReduceProdsStoresInfoItemData = (prods, abortInStoreInfoProcessed = true, filterStores?) => {
  if (!prods || !prods.length) return prods;
  return prods.map(prd => {
    return { ...prd, itemData: _auxReduceSingleProdDataStoresInfo(prd.itemData, abortInStoreInfoProcessed, filterStores) };
  });
};

export const _auxReduceProdsStoresInfo = (prods, abortInStoreInfoProcessed = true, $filterStores?) => {
  if (!prods || !prods.length) return prods;
  return prods.map(prd => {
    return _auxReduceSingleProdDataStoresInfo(prd, abortInStoreInfoProcessed, $filterStores);
  });
};

export const _auxReduceSingleProdDataStoresInfo = (
  prodData,
  abortInStoreInfoProcessed = true,
  filterStores: (string | number)[] = null,
  salesRatio: number = 1 // Solo para analítica de layouts
) => {
  const use_NEW_DES_2009 = true; //DES-2009

  if (use_NEW_DES_2009) {
    return _auxReduceSingleProdDataStoresInfo_NEW_DES_2009(prodData, abortInStoreInfoProcessed, filterStores, salesRatio);
  } else {
    return _auxReduceSingleProdDataStoresInfo_OLD(prodData, abortInStoreInfoProcessed, filterStores);
  }
};

export const _auxRecalcProdsPosAndSize = shelf => {
  if (!shelf.type) {
    _warn('No shelf.type', shelf);
    return;
  }

  let prods = [];

  if (shelf.type === 'normalShelf-level') prods = _recalcPosAndSize_normalShelf(shelf);
  if (shelf.type === 'simplePeg-level') prods = _recalcPosAndSize_simplePeg(shelf);
  if (shelf.type === 'pitShelf-level') prods = _recalcPosAndSize_pit(shelf);

  return prods.filter(Boolean);
};

/*Asigna el tamaño de itemData.size a size */
export const _calcSizeProd = obj => {
  let tmpSize = obj && obj.itemData && obj.itemData.size ? obj.itemData.size : null;

  if (!tmpSize) {
    if (true) console.warn('[_calcSizeProd] no itemData size', obj);
    return { w: 0, h: 0 };
  }

  //Con Stacks o Facings (normalShelf / ya calcula las rotaciones tmb)
  if (obj.stacks || obj.facings) {
    return _auxCalcGroupImgItemsAndTotalSize(obj).totalSize;
  }

  if (obj.rotation) {
    const totalBound = _calcRotateTransforms(tmpSize.w, tmpSize.h, obj.rotation || 0);
    tmpSize = { w: totalBound.w, h: totalBound.h };
  }

  return { w: _round(tmpSize.w), h: _round(tmpSize.h) };
};

/* Si hay un rotación calcula las transformaciones */
export const _calcRotateTransforms = function (w, h, $ang) {
  const ang = $ang * (Math.PI / 180);
  const sin = Math.sin(ang);
  const cos = Math.cos(ang);

  const bw = Math.abs(w * cos) + Math.abs(h * sin);
  const bh = Math.abs(w * sin) + Math.abs(h * cos);
  const delta = w - bw + (h - bh);

  return {
    w: bw,
    h: bh,
    originX: w * 0.5,
    originY: h * 0.5,
    offsetX: (h - bh) * 0.5 - delta * 0.5,
    offsetY: (w - bw) * 0.5 - delta * 0.5,
  };
};

/* Borra filtros para comparaciones */
export const _normaliceProdFilters = function (filters) {
  const _filters = _cloneDeep(filters);

  delete _filters.stores;
  delete _filters.pageIndex;
  delete _filters.pageSize;
  delete _filters.exhibition;
  delete _filters.noExhibition;

  delete _filters.isBlockedForSales;
  delete _filters.isNotBlockedForSales;

  if (!_filters.categories) delete _filters.subCategories;

  if (_filters.tags) _filters.tags = (_filters.tags || []).filter(tag => (tag.includedValues || []).length && tag.tagId);
  if (_filters.tags && !_filters.tags.length) delete _filters.tags;

  return _filters;
};

/*
    _clonePlanogram
*/
export const _clonePlanogram = x => {
  return _cloneDeepWithLimit(x, ['itemData']);
};

const _cloneDeepWithLimit = function (x, limit: String[] = null) {
  if (typeof x !== 'object') return x;

  let k,
    tmp,
    str = Object.prototype.toString.call(x);

  if (str === '[object Object]') {
    if (x.constructor !== Object && typeof x.constructor === 'function') {
      tmp = new x.constructor();
      for (let k in x) {
        if (tmp.hasOwnProperty(k) && tmp[k] !== x[k]) {
          tmp[k] = _cloneDeepWithLimit(x, limit);
        }
      }
    } else {
      tmp = {}; // null
      for (let k in x) {
        if (x.hasOwnProperty(k)) {
          if (limit && limit.includes(k) && x[k]) return x;

          if (k === '__proto__') {
            Object.defineProperty(tmp, k, {
              value: _cloneDeepWithLimit(x[k], limit),
              configurable: true,
              enumerable: true,
              writable: true,
            });
          } else {
            tmp[k] = _cloneDeepWithLimit(x[k], limit);
          }
        }
      }
    }
    return tmp;
  }

  if (str === '[object Array]') {
    k = x.length;
    for (tmp = Array(k); k--; ) {
      tmp[k] = _cloneDeepWithLimit(x[k], limit);
    }
    return tmp;
  }

  if (str === '[object Set]') {
    tmp = new Set();
    x.forEach(function (val) {
      tmp.add(_cloneDeepWithLimit(val, limit));
    });
    return tmp;
  }

  if (str === '[object Map]') {
    tmp = new Map();
    x.forEach(function (val, key) {
      tmp.set(_cloneDeepWithLimit(key, limit), _cloneDeepWithLimit(val, limit));
    });
    return tmp;
  }

  if (str === '[object Date]') {
    return new Date(+x);
  }

  if (str === '[object RegExp]') {
    tmp = new RegExp(x.source, x.flags);
    tmp.lastIndex = x.lastIndex;
    return tmp;
  }

  if (str.slice(-6) === 'Array]') {
    return new x.constructor(x);
  }

  return x;
};

/*
    #CDKcopyElement Crea y da métoddos para duplicar el contenido de una image paraa draguear
*/
export const _getCDKcopyElement = (getimg = false, width = 60, height = 60): HTMLElement => {
  let CDKcopyElement = document.querySelector('#CDKcopyElement') as HTMLElement;

  if (!CDKcopyElement) {
    /*Si no existe lo crea y lo mete en el body */
    const CDKcopyNode = document.createElement('div');
    CDKcopyNode.setAttribute('id', 'CDKcopyElement');
    CDKcopyNode.appendChild(document.createElement('img'));
    document.body.appendChild(CDKcopyNode);
    CDKcopyElement = document.querySelector('#CDKcopyElement') as HTMLElement;
  }

  let CDKcopyElementIMG = document.querySelector('#CDKcopyElement img') as HTMLElement;
  _setElementStyles(CDKcopyElementIMG, { height: height + 'px', width: width + 'px' });

  return getimg ? CDKcopyElementIMG : CDKcopyElement;
};
export const _setCDKcopyElementStart = imgSrc => {
  _setElementAttributes(_getCDKcopyElement(true), { src: imgSrc });
  _setElementStyles(_getCDKcopyElement(), { display: 'block' });
};
export const _setCDKcopyElementPosition = posPoint => {
  _setElementStyles(_getCDKcopyElement(), { left: posPoint.x + 'px', top: posPoint.y + 'px' });
};
export const _setCDKcopyElementEnd = () => {
  _setElementStyles(_getCDKcopyElement(), { left: -1000 + 'px', top: -1000 + 'px', display: 'none' });
};

/*
 * CDKcopy SVG element (usado para layouts)
 */

function _removeAllChildNodes(parent) {
  while (parent?.firstChild != null) {
    parent.removeChild(parent.firstChild);
  }
}

export const _getCDKcopyElementSvg = (elementChildSvg = null, width = 60, height = 60): HTMLElement => {
  let CDKcopyElement = document.querySelector('#CDKcopyElement') as HTMLElement;

  if (!CDKcopyElement) {
    /*Si no existe lo crea y lo mete en el body */
    const CDKcopyNode = document.createElement('div');
    CDKcopyNode.setAttribute('id', 'CDKcopyElement');
    document.body.appendChild(CDKcopyNode);
    CDKcopyElement = document.querySelector('#CDKcopyElement') as HTMLElement;
  }

  //Borra el contenido y hace append de una copia elementChild
  if (CDKcopyElement && elementChildSvg) {
    const svgNode = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgNode.setAttributeNS(null, 'id', 'svgRoot');
    svgNode.appendChild(elementChildSvg.cloneNode(true));
    _removeAllChildNodes(CDKcopyElement);
    CDKcopyElement.appendChild(svgNode);
  }

  return CDKcopyElement;
};
export const _setCDKcopyElementSvgStart = (elementChild = null) => {
  const CDKcopyElement = _getCDKcopyElementSvg(elementChild);
  _setElementStyles(CDKcopyElement, { display: 'block' });
};
export const _setCDKcopyElementSvgPosition = posPoint => {
  _setElementStyles(_getCDKcopyElementSvg(), { left: posPoint.x + 'px', top: posPoint.y + 'px' });
};
export const _setCDKcopyElementSvgEnd = () => {
  let CDKcopyElement = document.querySelector('#CDKcopyElement') as HTMLElement;
  CDKcopyElement.remove();
};
