import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { _isFeDev, _log } from '@shared/aux_helper_environment';
import { _timeout } from '@shared/aux_helper_functions';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  dummyDataBricksAiGenieChatResponse,
  dummyDataBricksAiGenieChatResponseChartDate,
  dummyDataBricksAiGenieChatResponseChartItems,
  dummyDataBricksAiGenieChatResponseChartPie,
  dummyDataBricksAiGenieChatResponseError,
} from '../dummyData/databricks-genie-ai-dummyData';
import { GenieMessage, GenieStatementResponse } from '../services/databricks-genie-ai-chat.models';
import {
  DataBricksAiGenieChatResponse,
  DataBricksAiGenieChatService,
  GenieError,
  GenieErrorType,
} from '../services/databricks-genie-ai-chat.service';

// CORE-40 > Chateá con tus datos
// Ejemplo: Quiero un listado en forma de tabla del ranking de los 5 productos con más venta histórica
//          perfecto, puedes hacer un gráfico de torta con esos mismos datos?

interface ChatMessage {
  from: 'user' | 'ai';
  text: any;
  textComplete?: boolean;
  error?: GenieErrorType | undefined;
  queryProcessResult?: GenieStatementResponse;
  rawResponse?: GenieMessage;
}

@Component({
  selector: 'quick-panel-data-ai-V2DB',
  template: `
    <div fxLayout="column" fxFlex class="quick-panel-data-ai-wrapp" *ngIf="firstOpenedPanel">
      <mat-list class="date" cdkFocusRegionStart>
        <mat-icon (click)="closeQuickPanel()" class="closeQuickPanel">close</mat-icon>
        <mat-icon (click)="resetConversation()" class="resetChat" matTooltip="Reiniciar conversación">restart_alt</mat-icon>
        <h3 matSubheader class="notification-title">
          <ai-icon [svgStyle]="'width: 28px; height: 28px; padding-top: 2px; padding-right: 4px'"></ai-icon>
          {{ 'Conversá con tus datos' }}
        </h3>
      </mat-list>

      <div class="chat-container">
        <div class="messages" fxFlex #messagesContainer>
          <div
            *ngFor="let message of messages"
            [ngClass]="{ 'from-user': message.from === 'user', 'from-ai': message.from === 'ai' }"
            class="message"
          >
            <div class="message-header">
              <ng-container *ngIf="message.from !== 'user'"
                ><ai-icon [svgStyle]="'width: 20px; height: 20px; vertical-align: bottom; padding-right: 0px;'"></ai-icon>
              </ng-container>
              {{ message.from === 'user' ? userDataName || 'Usuario' : 'Prisma Copilot' }}
            </div>
            <div [innerHTML]="message.text"></div>
            <!-- -- -->
            <div *ngIf="hasValidSchemaQueryResult(message.queryProcessResult)" class="queryProcessResultWrapp">
              <div class="queryProcessResult-header">Resultado de la consulta</div>
              <div class="queryProcessResult-content">
                <query-process-result
                  *ngIf="hasValidQueryResult(message.queryProcessResult)"
                  [queryProcessResult]="message.queryProcessResult"
                  [message]="message"
                ></query-process-result>
                <div *ngIf="!hasValidQueryResult(message.queryProcessResult)" class="no-data-message">
                  <span>No hay datos disponibles.</span>
                  <button
                    *ngIf="hasQuery(message)"
                    mat-icon-button
                    [matMenuTriggerFor]="queryPopover"
                    matTooltip="Ver Query"
                    #queryBtn="matTooltip"
                  >
                    <mat-icon>code</mat-icon>
                  </button>
                  <mat-menu #queryPopover="matMenu" class="query-popover">
                    <div class="query-container">
                      <pre class="query-content">{{ getQuery(message) }}</pre>
                      <button mat-icon-button class="copy-button" (click)="copyQuery(message)" matTooltip="Copiar Query al Portapapeles">
                        <mat-icon>content_copy</mat-icon>
                      </button>
                    </div>
                  </mat-menu>
                </div>
              </div>
            </div>
            <!-- -- -->
          </div>
          <div *ngIf="loading" class="message from-ai loading-message">
            <div class="loading-indicator">
              <div class="loadingWrapp">
                <mat-spinner color="accent" diameter="15"></mat-spinner>
              </div>
              Pensando...
            </div>
          </div>
        </div>
      </div>

      <div class="input-container">
        <textarea
          matInput
          [(ngModel)]="userInput"
          placeholder="Escribe un mensaje para el asistente..."
          (keyup.enter)="$event.shiftKey ? null : sendMessage()"
          rows="2"
        ></textarea>
        <button mat-icon-button (click)="sendMessage()">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./quick-panel-data-chat-V2DB.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickPanelV2DBComponentAi implements OnInit, OnDestroy {
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;

  messages: ChatMessage[] = null;
  userInput = '';
  genieOk = false;
  genieUseDummyData = false;
  loading = false;
  apiUrl = null;
  userDataName = null;
  chatPermision = null;
  firstOpenedPanel = false;

  // Añadir control de error y estado
  hasError = false;
  errorMessage = '';

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private sanitizer: DomSanitizer,
    private oidcSecurityService: OidcSecurityService,
    private prismaDynamicEnv: PrismaDynamicEnv,
    private quickPanelAiService: DataBricksAiGenieChatService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._fuseSidebarService.getSidebar('quickPanelAi').openedChanged.subscribe(data => {
      this.firstOpenPanel();
    });
  }

  isAssistantEnabledAi() {
    return true;
  }

  resetConversation() {
    this.messages = [{ from: 'ai', text: '¿En qué puedo ayudarte hoy?' }];
    this.userInput = '';
    this.hasError = null;
    this.errorMessage = '';
    this.quickPanelAiService.resetConversation();
  }

  firstOpenPanel() {
    if (this.firstOpenedPanel) return;

    this.resetConversation();

    this.firstOpenedPanel = true;

    this.chatPermision = this.isAssistantEnabledAi();
    if (this.chatPermision !== true) return;

    this.oidcSecurityService
      .getUserData()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(userData => {
        if (userData?.name) this.userDataName = userData?.name;
      });

    if (true && _isFeDev()) this.fakeMirstMsg();
  }

  async scrollToBottomWhitDelay($time = 100) {
    await _timeout($time);
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    if (!this.firstOpenedPanel) return;

    try {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  closeQuickPanel(): void {
    if (true) {
      setTimeout(() => {
        this._fuseSidebarService.getSidebar('quickPanelAi') && this._fuseSidebarService.getSidebar('quickPanelAi').close();
      });
    }
  }

  async fakeMirstMsg() {
    this.messages.push({ from: 'user', text: 'Quiero un listado de todas las estaciones disponibles que están activas' });
    this.loading = true;
    await _timeout(500);
    this.putMsg(dummyDataBricksAiGenieChatResponse);

    await _timeout(500);

    this.messages.push({ from: 'user', text: 'Quiero un listado de todas las estaciones disponibles que están activas' });
    this.loading = true;
    await _timeout(500);
    this.putMsg(dummyDataBricksAiGenieChatResponseChartPie);

    await _timeout(500);

    this.messages.push({ from: 'user', text: 'Quiero un listado de todas las estaciones disponibles que están activas' });
    this.loading = true;
    await _timeout(500);
    this.putMsg(dummyDataBricksAiGenieChatResponseChartDate);

    await _timeout(500);

    this.messages.push({ from: 'user', text: 'Quiero un listado de todas las estaciones disponibles que están activas' });
    this.loading = true;
    await _timeout(500);
    this.putMsg(dummyDataBricksAiGenieChatResponseChartItems);

    this.messages.push({ from: 'user', text: 'Quiero un listado de todas las estaciones disponibles que están activas' });
    this.loading = true;
    await _timeout(500);
    this.putMsg(dummyDataBricksAiGenieChatResponseError);
  }

  putMsg(response: DataBricksAiGenieChatResponse) {
    this.loading = false;

    const messageContent = {
      from: 'ai' as const,
      text: this.sanitizer.bypassSecurityTrustHtml(response.text || ''),
      textComplete: true,
      queryProcessResult: response.queryProcessResult,
      rawResponse: response.rawResponse,
    };

    this.messages.push(messageContent);
    this.scrollToBottomWhitDelay();
  }

  async sendMessage() {
    if (!this.userInput.trim()) return;

    const userMessage = String(this.userInput).trim();
    this.messages.push({ from: 'user', text: userMessage });
    this.userInput = '';
    this.loading = true;
    this.hasError = false;

    await this.scrollToBottomWhitDelay(50);

    this.quickPanelAiService.sendGenieMessage(userMessage).subscribe({
      next: (response: DataBricksAiGenieChatResponse) => {
        _log('QuickPanelV2DBComponentAi response', response);

        if (!response || response.error) {
          this.handleError(response?.error);
          return;
        }

        this.putMsg(response);
      },
      error: error => {
        this.handleError(error);
      },
    });
  }

  private handleError(error: GenieError | GenieErrorType | any) {
    this.loading = false;
    this.hasError = true;
    this.errorMessage = error?.message || 'Ocurrió un error al procesar tu mensaje';

    const errorType = error?.type || GenieErrorType.UNKNOWN;

    const errorMessage: ChatMessage = {
      from: 'ai',
      text: this.sanitizer.bypassSecurityTrustHtml(`<div class="error-message">${this.errorMessage}</div>`),
      error: errorType,
    };

    this.messages.push(errorMessage);
    this.scrollToBottomWhitDelay();
  }

  private hasValidSchemaQueryResult(queryResult: GenieStatementResponse): boolean {
    // Verificar que haya al menos una columna definida
    return queryResult?.statement_response?.manifest?.schema?.columns?.length > 0;
  }

  private hasValidQueryResult(queryResult: GenieStatementResponse): boolean {
    if (!this.hasValidSchemaQueryResult) return false;

    const { result } = queryResult.statement_response;

    // Verificar que exista el arreglo de datos
    if (!result?.data_typed_array?.length) return false;

    // Verificar que al menos una fila tenga algún valor
    const hasAnyValue = result.data_typed_array.some(row =>
      row.values.some(value => value.str !== undefined || value.num !== undefined || value.bool !== undefined)
    );

    return hasAnyValue;
  }

  hasQuery(message: ChatMessage): boolean {
    return !!message?.rawResponse?.attachments?.[0]?.query;
  }

  getQuery(message: ChatMessage): string {
    return message?.rawResponse?.attachments?.[0]?.query?.query || '';
  }

  copyQuery(message: ChatMessage): void {
    const query = this.getQuery(message);
    if (!query) return;

    navigator.clipboard.writeText(query).then(
      () => {
        _log('Query copiado al portapapeles');
      },
      err => {
        console.error('Error al copiar el query:', err);
      }
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
