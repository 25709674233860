import { Injectable } from '@angular/core';
import { _throwError } from '@shared/aux_helper_functions';
import { environment } from 'environments/environment';
import { _logTap } from '@shared/aux_helper_environment';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class FileService {
  private config = environment.imageFile;
  private configBase = environment.apiBaseUrl_prices;

  constructor(private http: HttpClient) {}
  /*
   * Actualiza la imagen y referencia de donde pertenece
   * Devolviendo la url donde esta almacenada
   *
   */
  updateImageFile(_moduleName: string, file?: File): Observable<string> {
    const url = `${this.configBase}${this.config}`;

    const formData = new FormData();
    formData.append('moduleName', _moduleName);

    if (file) {
      formData.append('file', file, file.name);
    }

    return this.http.put<any>(url, formData).pipe(
      tap(data => _logTap(`${FileService.name}::update (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, FileService))
    );
  }
  /*
   * Guarda la imagen y referencia de donde pertenece
   * Devolviendo la url donde esta almacenada
   *
   */

  saveImageFile(_moduleName: string, file: File): Observable<any> {
    const url = `${this.configBase}${this.config}`;

    const formData = new FormData();
    formData.append('moduleName', _moduleName);

    if (file) {
      formData.append('file', file, file.name);
    }
    //let headers = new HttpHeaders();
    //headers= headers.set('content-type', 'text/plain');
    //return this.http.post<any>(url, formData, {headers}).pipe(
    return this.http.post<any>(url, formData, { headers: { 'Cache-Control': 'no-cache' } }).pipe(
      tap(data => _logTap(`${FileService.name}::update (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, FileService))
    );
  }

  saveFile(moduleName: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('moduleName', moduleName);

    return this.http.post('/api/files/upload', formData);
  }

  updateFile(moduleName: string, file: File): Observable<any> {
    const url = `${this.configBase}${this.config}`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('moduleName', moduleName);

    return this.http.put(url, formData).pipe(
      tap(data => _logTap(`${FileService.name}::updateFile (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, FileService))
    );
  }

  updateGeneralFile(moduleName: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('moduleName', moduleName);

    return this.http.put('/api/files/upload', formData).pipe(
      tap(data => _logTap(`${FileService.name}::updateGeneralFile (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, FileService))
    );
  }
}
