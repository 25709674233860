import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SelectWeekdayComponent } from './supply/setup/order-schedule/order-schedule-form/sub-components/select-weekday-component';

/** 3RD Party Modules */
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

/** Custom Components */
import { ChangeWithoutSavingModalComponent } from '@prisma/components/change-without-saving-modal/change-without-saving-modal.component';
import { ErasureConfirmationModalComponent } from '@prisma/components/erasure-confirmation-modal/erasure-confirmation-modal.component';
import { ErrorMessageModalComponent } from '@prisma/components/error-message-modal/error-message-modal.component';
import { IanCardComponent } from '@prisma/components/ian-card/ian-card.component';
import { IanFilterMarkersComponent } from '@prisma/components/ian-filter-markers/ian-filter-markers.component';
import { IanHeaderToolbarComponent } from '@prisma/components/ian-header-toolbar/ian-header-toolbar.component';
import { ActionToolbarComponent } from '@prisma/components/ian-table-components/ian-action-toolbar/ian-action-toolbar.component';
import { DefaultToolbarComponent } from '@prisma/components/ian-table-components/ian-default-toolbar/ian-default-toolbar.component';
import { OperationSuccessModalComponent } from '@prisma/components/operation-success-modal/operation-success-modal.component';
import { LatitudeLongitudeInput } from '@prisma/directives/latitude-longitude-input.directive';
import { ErrorMessageService } from '@prisma/services/error-message.service';
import { LargeNumberSuffixesPipe } from 'core/pipes/large-number-suffix.pipe';
import { LargeNumberSuffixesPipeK } from 'core/pipes/large-number-suffix.pipeK';
import { StringTruncatePipe } from 'core/pipes/string-truncate.pipe';

import localeEsAr from '@angular/common/locales/es-AR';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AdvancedSearchFooterComponent } from '@prisma/components/advanced-search-footer/advanced-search-footer.component';
import { AdvancedSearchHeaderComponent } from '@prisma/components/advanced-search-header/advanced-search-header.component';
import { BigGreenButtonComponent } from '@prisma/components/bigGreenButton/big-green-button/big-green-button.component';
import { MultipleImplementationComponent } from '@prisma/components/bigGreenButton/multiple-implementation/multiple-implementation.component';
import { ColumnCheckBoxCellComponentV2 } from '@prisma/components/check-box/check-box-v2/column-check-box-cell-v2/column-check-box-cell.components';
import { ColumnCheckBoxHeaderComponentV2 } from '@prisma/components/check-box/check-box-v2/column-check-box-header-v2/column-check-box-header.components';
import { IanHeaderMasiveActionAndFilterComponentV2 } from '@prisma/components/check-box/check-box-v2/ian-header-masive-action-and-filter-v2/ian-header-masive-action-and-filter.components';
import { IanHeaderMasiveActionAndFilterComponentV3 } from '@prisma/components/check-box/check-box-v2/ian-header-masive-action-and-filter-v3/ian-header-masive-action-and-filter.components';
import { ColumnCheckBoxCellComponent } from '@prisma/components/check-box/column-check-box-cell/column-check-box-cell.components';
import { ColumnCheckBoxHeaderComponent } from '@prisma/components/check-box/column-check-box-header/column-check-box-header.components';
import { IanHeaderMasiveActionAndFilterComponent } from '@prisma/components/check-box/ian-header-masive-action-and-filter/ian-header-masive-action-and-filter.components';
import { ClipboardComponent } from '@prisma/components/clipboard/clipboard.component';
import { ComboOnModalComponent } from '@prisma/components/combo-on-modal/combo-on-modal.component';
import { ContextMenuInsightsComponent } from '@prisma/components/context-menu-insights/context-menu-insights.component';
import { DinamicTagsAdvancedFilterComponent } from '@prisma/components/dinamic-tags/dinamic-tags-advanced-filter/dinamic-tags-advanced-filter.component';
import { DinamicTagsFormIncludedExcludedV2Component } from '@prisma/components/dinamic-tags/dinamic-tags-form-included-excluded-V2/dinamic-tags-form-included-excluded-V2.component';
import { DinamicTagsFormIncludedExcludedComponent } from '@prisma/components/dinamic-tags/dinamic-tags-form-included-excluded/dinamic-tags-form-included-excluded.component';
import { DinamicTagsFormComponent } from '@prisma/components/dinamic-tags/dinamic-tags-form/dinamic-tags-form.component';
import { DinamicTagsAdvancedFilterComponentV2 } from '@prisma/components/dinamic-tags/dinamic-tags-v2/dinamic-tag-advanced-filter-v2/dinamic-tags-advanced-filter.component';
import { DinamicTagsFormComponentV2 } from '@prisma/components/dinamic-tags/dinamic-tags-v2/dinamic-tags-form/dinamic-tags-form.component';
import { TagsTreeIncludedExcludedV2 } from '@prisma/components/dinamic-tags/tags-tree-included-excluded-V2/tags-tree-included-excluded-V2.component';
import { TagsValuesTreeCascadeIncludedExcludedV2 } from '@prisma/components/dinamic-tags/tags-tree-included-excluded-V2/tags-values-tree-cascade-included-excluded-V2.component';
import { TagsValuesTreeIncludedExcludedV2 } from '@prisma/components/dinamic-tags/tags-tree-included-excluded-V2/tags-values-tree-included-excluded-V2.component';
import { DragDropFileComponent } from '@prisma/components/drag-drop-file/drag-drop-file.component';
import { DynReportComponent } from '@prisma/components/dyn-report/dyn-report.component';
import { DynamicChildLoaderDirective } from '@prisma/components/dynamicChildLoaderDirective/DynamicChildLoader.directive';
import { EasyLeafletMap } from '@prisma/components/easy-leaflet-map/easy-leaflet-map.component';
import { FilterChargedComboCommonComponent } from '@prisma/components/filterChargedCombo/common/filter-charged-combo-common.component';
import { SelectOnDemandItemsV2Component } from '@prisma/components/filterChargedCombo/items/select-ondemand-item.componentV2';
import { SelectOnDemanditemsPagedV3Component } from '@prisma/components/filterChargedCombo/items/select-ondemand-items-paged.componentV3';
import { SelectOnDemandItemsComponent } from '@prisma/components/filterChargedCombo/items/select-ondemand-items.component';
import { SelectOnDemanditemsV3Component } from '@prisma/components/filterChargedCombo/items/select-ondemand-items.componentV3';
import { FilterChargedComboOptionDisabledComponent } from '@prisma/components/filterChargedCombo/optionDisabled/filter-charged-combo-optionDisabled.component';
import { SelectOnDemandstoresComponent } from '@prisma/components/filterChargedCombo/stores/select-ondemand-store.component';
import { SelectOnDemandstoresV2Component } from '@prisma/components/filterChargedCombo/stores/select-ondemand-store.componentV2';
import { SelectOnDemandstoresV3Component } from '@prisma/components/filterChargedCombo/stores/select-ondemand-store.componentV3';
import { FilterChargedComboCommonV2Component } from '@prisma/components/filterChargedComboV2/filter-charged-combo-common-v2.component';
import { ClipboardModalCompetitorsComponent } from '@prisma/components/general-clipboard-list-modal/general-clipboard-list-modal-competitors.component';
import { ClipboardModalUserComponent } from '@prisma/components/general-clipboard-list-modal/general-clipboard-list-modal-user.component';
import { ClipboardModalComponent } from '@prisma/components/general-clipboard-list-modal/general-clipboard-list-modal.component';
import { AssortmentStatusList } from '@prisma/components/generic-assortment-status-modal/generic-assortment-status-list.component';
import { ModalAssortmentStatus } from '@prisma/components/generic-assortment-status-modal/generic-assortment-status-modal.component';
import { GenericCategorySelectionMiniV2Component } from '@prisma/components/generic-category-selection-mini-v2/generic-category-selection-mini-v2.component';
import { GenericCategorySelectionAndList } from '@prisma/components/generic-category-selection/categoriesAndList/generic-category-selection.component';
import { GenericCategorySelection } from '@prisma/components/generic-category-selection/generic-category-selection.component';
import { GenericConfirmationModalComponent } from '@prisma/components/generic-confirmation-modal/generic-confirmation-modal.component';
import { GenericDistributionCenterSelectComponent } from '@prisma/components/generic-distribution-center-select/generic-distribution-center-select.component';
import { GenericLookupAsyncComponent } from '@prisma/components/generic-lookup/generic-lookup-async.component';
import { GenericLookupComponent } from '@prisma/components/generic-lookup/generic-lookup.component';
import { GenericMasiveActionsComponent } from '@prisma/components/generic-masive-actions/generic-masive-actions.component';
import { GenericModalWarningDelete } from '@prisma/components/generic-modal/generic-modal.component';
import { GenericPromotionalPeriodsComponent } from '@prisma/components/generic-periods-select/generic-promotional-periods-select.component';
import { GenericProductSelectAsyncComponent } from '@prisma/components/generic-product-select-async/generic-product-select-async.component';
import { GenericPromotionTypeSelector } from '@prisma/components/generic-promotion-type-selector/generic-promotion-type.component';
import { GenericPromotionalSpacesComponent } from '@prisma/components/generic-promotional-spaces/generic-promotional-spaces.component';
import { GenericRadioOptionsComponent } from '@prisma/components/generic-radio-options/generic-radio-options.component';
import { GenericPromotionReferencePromotionPriceType } from '@prisma/components/generic-reference-promotion-price/reference-promotion-price-type.component';
import { GenericPromotionRuleTypeSelector } from '@prisma/components/generic-rule-type-selector/generic-rule-type.component';
import { GenericSelectItemMasterTypesComponent } from '@prisma/components/generic-select-item-master-types/generic-select-item-master-types.component';
import { GenericSelectPromotionMasterTypesComponent } from '@prisma/components/generic-select-promotion-types/generic-select-promotion-types.component';
import { GenericSelectStoresTypesComponent } from '@prisma/components/generic-select-stores-types/generic-select-stores-types.component';
import { GenericSelectTagsDataTypeComponent } from '@prisma/components/generic-select-tags-datatype/generic-select-tags-datatype.component';
import { GenericSelectTagsParentComponent } from '@prisma/components/generic-select-tags-parent/generic-select-tags-parent.component';
import { GenericSelectTagsTypeComponent } from '@prisma/components/generic-select-tags-type/generic-select-tags-type.component';
import { GenericStoreSelectComponent } from '@prisma/components/generic-store-select/generic-store-select.component';
import { GenericSupplierSelectComponent } from '@prisma/components/generic-supplier-select/generic-supplier-select.component';
import { GenericTagsSelectMultipleIncludedExcludedV2Component } from '@prisma/components/generic-tags-select-v2/generic-tags-select-multiple-included-excluded-v2.component';
import { GenericTagsSelectMultiplev2AttributeModal } from '@prisma/components/generic-tags-select-v2/generic-tags-select-multiple-v2-attribute.modal';
import { GenericTagsSelectMultipleV2Component } from '@prisma/components/generic-tags-select-v2/generic-tags-select-multiple-v2.component';
import { GenericTagsSelectV2Component } from '@prisma/components/generic-tags-select-v2/generic-tags-select-v2.component';
import { generic_type_rules_checkbox_component } from '@prisma/components/generic-type-rules-checkbox/generic-type-rules-checkbox.component';
import { generic_users_select_component } from '@prisma/components/generic-users-select/generic-users-select.component';
import { IanAddAttributeModalComponent } from '@prisma/components/ian-add-attribute-modal/ian-add-attribute-modal.component';
import { ImporterComponentV2_selector } from '@prisma/components/ian-importer-v2/components/selector_template.component';
import { IanLookupComponent } from '@prisma/components/ian-lookup/ian-lookup.component';
import { ActionToolbarComponentV2 } from '@prisma/components/ian-table-components/ian-action-toolbar-v2/ian-action-toolbar.component';
import { NoItemsFoundComponent } from '@prisma/components/ian-table-components/ian-no-items-found/ian-no-items-found.component';
import { InputAreaSelectedOptionsComponentV2 } from '@prisma/components/input-area-selected-options-v2/input-area-selected-options-v2.component';
import { InputComboTagsCommonComponent } from '@prisma/components/input-area-selected-options/common/input-area-selected-options-common.component';
import { AddStoresModalComponent } from '@prisma/components/input-area-selected-options/search/only-search/add-stores-modal/add-stores-modal.component';
import { AddPlanogramsCommonModalComponent } from '@prisma/components/input-area-selected-options/search/only-search/add-stores-modal/common/add-planograms-common-modal.component';
import { AddStoresCommonModalComponent } from '@prisma/components/input-area-selected-options/search/only-search/add-stores-modal/common/add-stores-common-modal.component';
import { GenericInputSelectedOptionsComponent } from '@prisma/components/input-area-selected-options/search/only-search/add-stores-modal/common/general-input-selected-options.component';
import { InputAreaSelectedOptionsSearchComponent } from '@prisma/components/input-area-selected-options/search/only-search/add-stores-modal/common/input-area-selected-options-search.component';
import { AddStoresBySupplierModalComponent } from '@prisma/components/input-area-selected-options/search/only-search/add-stores-modal/supplier/add-stores-by-supplier-modal.component';
import { InputAreaSelectedOptionsSearchBySupplierComponent } from '@prisma/components/input-area-selected-options/search/only-search/add-stores-modal/supplier/input-area-selected-options-search-store-by-supplier.component';
import { StoresListModalComponent } from '@prisma/components/input-area-selected-options/search/stores-list-modal/stores-list-modal.component';
import { AddVendorsVerifyModalComponent } from '@prisma/components/input-area-selected-options/search/verification/add-vendors-verify-modal/add-vendors-verify-modal.component';
import { CopyVendorsByStoreComponente } from '@prisma/components/input-area-selected-options/search/verification/add-vendors-verify-modal/copy-vendors-by-store/copy-vendors-by-store.component';
import { InputAreaSelectedOptionsSearchWithVerificationComponent } from '@prisma/components/input-area-selected-options/search/verification/input-area-selected-options-search-with-verification.component';
import { CustomLoaderComponent } from '@prisma/components/loader/loader.component';
import { NumberCounterToAnimatedComponent } from '@prisma/components/number-counter-to-animated/number-counter-to-animated.component';
import { NumberKComponent } from '@prisma/components/number-k/number-k.component';
import { PopoverComponent } from '@prisma/components/popover/popover.component';
import { PriceMapGenericComponent } from '@prisma/components/price-map-generic/price-map-generic.component';
import { PrismaRouteToAnotherPageComponent } from '@prisma/components/prisma-route-to-another-page/prisma-route-to-another-page.component';
import { RangePickerDateComponent } from '@prisma/components/range-picker-date/range-picker-date.component';
import { SaveConfirmationCustomModalComponent } from '@prisma/components/save-confirmation-custom-modal/save-confirmation-custom-modal.component';
import { SaveConfirmationModalComponent } from '@prisma/components/save-confirmation-modal/save-confirmation-modal.component';
import { ShowEntityListModalComponent } from '@prisma/components/show-entity-list-modal/show-entity-list-modal.component';
import { ChipsPrismaTable } from '@prisma/components/table-components/components/chips/chips.component';
import { PrismaTableColumnContentComponent } from '@prisma/components/table-components/components/column/column.component';
import { SubRowExpandedComponent } from '@prisma/components/table-components/components/sub-row-expanded/sub-row-expanded.component';
import { PrismaTableUserConfigureColumnComponent } from '@prisma/components/table-components/components/user-configure-columns/user-configure-columns.component';
import { PrismaTableFlexColumn } from '@prisma/components/table-components/directives/flex-columns';
import { PrismaTableSubRowComponent } from '@prisma/components/table-components/prisma-table-sub-row/prisma-table-sub-row.component';
import { PrismaTableComponent } from '@prisma/components/table-components/prisma-table/prisma-table.component';
import { VirtualScrollPrismaTableComponent } from '@prisma/components/table-components/virtual-scroll-prisma-table/virtual-scroll-prisma-table.component';
import { MomentToDate } from '@prisma/directives/moment-date.directive';
import { NumericDirective, NumericDirectiveTel, PositiveIntegrerDirective } from '@prisma/directives/numerci-input.directive';
import { FileService } from '@prisma/services/file.service';
import { TagsService } from '@prisma/services/tags.service';
import { DirectivePrismaCustomValidators, DirectivePrismaCustomValidatorsAsync } from '@shared/aux_prisma_custom_forms';
import { ModalConfirmationStoresComponent } from 'app/spaces/floorplans-editor/components/modal-confirmation-stores.component';
import {
  AuxFilterLookUp,
  AuxFilterLookUpAndSort,
  AuxFilterLookUpWithSubCat,
  AuxPrismaTranslate,
  AuxPrismaTranslateB,
  AuxPrismaTranslateEnum,
  DateLenguaguePipe,
  DateTimeLenguaguePipe,
  MaxLenghtTextPipe,
  PaginatePipe,
  TrimPipe,
} from 'core/pipes/aux_pipes.pipe';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { InputTrimModule } from 'ng2-trim-directive';
import { CustomFormsModule } from 'ng2-validation';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LenguageDateFormatType, getDateFormat } from './app.config';
import { MarketInformationItemMarketSelectorComponent } from './assortment/setup/market-information/market-information-list/generic_components/market-information-itemMarket-selector.component';
import { MaterialModule } from './material.module';
import { generic_brands_select_component } from './prisma/components/generic-brands-select/generic-brands-select.component';
import { generic_item_status_select_component } from './prisma/components/generic-status-select/generic-item-status-select.component';
import { DeactivateFormDialogComponent } from './services/guards/can-deactivate-form.guard/can-deactivate.dialog/can-deactivate.dialog';
import { ModalWidthProductComponent } from './spaces/floorplans-editor/components/modal-width-products.component';
import { SelectMonthdayComponent } from './supply/setup/order-schedule/order-schedule-form/sub-components/select-monhtday-component';
import { GenericTagsSelectComponent } from '@prisma/components/generic-tags-select-v2/generic-tags-select';
import { GenericYearSelector } from '@prisma/components/generic-promotion-year-selector/generic-year-selector.component';
import { GenericVendorSelector } from '@prisma/components/generic-vendors-selector/generic-vendors-selector.component';
import { GenericSliderComponent } from '@prisma/components/generic-slider/generic-slider.component';
import { GenericSelectTreeComponent } from '@prisma/components/generic-select-tree/generic-select-tree.component';
import { RangePickerDateCalendarComponent } from '@prisma/components/range-picker-date-calendar/range-picker-date-calendar.component';
import { GenericRolesUsersConsultantsTreeSelectComponent } from '@prisma/components/generic-roles-users-consultants-select/generic-roles-users-consultants-tree.component';
import { GenericPromotionTasksActionables } from '@prisma/components/generic-promotion-task-actionables-select/generic-promotion-task-actionables.component';
import { GenericPeriodsEmptyMessageComponent } from '@prisma/components/generic-periods-select/generic-promotional-periods-empty-message.component';
import { AiIconComponent } from './layout/components/quick-panel/ai-icon.component';
import { FileUploadComponent } from '@prisma/components/file-upload/file-upload.component';
import { GenericErrorMessageDirective } from '@prisma/directives/generic-error-messages.directive';
import { ReactiveFormWrapper } from '@prisma/components/reactive-form-wrapper/reactive-form-wrapper.component';
import { SelectedPlanogramPopMaterialPipe } from './prisma/components/generic-promotional-spaces/selectedPlanogramPopMaterial.pipe';
import { AssignForecastModalComponent } from '@prisma/containers/assign-forecast-modal/assign-forecast-modal.component';
import { GenericMessageStructureSelector } from '@prisma/components/generic-message-structure-selector/generic-message-structure-select.component';

//Se registra este idioma para agregar al DatePipe, asi se debe hacer con cada idioma, excepto en-US ya viene por default cargado.
registerLocaleData(localeEsAr, LenguageDateFormatType.DDMMYYYY);

const dateFormat = getDateFormat();

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  ScrollingModule,

  // Translate
  TranslateModule,

  // Fuse
  FuseSharedModule,
  FuseSidebarModule,
  FuseProgressBarModule,

  // Flex
  FlexLayoutModule,

  MaterialModule,

  NgxPermissionsModule,

  CustomFormsModule,
  InputTrimModule,
  TableVirtualScrollModule,
];

@NgModule({
  declarations: [
    AiIconComponent,
    IanCardComponent,
    IanHeaderToolbarComponent,
    ActionToolbarComponent,
    ActionToolbarComponentV2,
    DefaultToolbarComponent,
    DragDropFileComponent,
    FileUploadComponent,
    StringTruncatePipe,
    LargeNumberSuffixesPipe,
    LargeNumberSuffixesPipeK,
    IanFilterMarkersComponent,
    ErrorMessageModalComponent,
    LatitudeLongitudeInput,
    MomentToDate,
    GenericErrorMessageDirective,
    NumericDirective,
    PositiveIntegrerDirective,
    NumericDirectiveTel,
    PrismaTableFlexColumn,
    PrismaTableUserConfigureColumnComponent,
    ChangeWithoutSavingModalComponent,
    GenericModalWarningDelete,
    OperationSuccessModalComponent,
    ErasureConfirmationModalComponent,
    GenericConfirmationModalComponent,
    ModalAssortmentStatus,
    DeactivateFormDialogComponent,
    NoItemsFoundComponent,
    IanAddAttributeModalComponent,
    GenericTagsSelectMultiplev2AttributeModal,
    IanLookupComponent,
    SaveConfirmationModalComponent,
    SaveConfirmationCustomModalComponent,
    AuxFilterLookUp,
    AuxFilterLookUpAndSort,
    PaginatePipe,
    AuxFilterLookUpWithSubCat,
    AuxPrismaTranslate,
    AuxPrismaTranslateB,
    AuxPrismaTranslateEnum,
    TrimPipe,
    MaxLenghtTextPipe,
    DateLenguaguePipe,
    DateTimeLenguaguePipe,
    DirectivePrismaCustomValidators,
    DirectivePrismaCustomValidatorsAsync,
    ColumnCheckBoxCellComponent,
    ColumnCheckBoxHeaderComponent,
    IanHeaderMasiveActionAndFilterComponent,
    ColumnCheckBoxCellComponentV2,
    ColumnCheckBoxHeaderComponentV2,
    IanHeaderMasiveActionAndFilterComponentV2,
    IanHeaderMasiveActionAndFilterComponentV3,
    DinamicTagsAdvancedFilterComponent,
    DinamicTagsFormComponent,
    DinamicTagsFormIncludedExcludedComponent,
    DinamicTagsFormIncludedExcludedV2Component,
    TagsTreeIncludedExcludedV2,
    TagsValuesTreeIncludedExcludedV2,
    TagsValuesTreeCascadeIncludedExcludedV2,
    GenericCategorySelection,
    GenericCategorySelectionAndList,
    GenericSelectStoresTypesComponent,
    DynReportComponent,
    FilterChargedComboOptionDisabledComponent,
    FilterChargedComboCommonComponent,
    SelectOnDemandItemsComponent,
    SelectOnDemandstoresComponent,
    SelectOnDemandstoresV2Component,
    SelectOnDemandstoresV3Component,
    SelectOnDemandItemsV2Component,
    SelectOnDemanditemsV3Component,
    SelectOnDemanditemsPagedV3Component,
    FilterChargedComboCommonV2Component,
    GenericProductSelectAsyncComponent,
    InputComboTagsCommonComponent,
    InputAreaSelectedOptionsComponentV2,
    InputAreaSelectedOptionsSearchComponent,
    GenericInputSelectedOptionsComponent,
    InputAreaSelectedOptionsSearchBySupplierComponent,
    InputAreaSelectedOptionsSearchWithVerificationComponent,
    AddStoresModalComponent,
    AddStoresCommonModalComponent,
    AddPlanogramsCommonModalComponent,
    AddStoresBySupplierModalComponent,
    AddVendorsVerifyModalComponent,
    ComboOnModalComponent,
    PopoverComponent,
    CustomLoaderComponent,
    AdvancedSearchHeaderComponent,
    AdvancedSearchFooterComponent,
    NumberKComponent,
    NumberCounterToAnimatedComponent,
    MultipleImplementationComponent,
    BigGreenButtonComponent,
    ClipboardComponent,
    PrismaTableComponent,
    ChipsPrismaTable,
    VirtualScrollPrismaTableComponent,
    PrismaTableSubRowComponent,
    PrismaTableColumnContentComponent,
    SubRowExpandedComponent,
    ClipboardModalComponent,
    ClipboardModalUserComponent,
    ClipboardModalCompetitorsComponent,
    GenericLookupComponent,
    GenericSliderComponent,
    GenericMasiveActionsComponent,
    GenericMessageStructureSelector,
    GenericPromotionalSpacesComponent,
    GenericPromotionalPeriodsComponent,
    GenericPeriodsEmptyMessageComponent,
    GenericPromotionTypeSelector,
    GenericYearSelector,
    GenericPromotionRuleTypeSelector,
    GenericLookupAsyncComponent,
    GenericCategorySelectionMiniV2Component,
    DinamicTagsAdvancedFilterComponentV2,
    DinamicTagsFormComponentV2,
    GenericTagsSelectV2Component,
    GenericTagsSelectMultipleV2Component,
    GenericTagsSelectComponent,
    GenericTagsSelectMultipleIncludedExcludedV2Component,
    CopyVendorsByStoreComponente,
    ContextMenuInsightsComponent,
    EasyLeafletMap,
    PriceMapGenericComponent,
    PrismaRouteToAnotherPageComponent,
    RangePickerDateComponent,
    RangePickerDateCalendarComponent,
    GenericStoreSelectComponent,
    GenericSelectPromotionMasterTypesComponent,
    GenericPromotionReferencePromotionPriceType,
    GenericRadioOptionsComponent,
    GenericRolesUsersConsultantsTreeSelectComponent,
    GenericPromotionTasksActionables,
    GenericSelectTagsTypeComponent,
    GenericSelectTreeComponent,
    GenericDistributionCenterSelectComponent,
    GenericSupplierSelectComponent,
    GenericVendorSelector,
    GenericSelectTagsParentComponent,
    GenericSelectTagsDataTypeComponent,
    GenericSelectItemMasterTypesComponent,
    generic_item_status_select_component,
    generic_brands_select_component,
    AssortmentStatusList,
    SelectWeekdayComponent,
    SelectMonthdayComponent,
    generic_users_select_component,
    generic_type_rules_checkbox_component,
    ShowEntityListModalComponent,
    ImporterComponentV2_selector,
    DynamicChildLoaderDirective,
    MarketInformationItemMarketSelectorComponent,
    ModalConfirmationStoresComponent,
    ModalWidthProductComponent,
    StoresListModalComponent,
    ReactiveFormWrapper,
    SelectedPlanogramPopMaterialPipe,
    AssignForecastModalComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: dateFormat },
    { provide: MAT_DATE_LOCALE, useValue: dateFormat },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ErrorMessageService,
    DeactivateFormDialogComponent,
    FileService,
    TagsService,
    DecimalPipe,
  ],
  imports: [modules, LeafletModule.forRoot()],
  exports: [
    ...modules,
    AiIconComponent,
    IanCardComponent,
    IanHeaderToolbarComponent,
    ActionToolbarComponent,
    ActionToolbarComponentV2,
    DefaultToolbarComponent,
    DragDropFileComponent,
    FileUploadComponent,
    StringTruncatePipe,
    LargeNumberSuffixesPipe,
    LargeNumberSuffixesPipeK,
    IanFilterMarkersComponent,
    LatitudeLongitudeInput,
    MomentToDate,
    GenericErrorMessageDirective,
    NumericDirective,
    PositiveIntegrerDirective,
    NumericDirectiveTel,
    PrismaTableFlexColumn,
    PrismaTableUserConfigureColumnComponent,
    ErrorMessageModalComponent,
    ErasureConfirmationModalComponent,
    GenericConfirmationModalComponent,
    ModalAssortmentStatus,
    ChangeWithoutSavingModalComponent,
    GenericModalWarningDelete,
    NoItemsFoundComponent,
    IanAddAttributeModalComponent,
    GenericTagsSelectMultiplev2AttributeModal,
    IanLookupComponent,
    AuxFilterLookUp,
    AuxFilterLookUpAndSort,
    PaginatePipe,
    AuxFilterLookUpWithSubCat,
    AuxPrismaTranslate,
    AuxPrismaTranslateB,
    AuxPrismaTranslateEnum,
    TrimPipe,
    MaxLenghtTextPipe,
    DateLenguaguePipe,
    DateTimeLenguaguePipe,
    DirectivePrismaCustomValidators,
    DirectivePrismaCustomValidatorsAsync,
    ColumnCheckBoxCellComponent,
    ColumnCheckBoxHeaderComponent,
    IanHeaderMasiveActionAndFilterComponent,
    ColumnCheckBoxCellComponentV2,
    ColumnCheckBoxHeaderComponentV2,
    IanHeaderMasiveActionAndFilterComponentV2,
    IanHeaderMasiveActionAndFilterComponentV3,
    DinamicTagsAdvancedFilterComponent,
    DinamicTagsFormComponent,
    DinamicTagsFormIncludedExcludedComponent,
    DinamicTagsFormIncludedExcludedV2Component,
    TagsTreeIncludedExcludedV2,
    TagsValuesTreeIncludedExcludedV2,
    TagsValuesTreeCascadeIncludedExcludedV2,
    GenericCategorySelection,
    GenericCategorySelectionAndList,
    GenericSelectStoresTypesComponent,
    DynReportComponent,
    FilterChargedComboOptionDisabledComponent,
    FilterChargedComboCommonComponent,
    SelectOnDemandItemsComponent,
    SelectOnDemandstoresComponent,
    SelectOnDemandstoresV2Component,
    SelectOnDemandstoresV3Component,
    SelectOnDemandItemsV2Component,
    SelectOnDemanditemsV3Component,
    SelectOnDemanditemsPagedV3Component,
    FilterChargedComboCommonV2Component,
    GenericProductSelectAsyncComponent,
    InputComboTagsCommonComponent,
    InputAreaSelectedOptionsComponentV2,
    InputAreaSelectedOptionsSearchComponent,
    GenericInputSelectedOptionsComponent,
    InputAreaSelectedOptionsSearchBySupplierComponent,
    InputAreaSelectedOptionsSearchWithVerificationComponent,
    AddStoresModalComponent,
    AddStoresCommonModalComponent,
    AddPlanogramsCommonModalComponent,
    AddStoresBySupplierModalComponent,
    AddVendorsVerifyModalComponent,
    ComboOnModalComponent,
    PopoverComponent,
    CustomLoaderComponent,
    AdvancedSearchHeaderComponent,
    AdvancedSearchFooterComponent,
    NumberKComponent,
    NumberCounterToAnimatedComponent,
    MultipleImplementationComponent,
    BigGreenButtonComponent,
    ClipboardComponent,
    PrismaTableComponent,
    ChipsPrismaTable,
    VirtualScrollPrismaTableComponent,
    PrismaTableSubRowComponent,
    PrismaTableColumnContentComponent,
    SubRowExpandedComponent,
    ClipboardModalComponent,
    ClipboardModalUserComponent,
    ClipboardModalCompetitorsComponent,
    GenericLookupComponent,
    GenericSliderComponent,
    GenericMasiveActionsComponent,
    GenericMessageStructureSelector,
    GenericPromotionalSpacesComponent,
    GenericPromotionalPeriodsComponent,
    GenericPeriodsEmptyMessageComponent,
    GenericPromotionTypeSelector,
    GenericYearSelector,
    GenericPromotionRuleTypeSelector,
    GenericLookupAsyncComponent,
    GenericCategorySelectionMiniV2Component,
    DinamicTagsAdvancedFilterComponentV2,
    DinamicTagsFormComponentV2,
    GenericTagsSelectV2Component,
    GenericTagsSelectMultipleV2Component,
    GenericTagsSelectComponent,
    GenericTagsSelectMultipleIncludedExcludedV2Component,
    CopyVendorsByStoreComponente,
    ContextMenuInsightsComponent,
    EasyLeafletMap,
    PriceMapGenericComponent,
    PrismaRouteToAnotherPageComponent,
    RangePickerDateComponent,
    RangePickerDateCalendarComponent,
    GenericStoreSelectComponent,
    GenericSelectPromotionMasterTypesComponent,
    GenericPromotionReferencePromotionPriceType,
    GenericRadioOptionsComponent,
    GenericRolesUsersConsultantsTreeSelectComponent,
    GenericPromotionTasksActionables,
    GenericSelectTagsTypeComponent,
    GenericSelectTreeComponent,
    GenericDistributionCenterSelectComponent,
    GenericSupplierSelectComponent,
    GenericVendorSelector,
    GenericSelectTagsParentComponent,
    GenericSelectTagsDataTypeComponent,
    GenericSelectItemMasterTypesComponent,
    generic_item_status_select_component,
    generic_brands_select_component,
    AssortmentStatusList,
    SelectWeekdayComponent,
    SelectMonthdayComponent,
    generic_users_select_component,
    generic_type_rules_checkbox_component,
    ShowEntityListModalComponent,
    ImporterComponentV2_selector,
    MarketInformationItemMarketSelectorComponent,
    ModalConfirmationStoresComponent,
    ModalWidthProductComponent,
    StoresListModalComponent,
    ReactiveFormWrapper,
    AssignForecastModalComponent,
  ],
})
export class SharedModule {}
