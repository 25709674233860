import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { _error, _isFeDev, _log } from '@shared/aux_helper_environment';
import { PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';
import { BehaviorSubject, Observable, of, throwError, timer } from 'rxjs';
import { catchError, delayWhen, finalize, map, retry, retryWhen, share, switchMap, tap, timeout } from 'rxjs/operators';
import { GenieConversationResponse, GenieMessage, GenieStatementResponse } from './databricks-genie-ai-chat.models';
import { _cloneDeep } from '@shared/aux_helper_functions';

/*
Docu:
https://docs.databricks.com/en/dev-tools/auth/oauth-m2m.html#language-CLI
ver: FAST TEST CON NODE
*/

const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';

export interface DataBricksAiGenieChatResponse {
  text?: string;
  queryProcessResult?: GenieStatementResponse;
  rawResponse?: GenieMessage;
  error?: GenieErrorType;
}

export enum GenieErrorType {
  AUTH_FAILED = 'AUTH_FAILED',
  RATE_LIMIT = 'RATE_LIMIT',
  TIMEOUT = 'TIMEOUT',
  NETWORK = 'NETWORK',
  UNKNOWN = 'UNKNOWN',
  DISABLED = 'DISABLED',
}

export interface GenieError {
  type: GenieErrorType;
  message: string;
  originalError?: any;
}

@Injectable({
  providedIn: 'root',
})
export class DataBricksAiGenieChatService {
  private readonly TOKEN_STORAGE_KEY = _keyVer + 'dbricks_auth_token';
  private readonly TOKEN_EXPIRY_KEY = _keyVer + 'dbricks_auth_expiry';

  private tokenSubject = new BehaviorSubject<string | null>(null);
  private token$ = this.tokenSubject.asObservable();

  private lasConversationResponseSubject = new BehaviorSubject<GenieConversationResponse | null>(null);

  private rateLimitTimeout?: number;
  private authInProgress: Observable<any> | null = null;

  private getConfig() {
    return this.prismaDynamicEnv.getConf('brandCustomization.dataBricksGenieConfig') || {};
  }

  public CAN_USE_DB_CHAT() {
    return !!(this.getConfig()?.ENABLED === true && this.getConfig()?.BODY?.u && this.getConfig().BODY?.p);
  }

  constructor(private http: HttpClient, private prismaDynamicEnv: PrismaDynamicEnv) {
    this.init();
    if (_isFeDev()) _log(['DataBricksAiGenieChatService'], this.getConfig(), this.CAN_USE_DB_CHAT());
  }

  init() {
    if (!this.CAN_USE_DB_CHAT()) return;

    if (false && _isFeDev()) this.testConversation();
  }

  private testConversation() {
    if (false) {
      this.startConversation('Hello').subscribe(data => {
        _log(2222, '[startConversation]', data);
      });
    }

    if (true) {
      this.sendGenieMessage('Quiero un listado en forma de tabla del ranking de los 3 productos con más venta histórica').subscribe(
        data => {
          _log(1, '[chat]', data);

          this.sendGenieMessage('perfecto, puedes hacer un gráfico de torta con esos mismos datos?').subscribe(data => {
            _log(2, '[chat]', data);
          });
        }
      );
    }
  }

  /** Maneja errores HTTP y los transforma en errores observables */
  private genericHandleError(error: HttpErrorResponse): Observable<never> {
    let genieError: GenieError = {
      type: GenieErrorType.UNKNOWN,
      message: 'An unknown error occurred',
      originalError: error,
    };

    if (error.status === 401) {
      genieError = { type: GenieErrorType.AUTH_FAILED, message: 'Authentication failed' };
      this.removeToken();
    } else if (error.status === 429) {
      genieError = { type: GenieErrorType.RATE_LIMIT, message: 'Rate limit exceeded' };
      this.rateLimitTimeout = Date.now() + this.getConfig().RATE_LIMIT_RESET;
    }

    _error('DataBricks API Error:', genieError);
    return throwError(() => genieError);
  }

  /** Crea headers HTTP con autenticación Bearer */
  private createGenericGenieAuthHeaders(token = this.tokenSubject.value): HttpHeaders {
    return new HttpHeaders({
      ...this.getConfig().HEADERS,
      Authorization: `Bearer ${token}`,
    });
  }

  /** Obtiene un token de autenticación del API de Databricks */
  private authenticate(): Observable<any> {
    if (!this.CAN_USE_DB_CHAT()) return of(null);

    // Si ya hay una autenticación en curso, devolver la misma
    if (this.authInProgress) return this.authInProgress;

    const auth = btoa(`${this.getConfig().BODY.u}:${this.getConfig().BODY.p}`);
    const headers = new HttpHeaders({ Authorization: `Basic ${auth}`, 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = new URLSearchParams({ grant_type: 'client_credentials', scope: 'all-apis' });

    _log('[authenticate databricks]', this.getConfig().API_AUTH, body.toString(), headers);

    // Crear un nuevo observable compartido para todas las llamadas concurrentes
    this.authInProgress = this.http.post<any>(this.getConfig().API_AUTH, body.toString(), { headers }).pipe(
      tap({
        next: response => {
          // Limpiar la referencia cuando termine exitosamente
          setTimeout(() => (this.authInProgress = null));
        },
        error: error => {
          // Limpiar la referencia en caso de error
          this.authInProgress = null;
          _error('Auth error:', error);
        },
      }),
      share()
    );

    return this.authInProgress;
  }

  /** Guarda el token y su tiempo de expiración en localStorage */
  private saveTokenToStorage(token: string, expiresIn: number) {
    const expiryTime = Date.now() + expiresIn * 1000;
    localStorage.setItem(this.TOKEN_STORAGE_KEY, token);
    localStorage.setItem(this.TOKEN_EXPIRY_KEY, expiryTime.toString());
  }

  private getStoredToken(): string | null {
    const token = localStorage.getItem(this.TOKEN_STORAGE_KEY);
    const expiry = localStorage.getItem(this.TOKEN_EXPIRY_KEY);

    if (token && expiry) {
      const expiryTime = Number(expiry);
      const now = Date.now();

      if (now < expiryTime) {
        const timeUntilExpiry = expiryTime - now;

        setTimeout(() => {
          this.removeToken();
        }, timeUntilExpiry * 0.99);

        return token;
      }
    }

    this.removeToken();

    return null;
  }

  private removeToken() {
    this.tokenSubject.next(null);
    try {
      localStorage.removeItem(this.TOKEN_STORAGE_KEY);
      localStorage.removeItem(this.TOKEN_EXPIRY_KEY);
    } catch (e) {}
  }

  /** Gestiona la obtención y renovación del token de autenticación */
  private setToken(): Observable<any> {
    if (!this.CAN_USE_DB_CHAT()) return of(null);

    const storedToken = this.getStoredToken();
    if (storedToken) {
      this.tokenSubject.next(storedToken);
      return this.token$;
    }

    if (this.tokenSubject.value != null) return this.token$;

    return this.authenticate().pipe(
      tap(response => {
        if (response?.access_token && response?.expires_in) {
          this.tokenSubject.next(response.access_token);
          this.saveTokenToStorage(response.access_token, response.expires_in);

          setTimeout(() => {
            this.removeToken();
          }, response.expires_in * 1000 * 0.99);
        } else {
          _error('Error on DB authenticate', response);
        }
      })
    );
  }

  /** Inicia una nueva conversación con la IA */
  private startConversation(content: string): Observable<any> {
    if (!this.CAN_USE_DB_CHAT()) return of(null);

    // Si no hay token, obtenerlo primero
    if (!this.tokenSubject.value) {
      return this.setToken().pipe(switchMap(() => this.startConversation(content)));
    }

    // Si ya tenemos un conversation_id, lo devolvemos
    if (this.lasConversationResponseSubject.value) {
      return new Observable<any>(observer => {
        observer.next(this.lasConversationResponseSubject.value);
        observer.complete();
      });
    }

    const headers = this.createGenericGenieAuthHeaders();
    const url = `${this.getConfig().SPACES_ENDPOINT}/${this.getConfig().SPACE_ID}/start-conversation`;
    const body = { content };

    return this.http.post(url, body, { headers }).pipe(
      tap(response => {
        if (response?.conversation_id) {
          this.lasConversationResponseSubject.next(response);
        } else {
          _error('Error on DB authenticate', response);
        }
      })
    );
  }

  /** Obtiene el resultado de un mensaje específico */
  private getMessageResult(conversationId: string, messageId: string): Observable<any> {
    if (!this.CAN_USE_DB_CHAT()) return of(null);

    const headers = this.createGenericGenieAuthHeaders();

    const url = `${this.getConfig().SPACES_ENDPOINT}/${this.getConfig().SPACE_ID}/conversations/${conversationId}/messages/${messageId}`;

    return this.http.get(url, { headers }).pipe(
      tap(response => {
        if (!response) _error('Error getting message result', response);
      })
    );
  }

  /** Envía un mensaje en una conversación existente o crea una nueva */
  private sendMessage(conversationId: string | null, content: string): Observable<any> {
    if (!this.CAN_USE_DB_CHAT()) return of(null);

    // Si no tenemos conversación guardada o no se proporciona ID, crear una nueva
    if (!this.lasConversationResponseSubject.value?.conversation_id && !conversationId) {
      return this.startConversation(content).pipe(
        switchMap(response => {
          if (response?.conversation_id) {
            return this.sendMessage(response.conversation_id, content);
          }
          _error('Error creating conversation', response);
          return of(null);
        })
      );
    }

    if (!this.tokenSubject.value) {
      return this.setToken().pipe(switchMap(() => this.sendMessage(conversationId, content)));
    }

    const headers = this.createGenericGenieAuthHeaders();

    const finalConversationId = conversationId || this.lasConversationResponseSubject.value?.conversation_id;
    const url = `${this.getConfig().SPACES_ENDPOINT}/${this.getConfig().SPACE_ID}/conversations/${finalConversationId}/messages`;
    const body = { content };

    return this.http.post(url, body, { headers }).pipe(
      tap(response => {
        if (!response?.id) {
          _error('Error sending message', response);
        }
      })
    );
  }

  /**
   * Sistema de polling genérico para consultas que requieren espera
   * @param checkFn Función que realiza la consulta
   * @param predicate Función que evalúa si el resultado está completo
   * @param maxAttempts Número máximo de intentos
   */
  private pollGenieMessage<T>(
    checkFn: () => Observable<T>,
    isComplete: (result: T) => boolean,
    maxAttempts = this.getConfig().MAX_ATTEMPTS
  ): Observable<any> {
    return new Observable(observer => {
      let attempts = 0;

      const poll = () => {
        if (attempts >= maxAttempts) {
          observer.error('Maximum attempts reached');
          return;
        }

        attempts++;

        checkFn()
          .pipe(
            timeout(this.getConfig().TIMEOUT),
            catchError(error => {
              if (attempts >= maxAttempts) {
                throw error;
              }
              return timer(this.getConfig().POLLING_FREQUENCY).pipe(switchMap(() => checkFn()));
            })
          )
          .subscribe({
            next: result => {
              if (isComplete(result)) {
                observer.next(result);
                observer.complete();
              } else {
                setTimeout(poll, this.getConfig().POLLING_FREQUENCY);
              }
            },
            error: error => observer.error(error),
          });
      };

      poll();
    }).pipe(
      retryWhen(errors =>
        errors.pipe(
          delayWhen(() => {
            if (this.rateLimitTimeout && Date.now() < this.rateLimitTimeout) {
              return timer(this.rateLimitTimeout - Date.now());
            }
            return timer(this.getConfig().RETRY_DELAY);
          }),
          retry(this.getConfig().RETRY_ATTEMPTS)
        )
      )
    );
  }

  /** Maneja el flujo completo de envío y espera de respuesta de un mensaje */
  private chat(content: string, conversationId: string | null = null): Observable<any> {
    if (!this.CAN_USE_DB_CHAT()) return of(null);

    return this.sendMessage(conversationId, content).pipe(
      switchMap(response => {
        if (!response?.message_id) return of(null);

        const messageId = response.message_id;
        const convId = response.conversation_id || this.lasConversationResponseSubject.value?.conversation_id;

        return this.pollGenieMessage(
          () => this.getMessageResult(convId, messageId),
          result => result?.status === 'COMPLETED' || result?.status === 'FAILED'
        ).pipe(
          catchError(this.genericHandleError),
          finalize(() => {})
        );
      })
    );
  }

  /**
   * Ejecuta una consulta SQL generada por la IA
   * @param conversationId ID de la conversación
   * @param messageId ID del mensaje que contiene la consulta
   * @param attachmentId ID del attachment que contiene la consulta
   */
  private executeQuery(conversationId: string, messageId: string, attachmentId: string): Observable<GenieStatementResponse> {
    if (!this.CAN_USE_DB_CHAT()) return of(null);

    const headers = this.createGenericGenieAuthHeaders();

    // Updated endpoint with attachment_id
    const url = `${this.getConfig().SPACES_ENDPOINT}/${
      this.getConfig().SPACE_ID
    }/conversations/${conversationId}/messages/${messageId}/query-result/${attachmentId}`;

    //Transformaciones por breakings changes en la API de Databricks
    return this.http.get<any>(url, { headers }).pipe(
      map(response => {
        const formattedResponse = _cloneDeep(response?.statement_response || response || {});

        const statementResponse: GenieStatementResponse = {
          statement_response: {
            ...formattedResponse,
            result: {
              ...formattedResponse.result,
              data_typed_array:
                formattedResponse.result?.data_typed_array ||
                formattedResponse.result?.data_array?.map(row => ({
                  values: row.map(value => {
                    // Determine the type of value and create the corresponding structure
                    if (typeof value === 'string') {
                      return { str: value };
                    } else if (typeof value === 'number') {
                      return { num: value };
                    } else if (typeof value === 'boolean') {
                      return { bool: value };
                    } else if (value === null) {
                      return {}; // Represent null values as empty objects
                    } else {
                      return { str: String(value) }; // Default to string for other types
                    }
                  }),
                })) ||
                [],
            },
          },
        };

        _log('executeQuery - formatted response', {
          original: response,
          formatted: statementResponse,
        });

        return statementResponse;
      }),
      catchError(error => {
        _error('Error executing query', error);
        return throwError(() => error);
      })
    );
  }

  /**
   * Método público principal para interactuar con la IA
   * Maneja tanto respuestas textuales como consultas SQL
   * @param content Mensaje para la IA
   * @param conversationId ID de conversación opcional
   * @param index Índice del attachment a procesar
   */
  public sendGenieMessage(content: string, conversationId?: string, index = 0): Observable<DataBricksAiGenieChatResponse> {
    if (!this.CAN_USE_DB_CHAT()) return of({ error: GenieErrorType.DISABLED });

    if (this.rateLimitTimeout && Date.now() < this.rateLimitTimeout) {
      return throwError(() => ({
        type: GenieErrorType.RATE_LIMIT,
        message: `Rate limit in effect. Please wait ${Math.ceil((this.rateLimitTimeout - Date.now()) / 1000)} seconds`,
      }));
    }

    return this.chat(content, conversationId).pipe(
      switchMap(response => {
        const attachment = response?.attachments?.[index];
        if (!attachment) {
          return of({ text: 'No response available' });
        }

        const baseResponse = {
          text: attachment.text?.content || attachment.query?.description,
          rawResponse: response as GenieMessage,
        };

        return attachment.query?.query && attachment.attachment_id
          ? this.executeQuery(response.conversation_id, response.message_id, attachment.attachment_id).pipe(
              map(queryResult => ({
                ...baseResponse,
                queryProcessResult: queryResult,
              }))
            )
          : of(baseResponse);
      }),
      catchError((error: GenieError) => {
        _error('Chat Text Error:', error);
        return of({
          text: error.message || 'Error processing request',
          error: error.type,
        });
      })
    );
  }

  public resetConversation(): void {
    this.lasConversationResponseSubject.next(null);
  }
}

/*
FAST TEST CON NODE

const https = require('https');
const querystring = require('querystring');

// Credenciales
const user = '60b43d7c-d3d3-40ed-a932-e4872b4bff63';
const pass = 'dose8c6777f7ce9ff7530975f3e42f6cf6a3';

// Codificar en Base64 el formato "user:pass"
const auth = Buffer.from(`${user}:${pass}`).toString('base64');

// Datos de la solicitud
const url = 'https://adb-7921325218031009.9.azuredatabricks.net/oidc/v1/token';
const data = querystring.stringify({
  grant_type: 'client_credentials',
  scope: 'all-apis',
});

// Extraer el host y path de la URL
const urlObj = new URL(url);

// Configuración de las opciones de la solicitud
const options = {
  hostname: urlObj.hostname, // Hostname extraído de la URL
  path: urlObj.pathname, // Path extraído de la URL
  method: 'POST',
  headers: {
    Authorization: `Basic ${auth}`, // Construir la cabecera Authorization dinámicamente
    'Content-Type': 'application/x-www-form-urlencoded',
    'Content-Length': Buffer.byteLength(data), // Tamaño del cuerpo
  },
};

// Hacer la solicitud
const req = https.request(options, (res) => {
  let responseData = '';

  console.log(`Estado: ${res.statusCode}`); // Mostrar el código de estado
  console.log('Cabeceras:', res.headers); // Mostrar las cabeceras de respuesta

  // Recibir los datos del servidor
  res.on('data', (chunk) => {
    responseData += chunk;
  });

  // Finalizar la recepción de datos
  res.on('end', () => {
    console.log('Respuesta del servidor:', responseData);
    try {
      const json = JSON.parse(responseData);
      console.log('JSON parseado:', json);
    } catch (e) {
      console.error('No se pudo parsear la respuesta como JSON:', e.message);
    }
  });
});

// Manejo de errores
req.on('error', (error) => {
  console.error('Error al hacer la solicitud:', error.message);
});

// Escribir los datos del cuerpo en la solicitud
req.write(data);

// Finalizar la solicitud
req.end();

*/
