import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { _log } from '@shared/aux_helper_environment';
import { _formatColumnTitle } from './_aux_dataBricksAiGenieQueryProcessTable';

interface ColumnSelectorData {
  columns: string[];
  numericColumns: string[];
  filteredData: string[];
}

@Component({
  selector: 'column-selector-dialog',
  template: `
    <h2 mat-dialog-title>Seleccionar columnas para el gráfico</h2>
    <div mat-dialog-content *ngIf="init">
      <form [formGroup]="form">
        <div class="column-selectors">
          <mat-form-field class="full-width">
            <mat-label>Columna de etiquetas</mat-label>
            <mat-select formControlName="labelColumn">
              <mat-option *ngFor="let col of getLabelColumns()" [value]="col">
                {{ formatColumnTitle(col) }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Columna de valores (numérica)</mat-label>
            <mat-select formControlName="numericColumn" #numericSelect>
              <mat-option *ngFor="let col of getNumericColumns()" [value]="col">
                {{ formatColumnTitle(col) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button (click)="onCancel()">Cancelar</button>
      <button mat-raised-button color="accent" [disabled]="!form.valid" (click)="onConfirm()">Visualizar</button>
    </div>
  `,
  styles: [
    `
      .column-selectors {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .full-width {
        width: 100%;
      }
    `,
  ],
})
export class ColumnSelectorDialogComponent implements OnInit {
  @ViewChild('numericSelect') numericSelect: any;

  form: FormGroup;
  init = false;

  private _cachedLabelColumns: string[] = null;
  private _cachedNumericColumns: string[] = null;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ColumnSelectorData,
    private dialogRef: MatDialogRef<ColumnSelectorDialogComponent>
  ) {}

  ngOnInit(): void {
    const defaultLabelColumn = this.findDefaultLabelColumn();

    this.form = this.fb.group({
      numericColumn: ['', Validators.required],
      labelColumn: [defaultLabelColumn || '', Validators.required],
    });

    if (true) _log('[ColumnSelectorDialogComponent]', { ...this.data, defaultLabelColumn });

    this.init = true;

    // Dar foco al selector numérico si ya hay una etiqueta predeterminada
    if (defaultLabelColumn) {
      setTimeout(() => {
        this.numericSelect?.focus();
      }, 512);
    }
  }

  private findDefaultLabelColumn(): string | null {
    const labelColumns = this.getLabelColumns();
    return labelColumns.find(col => col.toLowerCase().includes('name') || col.toLowerCase().includes('nombre')) || null;
  }

  getLabelColumns(): string[] {
    if (this._cachedLabelColumns !== null) {
      return this._cachedLabelColumns;
    }

    // Primero filtrar columnas no numéricas
    let allLabelColumns = this.data.columns.filter(col => !this.data.numericColumns.includes(col));

    if (allLabelColumns.length === 0) {
      allLabelColumns = this.data.columns;
      this._cachedLabelColumns = allLabelColumns;
      return allLabelColumns;
    }

    if (allLabelColumns.length === 1) {
      this._cachedLabelColumns = allLabelColumns;
      return allLabelColumns;
    }

    // Filtrar columnas con 'id' primero
    let filteredColumns = allLabelColumns.filter(col => !col.toLowerCase().includes('id'));
    if (filteredColumns.length === 0) filteredColumns = allLabelColumns;

    // Si tenemos datos filtrados, analizar la variabilidad de los valores
    if (this.data.filteredData?.length > 0) {
      const columnVariability = new Map<string, number>();

      // Calcular cuántos valores únicos tiene cada columna
      filteredColumns.forEach(colName => {
        const uniqueValues = new Set(this.data.filteredData.map(row => String(row[colName]))).size;
        columnVariability.set(colName, uniqueValues);
      });

      // Filtrar columnas que tienen más de un valor único
      const columnsWithVariability = filteredColumns.filter(col => {
        const uniqueCount = columnVariability.get(col);
        return uniqueCount > 1;
      });

      // Si quedan columnas después del filtrado, usarlas
      if (columnsWithVariability.length > 0) {
        this._cachedLabelColumns = columnsWithVariability;
        return columnsWithVariability;
      }
    }

    // Si no hay datos filtrados o todas las columnas tienen valores constantes
    this._cachedLabelColumns = filteredColumns;
    return filteredColumns;
  }

  getNumericColumns(): string[] {
    if (this._cachedNumericColumns !== null) {
      return this._cachedNumericColumns;
    }

    const allNumericColumns = this.data.numericColumns;

    // Si hay más de 2 columnas, filtrar las que contengan 'id'
    if (allNumericColumns.length > 1) {
      const filteredColumns = allNumericColumns.filter(col => !col.toLowerCase().includes('id'));
      // Si después del filtrado aún quedan columnas, retornar esas
      if (filteredColumns.length > 0) {
        this._cachedNumericColumns = filteredColumns;
        return filteredColumns;
      }
    }

    this._cachedNumericColumns = allNumericColumns;
    return allNumericColumns;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    if (this.form.valid) this.dialogRef.close(this.form.value);
  }

  private formatColumnTitle(columnName: string): string {
    return _formatColumnTitle(columnName);
  }
}
