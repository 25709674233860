import { environment } from '@env';
import { _isFeDev, _isLab, _isStaging, _log, __log } from '@shared/aux_helper_environment';
import { _cloneDeep } from '@shared/aux_helper_functions';
import { DevSettingsService } from './dev-settings.service';
const brandCustomizationV2Base = require('!raw-loader!../../../brandCustomizationDefaults.json5');
const JSON5 = require('json5');

const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';
const valKeyBrandCustomizationLocalStorage = _keyVer + 'brandCustomization';

const __walkAndSetObjValue = (obj, prefix, callBack) => {
  Object.keys(obj).forEach(key => {
    if (key.indexOf(prefix) === 0 || prefix == null) {
      obj[key] = callBack(obj[key]);
    }
  });

  return obj;
};

const dummyReports = [
  {
    id: 'DASHBOARD',
    section: null,
    description: 'Dashboard',
    enable: true,
    url: 'https://lab.prismaretail.dev/api/dashboard/',
  },
  {
    id: 'PRECIOS',
    section: null,
    description: 'Indice de Competencia',
    enable: true,
    url: 'https://lab.prismaretail.dev/api/dashboardprecios/',
  },
  {
    id: 'PERFORMACE',
    section: null,
    description: 'Benchmarks',
    enable: true,
    url: 'https://lab.prismaretail.dev/api/dashboardperformance/',
  },
  {
    id: 'ASSORTMENT',
    section__: null,
    section: 'assortment',
    description: 'Dashboard Surtido',
    enable: true,
    url: 'https://lab.prismaretail.dev/api/dashboardsurtidos/',
  },
];

const _handleGenericLicences_licensed = (extendedConfig, prefix, forceNoEnabled = false) => {
  if (forceNoEnabled == false) extendedConfig.featuresEnabled.mainMenu[prefix] = true;
};

const _handleGenericLicences_upSelling = (extendedConfig, prefix, forceNoEnabled = false) => {
  if (extendedConfig == null || prefix == null) return extendedConfig;

  __walkAndSetObjValue(extendedConfig.upSellRedirects.swapUrls, prefix, item => {
    return { ...item, enabled: true };
  });
  if (forceNoEnabled == false) _handleGenericLicences_licensed(extendedConfig, prefix);
};

const _handleGenericLicences_noLicensed = (extendedConfig, prefix, forceNoEnabled = false) => {
  if (extendedConfig == null || prefix == null) return extendedConfig;

  _handleGenericLicences_upSelling(extendedConfig, prefix, true);
  if (forceNoEnabled == false) __walkAndSetObjValue(extendedConfig.featuresEnabled.mainMenu, prefix, item => false);
};

const __prepareBrandCustomizationV2HandleLicences = (config, licences) => {
  if (licences == null || config == null) return config;

  let extendedConfig = _cloneDeep(config);

  if (licences.Prices === 'UpSelling') {
    _handleGenericLicences_upSelling(extendedConfig, 'prices');
  }

  if (licences.Prices === 'NoLicensed') {
    _handleGenericLicences_noLicensed(extendedConfig, 'prices');
  }

  if (licences.Assortment === 'UpSelling') {
    _handleGenericLicences_upSelling(extendedConfig, 'assortment');
  }

  if (licences.Assortment === 'NoLicensed') {
    _handleGenericLicences_noLicensed(extendedConfig, 'assortment');
  }

  if (licences.Promotions === 'UpSelling') {
    _handleGenericLicences_upSelling(extendedConfig, 'promotionsV2');
  }

  if (licences.Promotions === 'NoLicensed') {
    _handleGenericLicences_noLicensed(extendedConfig, 'promotionsV2');
  }

  if (licences.MysteryShopper === 'UpSelling') {
    _handleGenericLicences_upSelling(extendedConfig, 'mystery-shopper');
  }

  if (licences.MysteryShopper === 'NoLicensed') {
    _handleGenericLicences_noLicensed(extendedConfig, 'mystery-shopper');
  }

  if (licences.Spaces === 'UpSelling') {
    _handleGenericLicences_upSelling(extendedConfig, 'spaces-v2');
  }

  if (licences.Spaces === 'NoLicensed') {
    _handleGenericLicences_noLicensed(extendedConfig, 'spaces-v2');
  }

  if (licences.SmartSupply === 'UpSelling') {
    _handleGenericLicences_upSelling(extendedConfig, 'supply');
  }

  if (licences.SmartSupply === 'NoLicensed') {
    _handleGenericLicences_noLicensed(extendedConfig, 'supply');
  }

  if (licences.SmartSupply === 'Licensed') {
    _handleGenericLicences_licensed(extendedConfig, 'supply');
  }

  return extendedConfig;
};

const __prepareBrandCustomizationV2ExtendExternalConfig = (base, configV2) => {
  if (configV2 == null || base == null) return base;

  let extendedConfig = base;

  if (configV2.Logo != null) extendedConfig.brandLogoAsset = configV2.Logo;
  if (configV2.Code != null) extendedConfig.code = configV2.Code;

  if (extendedConfig?.featuresEnabled?.mainMenu != null) {
    if (false) extendedConfig.featuresEnabled.mainMenu['admin-interfaces-log'] = false;
  }

  if (extendedConfig.itemMaster) {
    if (configV2.Units?.length > 0) {
      extendedConfig.itemMaster.unitOptions = { ...(extendedConfig.itemMaster.unitOptions || {}), options: configV2.Units };
    }

    // null o false => habilita costos por tiendas. 2256.
    extendedConfig.itemMaster.showTab_vendorsCostsAndTaxes = !!configV2?.UseVendorCosts;

    //DES-1805 / DES-1806 / DES-2082
    if (extendedConfig?.itemMaster?.showTab_vendorsCostsAndTaxes /* si usa UseVendorCosts */) {
      extendedConfig.featuresEnabled.mainMenu['prices-admin-import-store-taxes'] = false; //apaga Impuestos por Punto de Venta
      extendedConfig.featuresEnabled.mainMenu['prices-admin-import-store-costs'] = false; //apaga Costos por Punto de Venta DES-2082
      extendedConfig.featuresEnabled.mainMenu['prices-admin-import-vendor-costs'] = true; //prende Importador de Costos por Proveedor
      extendedConfig.featuresEnabled.mainMenu['admin-master-supplier'] = true; //prende Maestro de Proveedores
    } else {
      extendedConfig.featuresEnabled.mainMenu['prices-admin-import-vendor-costs'] = false; //apaga Importador de Costos por Proveedor
      extendedConfig.featuresEnabled.mainMenu['admin-master-supplier'] = false; //apaga Maestro de Proveedores
      extendedConfig.featuresEnabled.mainMenu['prices-admin-import-store-costs'] = true; //apaga Costos por Punto de Venta DES-2082
    }

    //DEV 3758
    if (_isFeDev()) extendedConfig.featuresEnabled.mainMenu['prices-admin-import-vendor-costs'] = true;

    //DES-2119
    if (configV2.UseRegulatedPrices != null) {
      extendedConfig.itemMaster.regulatedPrices = !!configV2.UseRegulatedPrices;
    }
    if (configV2.UseRegulatedCosts != null) {
      extendedConfig.itemMaster.regulatedCosts = !!configV2.UseRegulatedCosts;
    }
  }

  if (extendedConfig.leafletDefaultOptions) {
    if (configV2.CenterLatitude != null) extendedConfig.leafletDefaultOptions.centerLatitude = configV2.CenterLatitude;
    if (configV2.CenterLongitud != null) extendedConfig.leafletDefaultOptions.centerLongitude = configV2.CenterLongitud;
  }

  extendedConfig = __prepareBrandCustomizationV2HandleLicences(extendedConfig, configV2.Licenses);

  //ASYNCHRONIC_ENDPOINTS
  if (extendedConfig?.featuresEnabled?.blocksEnabled != null) {
    extendedConfig.featuresEnabled.blocksEnabled['PRICES-INFORMTOERP_USE_ASYNCHRONIC_ENDPOINTS'] = true;
  }

  //INTERFACES ImplementedItems  //DES-1881
  if (configV2.Interfaces?.ImplementedItems != null) {
    const ImplementedItems = !!configV2.Interfaces?.ImplementedItems;
    if (extendedConfig?.itemMaster != null) extendedConfig.itemMaster.isReadOnly = ImplementedItems;
  }

  extendedConfig.interfaces.useEndpointStandard = !!configV2?.Interfaces?.UseEndpointStandard;

  return extendedConfig;
};

const __prepareBrandCustomizationV2ExtendWithConfigOverwrites = (base, $configOverwrites) => {
  if (base == null || $configOverwrites == null) return base;

  let extendedConfig = _cloneDeep(base);
  let configOverwrites = _cloneDeep($configOverwrites);

  if (configOverwrites.featuresEnabled) {
    if (configOverwrites.featuresEnabled?.mainMenu && extendedConfig.featuresEnabled?.mainMenu) {
      Object.keys(configOverwrites.featuresEnabled.mainMenu).forEach(key => {
        extendedConfig.featuresEnabled.mainMenu[key] = configOverwrites.featuresEnabled.mainMenu[key];
      });
    }

    if (configOverwrites.featuresEnabled?.blocksEnabled && extendedConfig.featuresEnabled?.blocksEnabled) {
      Object.keys(configOverwrites.featuresEnabled.blocksEnabled).forEach(key => {
        extendedConfig.featuresEnabled.blocksEnabled[key] = configOverwrites.featuresEnabled.blocksEnabled[key];
      });
    }

    extendedConfig = _cloneDeep(extendedConfig);
    delete configOverwrites.featuresEnabled;
  }

  if (configOverwrites.upSellRedirects && extendedConfig.upSellRedirects) {
    if (configOverwrites.upSellRedirects.enabled != null) extendedConfig.upSellRedirects.enabled = configOverwrites.upSellRedirects.enabled;

    if (configOverwrites.upSellRedirects.swapUrls) {
      Object.keys(configOverwrites.upSellRedirects.swapUrls).forEach(key => {
        if (extendedConfig.upSellRedirects.swapUrls == null) extendedConfig.upSellRedirects.swapUrls = {};
        extendedConfig.upSellRedirects.swapUrls[key] = configOverwrites.upSellRedirects.swapUrls[key];
      });
    }

    extendedConfig = _cloneDeep(extendedConfig);
    delete configOverwrites.upSellRedirects;
  }

  if (configOverwrites.reports && configOverwrites.reports.length > 0 && extendedConfig.featuresEnabled.mainMenu) {
    extendedConfig.reports = _cloneDeep(configOverwrites.reports);

    if (extendedConfig.reports.some(report => report.section == null)) {
      extendedConfig.featuresEnabled.mainMenu.reports = true;
    }

    if (extendedConfig.reports.some(report => report.id === 'DASHBOARD')) {
      extendedConfig.featuresEnabled.mainMenu['reports-dashboard'] = true;
    }

    if (extendedConfig.reports.some(report => report.section === 'assortment')) {
      extendedConfig.featuresEnabled.mainMenu['assortment-reports'] = true;
    }

    if (extendedConfig.reports.some(report => report.section === 'prices')) {
      extendedConfig.featuresEnabled.mainMenu['prices-reports'] = true;
    }

    extendedConfig = _cloneDeep(extendedConfig);
    delete configOverwrites.reports;
  }

  if (configOverwrites.itemMaster) {
    if (configOverwrites.itemMaster?.showTab_vendorsCostsAndTaxes != null) {
      if (extendedConfig.itemMaster != null) {
        extendedConfig.itemMaster.showTab_vendorsCostsAndTaxes = configOverwrites.itemMaster.showTab_vendorsCostsAndTaxes;
      }
    }

    //DES-1881
    if (configOverwrites.itemMaster?.isReadOnly != null) {
      if (extendedConfig.itemMaster != null) extendedConfig.itemMaster.isReadOnly = !!configOverwrites.itemMaster.isReadOnly;
    }

    if (configOverwrites.itemMaster?.unitOptions != null) {
      if (extendedConfig.itemMaster != null) extendedConfig.itemMaster.unitOptions = configOverwrites.itemMaster.unitOptions;
    }

    if (configOverwrites.itemMaster?.salesTaxRateDefault != null) {
      if (extendedConfig.itemMaster != null)
        extendedConfig.itemMaster.salesTaxRateDefault = configOverwrites.itemMaster.salesTaxRateDefault;
    }

    //DES-2119
    if (configOverwrites.itemMaster?.regulatedPrices != null) {
      if (extendedConfig.itemMaster != null) extendedConfig.itemMaster.regulatedPrices = configOverwrites.itemMaster.regulatedPrices;
    }
    if (configOverwrites.itemMaster?.regulatedCosts != null) {
      if (extendedConfig.itemMaster != null) extendedConfig.itemMaster.regulatedCosts = configOverwrites.itemMaster.regulatedCosts;
    }

    //DES-4575
    if (configOverwrites.itemMaster?.hasRestrictItemsTypes != null) {
      if (extendedConfig.itemMaster != null)
        extendedConfig.itemMaster.hasRestrictItemsTypes = configOverwrites.itemMaster.hasRestrictItemsTypes;
    }
    if (configOverwrites.itemMaster?.restrictedItemsTypes != null) {
      if (extendedConfig.itemMaster != null)
        extendedConfig.itemMaster.restrictedItemsTypes = configOverwrites.itemMaster.restrictedItemsTypes;
    }

    //CAT-966
    if (configOverwrites.itemMaster?.showDownloadBtn != null) {
      if (extendedConfig.itemMaster != null) extendedConfig.itemMaster.showDownloadBtn = configOverwrites.itemMaster.showDownloadBtn;
    }

    extendedConfig = _cloneDeep(extendedConfig);
    delete configOverwrites.itemMaster;
  }

  if (configOverwrites.spaces) {
    extendedConfig.spaces = { ...(extendedConfig.spaces || {}), ...configOverwrites.spaces };
    extendedConfig = _cloneDeep(extendedConfig);
    delete configOverwrites.spaces;
  }

  if (configOverwrites.priceMapReport) {
    extendedConfig.priceMapReport = { ...(extendedConfig.priceMapReport || {}), ...configOverwrites.priceMapReport };
    extendedConfig = _cloneDeep(extendedConfig);
    delete configOverwrites.priceMapReport;
  }

  //DES-4814
  if (configOverwrites.promotions) {
    extendedConfig.promotions = { ...(extendedConfig.promotions || {}), ...configOverwrites.promotions };
    extendedConfig = _cloneDeep(extendedConfig);
    delete configOverwrites.promotions;
  }

  //CORE-40
  if (configOverwrites.dataBricksGenieConfig) {
    extendedConfig.dataBricksGenieConfig = { ...(extendedConfig.dataBricksGenieConfig || {}), ...configOverwrites.dataBricksGenieConfig };
    extendedConfig = _cloneDeep(extendedConfig);
    delete configOverwrites.dataBricksGenieConfig;
  }

  Object.keys(configOverwrites).forEach(key => {
    extendedConfig[key] = configOverwrites[key];
  });

  extendedConfig.__extended = true;
  return extendedConfig;
};

//[WIP] DES-1811
export const __prepareBrandCustomizationV2 = oldConfigData => {
  let newConfigData = _cloneDeep(oldConfigData);
  let configV2 = _cloneDeep(oldConfigData.brandCustomizationV2);
  let base = brandCustomizationV2Base.default;
  let configLocalStorage = null;

  if (false && _isFeDev()) {
    configV2.UseVendorCosts = true;
  }

  if (configV2 == null || base == null) return newConfigData;

  base = JSON5.parse(base);
  let configOverwrites = configV2?.ConfigOverwrites;

  try {
    if (typeof configOverwrites === 'string') configOverwrites = JSON5.parse(configOverwrites);
  } catch (e) {
    console.error('ERROR:', configOverwrites, e);
  }

  let newBrandCustomization = __prepareBrandCustomizationV2ExtendExternalConfig(base, configV2);
  newBrandCustomization = __prepareBrandCustomizationV2ExtendWithConfigOverwrites(newBrandCustomization, configOverwrites);

  //Extend brandConfig with with local storage
  if (true) {
    configLocalStorage = _getBrandConfigLS();
    if (configLocalStorage != null) {
      newBrandCustomization = __prepareBrandCustomizationV2ExtendWithConfigOverwrites(newBrandCustomization, configLocalStorage);
    }
    if (false) _log(valKeyBrandCustomizationLocalStorage, newBrandCustomization, configLocalStorage);
  }

  newConfigData.brandCustomization = newBrandCustomization;
  delete newConfigData.brandCustomizationV2;

  if (_isFeDev() && false) _log('[prepareBrandCustomizationV2]', { oldConfigData, base, configOverwrites, newConfigData });

  newConfigData._brandCustomizationRaw = oldConfigData.brandCustomizationV2 || oldConfigData.brandCustomization;
  newConfigData._brandCustomizationLocalStorage = configLocalStorage;
  newConfigData._brandCustomizationDefaults = base;
  newConfigData._brandCustomizationDefaultsBase = __prepareBrandCustomizationV2ExtendExternalConfig(base, configV2);

  return newConfigData;
};

export const _getBrandConfigLS = () => {
  let value = localStorage.getItem(valKeyBrandCustomizationLocalStorage);
  if (value == null) return null;

  let rv = null;

  try {
    rv = JSON.parse(value);
  } catch (e) {}

  return rv;
};

export const _setBrandConfigLS = (rv: string | Object) => {
  let val = null;

  if (rv !== '') {
    val = typeof rv === 'string' ? JSON.stringify(JSON5.parse(rv)) : JSON.stringify(rv);
  }

  localStorage.setItem(valKeyBrandCustomizationLocalStorage, val);
};
