import { ABM_StatusEnum } from '@prisma/components/generic-status-select/generic-item-status-select.service';

const StoreV2ListWithoutMap = {
  items: [
    {
      id: 1,
      code: 'F001',
      name: 'Shopping La Plata',
      brandId: 1,
      brand: { id: 1, name: 'SMD', isOwn: true, imageUrl: 'assets/competitors/supermarketsdemo.png', color: '#87A882' },
      isActive: true,
      excempt: false,
      longitude: -57.9706799,
      latitude: -34.920527,
      email: '',
      phone: '',
      address: 'LA PLATA - BUENOS AIRES ',
      openedDate: '2021-02-18T14:32:04.295675',
      storeType: 'Store',
      principalVendorId: null,
      principalVendor: null,
      externalCode: null,
      distributionCenterId: null,
      hasCustomTradeArea: true,
      tradeAreaInMeters: 27000,
      competitorsInTradeArea: [],
      selectedTags: [
        { tagId: 8, tagValues: [23] },
        { tagId: 7, tagValues: [25] },
        { tagId: 6, tagValues: [39] },
        { tagId: 9, tagValues: [45] },
      ],
      tags: null,
      tradeArea: null,
      competitorIdsInTradeArea: null,
      mainCompetitorIdsInTradeArea: null,
      vendors: null,
      browserId: null,
      userName: null,
      status: ABM_StatusEnum.Current,
    },
    {
      id: 2,
      code: 'F002',
      name: 'Alvarez Thomas  I',
      brandId: 1,
      brand: { id: 1, name: 'SMD', isOwn: true, imageUrl: 'assets/competitors/supermarketsdemo.png', color: '#87A882' },
      isActive: true,
      excempt: false,
      longitude: -58.4570319,
      latitude: -34.579395,
      email: '',
      phone: '',
      address: 'CAPITAL FEDERAL - CAPITAL FEDERAL',
      openedDate: '2021-02-18T14:32:04.296652',
      storeType: 'Store',
      principalVendorId: null,
      principalVendor: null,
      externalCode: null,
      distributionCenterId: null,
      hasCustomTradeArea: true,
      tradeAreaInMeters: 50000,
      competitorsInTradeArea: [],
      selectedTags: [
        { tagId: 8, tagValues: [24] },
        { tagId: 7, tagValues: [26] },
        { tagId: 9, tagValues: [32] },
        { tagId: 6, tagValues: [35] },
      ],
      tags: null,
      tradeArea: null,
      competitorIdsInTradeArea: null,
      mainCompetitorIdsInTradeArea: null,
      vendors: null,
      browserId: null,
      userName: null,
      status: ABM_StatusEnum.Modified,
    },
    {
      id: 3,
      code: 'F003',
      name: 'Carranza ',
      brandId: 1,
      brand: { id: 1, name: 'SMD', isOwn: true, imageUrl: 'assets/competitors/supermarketsdemo.png', color: '#87A882' },
      isActive: true,
      excempt: false,
      longitude: -58.4342127,
      latitude: -34.576915,
      email: '',
      phone: '',
      address: 'CAPITAL FEDERAL - CAPITAL FEDERAL',
      openedDate: '2021-02-18T14:32:04.296671',
      storeType: 'Store',
      principalVendorId: null,
      principalVendor: null,
      externalCode: null,
      distributionCenterId: null,
      hasCustomTradeArea: true,
      tradeAreaInMeters: 5000,
      competitorsInTradeArea: [],
      selectedTags: [
        { tagId: 8, tagValues: [24] },
        { tagId: 7, tagValues: [26] },
        { tagId: 9, tagValues: [32] },
        { tagId: 6, tagValues: [36] },
      ],
      tags: null,
      tradeArea: null,
      competitorIdsInTradeArea: null,
      mainCompetitorIdsInTradeArea: null,
      vendors: null,
      browserId: null,
      userName: null,
      status: ABM_StatusEnum.New,
    },
    {
      id: 4,
      code: 'F004',
      name: 'La Imprenta',
      brandId: 1,
      brand: { id: 1, name: 'SMD', isOwn: true, imageUrl: 'assets/competitors/supermarketsdemo.png', color: '#87A882' },
      isActive: true,
      excempt: false,
      longitude: -58.4495336,
      latitude: -34.5769137,
      email: '',
      phone: '',
      address: 'CAPITAL FEDERAL - CAPITAL FEDERAL',
      openedDate: '2021-02-18T14:32:04.296672',
      storeType: 'Store',
      principalVendorId: null,
      principalVendor: null,
      externalCode: null,
      distributionCenterId: null,
      hasCustomTradeArea: false,
      tradeAreaInMeters: 5000,
      competitorsInTradeArea: [],
      selectedTags: [
        { tagId: 8, tagValues: [23] },
        { tagId: 7, tagValues: [26] },
        { tagId: 9, tagValues: [32] },
        { tagId: 6, tagValues: [35] },
      ],
      tags: null,
      tradeArea: null,
      competitorIdsInTradeArea: null,
      mainCompetitorIdsInTradeArea: null,
      vendors: null,
      browserId: null,
      userName: null,
      status: ABM_StatusEnum.New,
    },
    {
      id: 5,
      code: 'F005',
      name: 'Belgrano I',
      brandId: 1,
      brand: { id: 1, name: 'SMD', isOwn: true, imageUrl: 'assets/competitors/supermarketsdemo.png', color: '#87A882' },
      isActive: true,
      excempt: false,
      longitude: -58.4032157,
      latitude: -34.591768,
      email: '',
      phone: '',
      address: 'CAPITAL FEDERAL - CAPITAL FEDERAL',
      openedDate: '2021-02-18T14:32:04.296672',
      storeType: 'Store',
      principalVendorId: null,
      principalVendor: null,
      externalCode: null,
      distributionCenterId: null,
      hasCustomTradeArea: false,
      tradeAreaInMeters: 5000,
      competitorsInTradeArea: [],
      selectedTags: [
        { tagId: 9, tagValues: [22] },
        { tagId: 8, tagValues: [24] },
        { tagId: 7, tagValues: [26] },
        { tagId: 6, tagValues: [35] },
      ],
      tags: null,
      tradeArea: null,
      competitorIdsInTradeArea: null,
      mainCompetitorIdsInTradeArea: null,
      vendors: null,
      browserId: null,
      userName: null,
      status: ABM_StatusEnum.New,
    },
    {
      id: 6,
      code: 'F006',
      name: 'Congreso',
      brandId: 1,
      brand: { id: 1, name: 'SMD', isOwn: true, imageUrl: 'assets/competitors/supermarketsdemo.png', color: '#87A882' },
      isActive: true,
      excempt: false,
      longitude: -58.4025676,
      latitude: -34.609919,
      email: '',
      phone: '',
      address: 'CAPITAL FEDERAL - CAPITAL FEDERAL',
      openedDate: '2021-02-18T14:32:04.296678',
      storeType: 'Store',
      principalVendorId: null,
      principalVendor: null,
      externalCode: null,
      distributionCenterId: null,
      hasCustomTradeArea: false,
      tradeAreaInMeters: 5000,
      competitorsInTradeArea: [],
      selectedTags: [
        { tagId: 9, tagValues: [22] },
        { tagId: 8, tagValues: [24] },
        { tagId: 7, tagValues: [26] },
        { tagId: 6, tagValues: [36] },
      ],
      tags: null,
      tradeArea: null,
      competitorIdsInTradeArea: null,
      mainCompetitorIdsInTradeArea: null,
      vendors: null,
      browserId: null,
      userName: null,
      status: ABM_StatusEnum.New,
    },
    {
      id: 32,
      code: 'asd2',
      name: 'test',
      brandId: 1,
      brand: { id: 1, name: 'SMD', isOwn: true, imageUrl: 'assets/competitors/supermarketsdemo.png', color: '#87A882' },
      isActive: true,
      excempt: false,
      longitude: -58.39353561401368,
      latitude: -34.60808029466195,
      email: 'test',
      phone: '12345678',
      address: '23123',
      openedDate: '2021-06-04T03:00:00',
      storeType: 'Store',
      principalVendorId: null,
      principalVendor: null,
      externalCode: 'test',
      distributionCenterId: null,
      hasCustomTradeArea: true,
      tradeAreaInMeters: 1000,
      competitorsInTradeArea: [],
      selectedTags: [
        { tagId: 8, tagValues: [24] },
        { tagId: 7, tagValues: [25] },
        { tagId: 9, tagValues: [32] },
        { tagId: 6, tagValues: [35] },
      ],
      tags: null,
      tradeArea: null,
      competitorIdsInTradeArea: null,
      mainCompetitorIdsInTradeArea: null,
      vendors: null,
      browserId: null,
      userName: null,
      status: ABM_StatusEnum.New,
    },
    {
      id: 33,
      code: '123',
      name: '123',
      brandId: 1,
      brand: { id: 1, name: 'SMD', isOwn: true, imageUrl: 'assets/competitors/supermarketsdemo.png', color: '#87A882' },
      isActive: true,
      excempt: false,
      longitude: -58.39190961145817,
      latitude: -34.609374575392,
      email: null,
      phone: null,
      address: null,
      openedDate: '2021-07-02T03:00:00',
      storeType: { name: 'DistributionCenter', id: 2 },
      principalVendorId: null,
      principalVendor: null,
      externalCode: '123',
      distributionCenterId: null,
      hasCustomTradeArea: true,
      tradeAreaInMeters: 7000,
      competitorsInTradeArea: [],
      selectedTags: [
        { tagId: 8, tagValues: [24] },
        { tagId: 7, tagValues: [25] },
        { tagId: 9, tagValues: [32] },
        { tagId: 6, tagValues: [35] },
      ],
      tags: null,
      tradeArea: null,
      competitorIdsInTradeArea: null,
      mainCompetitorIdsInTradeArea: null,
      vendors: null,
      browserId: null,
      userName: null,
      status: ABM_StatusEnum.New,
    },
    {
      id: 35,
      code: 'chris test 02 07',
      name: 'chris test 02 07',
      brandId: 1,
      brand: { id: 1, name: 'SMD', isOwn: true, imageUrl: 'assets/competitors/supermarketsdemo.png', color: '#87A882' },
      isActive: true,
      excempt: false,
      longitude: -58.39083194732667,
      latitude: -34.609722739490515,
      email: null,
      phone: null,
      address: null,
      openedDate: '2021-07-22T03:00:00',
      storeType: { name: 'DistributionCenter', id: 2 },
      principalVendorId: null,
      principalVendor: null,
      externalCode: 'chris test 02 07',
      distributionCenterId: null,
      hasCustomTradeArea: true,
      tradeAreaInMeters: 1000,
      competitorsInTradeArea: [],
      selectedTags: [
        { tagId: 8, tagValues: [24] },
        { tagId: 7, tagValues: [25] },
        { tagId: 9, tagValues: [32] },
        { tagId: 6, tagValues: [35] },
      ],
      tags: null,
      tradeArea: null,
      competitorIdsInTradeArea: null,
      mainCompetitorIdsInTradeArea: null,
      vendors: null,
      browserId: null,
      userName: null,
      status: ABM_StatusEnum.New,
    },
    {
      id: 36,
      code: '20210705',
      name: '20210705 - TEST',
      brandId: 1,
      brand: { id: 1, name: 'SMD', isOwn: true, imageUrl: 'assets/competitors/supermarketsdemo.png', color: '#87A882' },
      isActive: true,
      excempt: false,
      longitude: -58.39050641749714,
      latitude: -34.61005533589235,
      email: null,
      phone: null,
      address: null,
      openedDate: '2021-07-05T03:00:00',
      storeType: 'Store',
      principalVendorId: null,
      principalVendor: null,
      externalCode: null,
      distributionCenterId: null,
      hasCustomTradeArea: false,
      tradeAreaInMeters: 1000,
      competitorsInTradeArea: [],
      selectedTags: [
        { tagId: 8, tagValues: [24] },
        { tagId: 7, tagValues: [26] },
        { tagId: 9, tagValues: [32] },
        { tagId: 6, tagValues: [35] },
      ],
      tags: null,
      tradeArea: null,
      competitorIdsInTradeArea: null,
      mainCompetitorIdsInTradeArea: null,
      vendors: null,
      browserId: null,
      userName: null,
      status: ABM_StatusEnum.New,
    },
  ],
  totalItemCount: 36,
  pageIndex: 0,
  pageSize: 10,
};

/* 
{
  items: [
    {
      id: 44,
      imageUrl: null,
      name: 'Coca Cola 1.5L',
      code: 78965220,
      discriminator: 'Retail',
      parentCategoryName: 'Bebidas sin alcohol',
      categoryName: 'Gaseosas',
    },
  ],
  totalItemCount: 1,
  pageIndex: 0,
  pageSize: 10,
}; */

const filters = {
  itemTypes: [1, 2],
  isDeleted: false,
  tags: [],
  categories: [],
  subCategories: [],
  query: '',
  itemStatuses: [0, 1, 5],
  itemAssortmentBehaviors: [0, 1],
  customItemOfStoreIds: [],
  itemCreatedOnFrom: '2021-06-01T00:00:00+00:00',
  itemCreatedOnTo: '2022-06-01T00:00:00+00:00',
};

export const StoreV2ListDummyData = {
  StoreV2ListWithoutMap,
  filters,
};
