import { TagTreeSelectedModel } from '@prisma/components/generic-tags-select-v2/generic-tags-select-v2.component';
import { GenericWarningCostsModel } from 'core/services/ian-core-singleton.service';

export interface IMV2_FormStateModel {
  form: IMV2_FormModel;
  formOriginal: IMV2_FormModel;
}

export interface IMV2_FormModel {
  _inSave?: boolean;
  _isDirty?: boolean;
  _publishOk?: boolean;
  _createdEntity?: boolean;
  _userNotConfirmPublishWarnings?: boolean;
  mainData: IMV2_MainDataModel;
  clasificationData: IMV2_ClasificationModel;
  unitsOfMeasure: IMV2_UnitsOfMeasureModel[];
  vendors: IMV2_VendorsItemsModel[];
  assortmentBehavior: IMV2_AssortmentBehaviorModel;
  prices: IMV2_PricesModel;
  codes: IMV2_codesModel[];
  recipe?: IMV2_recipe;
  combo?: IMV2_combo;
  warningCosts?: GenericWarningCostsModel;
  matchings?: IMV2_matchings[];
}

export interface IMV2_matchings {
  id: number;
  codeTypeId: number;
  itemCode: number;
  name: string;
  brandId: string;
  conversionFactor: number;
  //
  codeType?: string; //No se usa
  brand?: string; //No se usa
}

export interface IMV2_MainDataModel {
  id: number;
  status: IMV2_itemStatus;
  discriminator: IMV2_discriminator;
  name: string;
  shortName: string;
  categoryId: number[];
  code: string;
  itemCreatedOn: string;
  ean?: string;
  isRegionalProduct: boolean;
  /**/
  _L3categoryId?: number[];
  _L4categoryId?: number[];
  publishStatus?: IMV2_publishStatus;
  _salesTaxRate?: number; //DES-1918 se mueve de tab el taxRate
}

export interface IMV2_ClasificationModel {
  tags: TagTreeSelectedModel[];
}

export interface IMV2_UnitsOfMeasureModel {
  id: number;
  code: string;
  name: string;
  conversion: number;
  isSellingUnit: boolean;
  isBaseUnitOfMeasure: boolean;
  /**/
  initialPrice?: number;
  hasExhibition?: boolean;
  height?: number;
  width?: number;
  depth?: number;
  templateMinFrontXSku?: number | null;
  templateMaxFrontXSku?: number | null;
  useFrontLimitsPerItem?: boolean;
  stackable?: boolean;
  maxStacks?: boolean;
  canLayDown?: boolean;
  canRotate?: boolean;
  canRotateY?: boolean;
  imagePlanogramTop?: string;
  imagePlanogramFront?: string;
  imagePlanogramLateral?: string;
  regulated_price: IMV2_RegulatedPriceModel[];
}

//CAT-192 [WIP] Agregar Campos límites de frentes (Mínimo/Máximo) faltan nombres

export interface IMV2_RegulatedPriceModel {
  regulatedPrice: number;
  forceRegulatedPrice: boolean;
  regulatedCost: number;
  forceRegulatedCost: boolean;
  tagValueId: any;
  margin: number;
}

export interface IMV2_VendorsItemsModel {
  id?: number;
  _vendorName?: string;
  vendorId: number;
  vendorCode: string;
  vendorItemNumber: number | string;
  isSecondary: boolean;
  tagValueId?: number;
  tagValueName?: string;
  orderUnitOfMeasureId: number;
  orderUnitOfMeasureConversion: null; //TODO: q sea obligatorio
  orderUnitOfMeasureCode: string;
  usesOwnItemNumbers?: boolean; // Si esta propiedad esta en True, hay que hacer obligatoria la carga de el codigo del item propio del proveedor
  minimumOrder: number; //Default 0
  maximumOrder: number; //Default 0
  costs?: IMV2_VendorsCodesModel[];
}

export interface IMV2_VendorsCodesModel {
  tagValueId: number;
  tagValueName: number | string;
  cost1Code: string;
  cost: number;
  cost2Code: string;
  cost2: number;
  cost3Code: string;
  cost3: number;
  tax1Code: string;
  tax: number;
  tax2Code: string;
  tax2: number;
  tax3Code: string;
  tax3: number;
}

export interface IMV2_codesModel {
  id: number; //5730;
  code: string; //'7791274198991';
  typeId: number; // 1;
  isMain: boolean; //false;
  unitOfMeasureCode?: string; //'U';
  unitOfMeasureId?: number; //8832;
  unitOfMeasureConversion?: number; //8832;
  itemId?: number; //7214;
  _isRequired?: boolean;
}

export interface IMV2_recipe {
  items: IMV2_recipeItems[];
}

export interface IMV2_recipeItems {
  id?: number; //TODO: borrar en el server
  itemId: number;
  consumeUnitOfMeasureId: number;
  quantity: number;
  wastePercentage: number;
  type: IMV2_discriminator;
}

export interface IMV2_combo {
  comboBlocks: IMV2_comboBlocks[];
  comboBlockIds?: number;
  discount?: number;
  excludedStores?: number;
  strategyPriceByComponents?: boolean;
}

export interface IMV2_comboBlocks {
  id: number;
  name: string;
  comboItems: IMV2_comboItems[];
  quantity: number;
  comboItemIds?: number[];
}

export interface IMV2_comboItems {
  id?: number;
  itemId: number;
  salesUnitOfMeasureId: number;
}

export interface IMV2_AssortmentBehaviorModel {
  assortmentBehavior: IMV2_ItemAssortmentBehavior;
  originalAssortmentBehavior?: IMV2_ItemAssortmentBehavior;
  isBlockedForPurchase: boolean;
  isBlockedForSales: boolean;
  cloneStoreAssortment: boolean;
  segmentTagId?: number; // id del tag
  hasAssortmentModuleActive?: boolean; // activa opcion 1 de los 4 butones verdes
  hasPermission?: boolean; // activa el acceso al tab de assormentBehavior
  hasSegment?: boolean;
  forceGetAssormentSegment?: boolean;
  categoryName?: string;
  categoryForAssorment?: number;
  greenButtons?: Array<any>;
  tagMatch?: number;
  originalTagMatch?: number; // copy tag value
  _isLoading?: boolean;
}

export interface IMV2_PricesModel {
  priceInitializationMethod: IMV2_priceinitializationmethod;
  clonePricesFromItemId: number;
  greenButtons?: Array<any>;
  _salesTaxRate?: number; //DES-1918 DEPRECATE caundo la 1918 esté "DONE", Sacar todos los flagDES1918TaxRateGeneral
  priceType?: IMV2_comboPriceTypeEnum;
  fixedPrice?: number;
  discountRate?: number;
}

export interface IMV2_TestTabModel {
  testValue1: string;
  testCategorys: any;
  [key: string]: any;
}

export interface IMV2_AssortmentSegment {
  categoryId: number;
  categoryName: string;
  assortmentTagId: number;
  clusterizationId: number;
  level: number;
}

export interface IMV2_PermissionModel {
  permission: string;
  entityCode: string;
  entityName: string;
}

export enum IMV2_priceinitializationmethod {
  ClonedFromItem = 'ClonedFromItem', //1,
  NationalPrice = 'NationalPrice', //2,
  BasedOnComponents = 'BasedOnComponents', //3, //Esto solo aplica a los combos
}

export enum IMV2_itemStatus {
  Current = 'Current', // > no edita
  New = 'New',
  Endorsed = 'Endorsed',
  Informed = 'Informed',
  Modified = 'Modified', // > no edita, Permite volver a publicar
}

export enum IMV2_ItemAssortmentBehavior {
  BasedOnAssortment = 'BasedOnAssortment',
  ActiveInAllStores = 'ActiveInAllStores',
  OnlyIfPartOfRecipeOrCombo = 'OnlyIfPartOfRecipeOrCombo',
  OnlyStoreAssortment = 'OnlyStoreAssortment',
}

export enum IMV2_discriminator {
  Item = 'Item', // item = 1,
  Ingredient = 'Ingredient', //  ingredient = 2,
  Combo = 'Combo', //  combo = 3,
  Recipe = 'Recipe', //  recipe = 4,
  Supply = 'Supply', //  supply = 5,
}

export enum IMV2_discriminatorInt {
  Item = 1, // item = 1,
  Ingredient = 2, //  ingredient = 2,
  Combo = 3, //  combo = 3,
  Recipe = 4, //  recipe = 4,
  Supply = 5, //  supply = 5,
}

export const IMV2_discriminatorStr2int = (name: IMV2_discriminator): IMV2_discriminatorInt => {
  return IMV2_discriminatorInt[name];
};

export const IMV2_discriminatorArrStr2int = (arr: IMV2_discriminator[]): IMV2_discriminatorInt[] => {
  return arr.map(name => IMV2_discriminatorStr2int(name));
};

export enum IMV2_publishStatus {
  None = 'None',
  Processing = 'Processing',
  Error = 'Error',
}

export enum IMV2_publishStatusNum {
  None = 0,
  Processing = 1,
  Error = 2,
}

export enum IMV2_comboPriceTypeEnum {
  suggestedPrice = 1,
  discount = 2,
}

export enum IMV2_TAB_INDEX {
  MAIN_DATA = 0,
  CLASIFICATION = 1,
  UNIT_MEASURE = 2,
  ASSORTMENT = 3,
  PROVIDERS_COST_TAXES = 4,
  PRICES = 5,
  CODES = 6,
  CHANNEL = 7,
}
