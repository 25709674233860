import { DataBricksAiGenieChatResponse } from '../services/databricks-genie-ai-chat.service';

const isProduction = process.env['NODE_ENV'] === 'production';

// SELECT `store_id`, SUM(`sales_total`) AS `total_sales`, DATE_TRUNC('month', `created_on`) AS `month` FROM `prod`.`oxxogas_produ`.`sales_sales_per_day` WHERE `is_deleted` = FALSE GROUP BY `store_id`, DATE_TRUNC('month', `created_on`) ORDER BY `store_id`, `month`;
export const dummyDataBricksAiGenieChatResponseError: DataBricksAiGenieChatResponse = isProduction
  ? null
  : {
      text: 'Este análisis muestra las ventas totales por tienda, agrupadas por mes. Se considera solo la información activa, excluyendo registros eliminados. Los resultados están organizados por el identificador de la tienda y la fecha del mes correspondiente.',
      rawResponse: {
        id: '01efdfe2973a1888bf9eb6ce2cfd9d40',
        space_id: '01efc9dfddd01b6591241e1ce347725c',
        conversation_id: '01efdfe295b712679b5ad096bfb3cc96',
        user_id: 5906247936159087,
        created_timestamp: 1738334976457,
        last_updated_timestamp: 1738334983973,
        status: 'COMPLETED',
        content:
          "SELECT `store_id`, SUM(`sales_total`) AS `total_sales`, DATE_TRUNC('month', `created_on`) AS `month` FROM `prod`.`oxxogas_produ`.`sales_sales_per_day` WHERE `is_deleted` = FALSE GROUP BY `store_id`, DATE_TRUNC('month', `created_on`) ORDER BY `store_id`, `month`;",
        attachments: [
          {
            query: {
              query:
                "SELECT `store_id`, SUM(`sales_total`) AS `total_sales`, DATE_TRUNC('month', `created_on`) AS `month` FROM `prod`.`oxxogas_produ`.`sales_sales_per_day` WHERE `is_deleted` = FALSE GROUP BY `store_id`, DATE_TRUNC('month', `created_on`) ORDER BY `store_id`, `month`",
              description:
                'Este análisis muestra las ventas totales por tienda, agrupadas por mes. Se considera solo la información activa, excluyendo registros eliminados. Los resultados están organizados por el identificador de la tienda y la fecha del mes correspondiente.',
              ai_function: 'SQL_QUERY',
              ai_function_arguments: {
                execute_sql_query_args: {
                  plan_steps: [
                    {
                      step: 'Filter out deleted records from `sales_sales_per_day`.',
                      hint: 'SELECT * FROM `prod`.`oxxogas_produ`.`sales_sales_per_day` WHERE `is_deleted` = FALSE',
                    },
                    {
                      step: 'Group by `store_id` and month, and sum `sales_total`.',
                      hint: "SELECT `store_id`, SUM(`sales_total`) AS `total_sales`, DATE_TRUNC('month', `created_on`) AS `month` FROM `prod`.`oxxogas_produ`.`sales_sales_per_day` GROUP BY `store_id`, DATE_TRUNC('month', `created_on`) ORDER BY `store_id`, `month`",
                    },
                  ],
                  useful_columns: [
                    'sales_sales_per_day.store_id: int -- used to group by store',
                    'sales_sales_per_day.sales_total: decimal(38,18) -- used to sum sales',
                    'sales_sales_per_day.created_on: timestamp -- used to group by month',
                  ],
                },
              },
              id: '01efdfe29aa91b9bb300af2678763c60',
              statement_id: '01efdfe2-9ab8-1ddd-a5d7-2dbcafba9588',
            },
          },
        ],
        query_result: {
          statement_id: '01efdfe2-9ab8-1ddd-a5d7-2dbcafba9588',
          row_count: 0,
        },
        auto_regenerate_count: 0,
      },
      queryProcessResult: {
        statement_response: {
          statement_id: '01efdfe2-9ab8-1ddd-a5d7-2dbcafba9588',
          status: {
            state: 'SUCCEEDED',
          },
          manifest: {
            format: 'PROTOBUF_ARRAY',
            schema: {
              column_count: 3,
              columns: [
                {
                  name: 'store_id',
                  type_text: 'INT',
                  type_name: 'INT',
                  position: 0,
                },
                {
                  name: 'total_sales',
                  type_text: 'DECIMAL(38,18)',
                  type_name: 'DECIMAL',
                  position: 1,
                  type_precision: 38,
                  type_scale: 18,
                },
                {
                  name: 'month',
                  type_text: 'TIMESTAMP',
                  type_name: 'TIMESTAMP',
                  position: 2,
                },
              ],
            },
            total_chunk_count: 1,
            chunks: [
              {
                chunk_index: 0,
                row_offset: 0,
                row_count: 565,
                byte_count: 16600,
              },
            ],
            total_row_count: 565,
            total_byte_count: 16600,
            truncated: false,
          },
          result: {
            chunk_index: 0,
            row_offset: 0,
            row_count: 565,
            data_typed_array: [
              {
                values: [
                  {
                    str: '-1',
                  },
                  {
                    str: '15882348210.280000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '25',
                  },
                  {
                    str: '71949160.790000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '26',
                  },
                  {
                    str: '133819948.300000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '27',
                  },
                  {
                    str: '145630432.780000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '28',
                  },
                  {
                    str: '78950497.300000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '29',
                  },
                  {
                    str: '170047988.950000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '30',
                  },
                  {
                    str: '177402160.230000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '31',
                  },
                  {
                    str: '139951397.770000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '32',
                  },
                  {
                    str: '141834418.260000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '33',
                  },
                  {
                    str: '284586498.380000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '34',
                  },
                  {
                    str: '113223971.500000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '35',
                  },
                  {
                    str: '113375375.060000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '36',
                  },
                  {
                    str: '233129503.260000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '37',
                  },
                  {
                    str: '140340398.340000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '38',
                  },
                  {
                    str: '87562629.890000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '39',
                  },
                  {
                    str: '81692178.950000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '40',
                  },
                  {
                    str: '220825750.080000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '41',
                  },
                  {
                    str: '61746724.330000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '42',
                  },
                  {
                    str: '85998736.340000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '43',
                  },
                  {
                    str: '136169939.970000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '44',
                  },
                  {
                    str: '193112230.130000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '45',
                  },
                  {
                    str: '58741457.110000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '46',
                  },
                  {
                    str: '242561889.200000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '47',
                  },
                  {
                    str: '211706813.490000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '48',
                  },
                  {
                    str: '209356759.460000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '49',
                  },
                  {
                    str: '299815546.800000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '50',
                  },
                  {
                    str: '158743474.550000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '51',
                  },
                  {
                    str: '254848358.090000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '52',
                  },
                  {
                    str: '77705554.300000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '53',
                  },
                  {
                    str: '201954446.010000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '54',
                  },
                  {
                    str: '60141342.750000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '55',
                  },
                  {
                    str: '66013062.340000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '56',
                  },
                  {
                    str: '155551618.860000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '57',
                  },
                  {
                    str: '144159994.720000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '58',
                  },
                  {
                    str: '109642116.970000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '60',
                  },
                  {
                    str: '134217794.560000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '61',
                  },
                  {
                    str: '63791369.440000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '62',
                  },
                  {
                    str: '213850401.240000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '63',
                  },
                  {
                    str: '135042417.220000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '64',
                  },
                  {
                    str: '205967402.810000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '65',
                  },
                  {
                    str: '100431954.000000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '66',
                  },
                  {
                    str: '208065199.840000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '67',
                  },
                  {
                    str: '128585836.230000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '68',
                  },
                  {
                    str: '1238326862.900000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '69',
                  },
                  {
                    str: '206943548.540000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '70',
                  },
                  {
                    str: '299071486.620000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '71',
                  },
                  {
                    str: '431232045.040000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '72',
                  },
                  {
                    str: '150905817.830000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '73',
                  },
                  {
                    str: '160881784.940000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '74',
                  },
                  {
                    str: '83565733.060000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '75',
                  },
                  {
                    str: '361011023.710000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '76',
                  },
                  {
                    str: '277511353.140000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '77',
                  },
                  {
                    str: '320588486.650000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '78',
                  },
                  {
                    str: '165139825.840000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '79',
                  },
                  {
                    str: '144985698.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '81',
                  },
                  {
                    str: '162963331.650000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '82',
                  },
                  {
                    str: '144313679.640000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '83',
                  },
                  {
                    str: '575035977.970000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '84',
                  },
                  {
                    str: '108104841.810000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '85',
                  },
                  {
                    str: '269518144.850000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '86',
                  },
                  {
                    str: '360643378.210000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '87',
                  },
                  {
                    str: '160927449.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '88',
                  },
                  {
                    str: '221777402.260000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '89',
                  },
                  {
                    str: '45245932.470000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '90',
                  },
                  {
                    str: '163030504.940000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '91',
                  },
                  {
                    str: '166279799.310000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '92',
                  },
                  {
                    str: '230750912.490000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '93',
                  },
                  {
                    str: '153600030.130000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '94',
                  },
                  {
                    str: '266093603.690000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '95',
                  },
                  {
                    str: '161368303.640000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '96',
                  },
                  {
                    str: '89297374.060000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '97',
                  },
                  {
                    str: '314683893.130000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '98',
                  },
                  {
                    str: '115323675.160000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '99',
                  },
                  {
                    str: '100064195.180000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '100',
                  },
                  {
                    str: '140210760.350000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '101',
                  },
                  {
                    str: '371708392.110000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '102',
                  },
                  {
                    str: '274926929.610000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '103',
                  },
                  {
                    str: '160522987.870000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '104',
                  },
                  {
                    str: '224192836.220000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '105',
                  },
                  {
                    str: '223710988.310000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '106',
                  },
                  {
                    str: '250666988.150000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '107',
                  },
                  {
                    str: '535501500.570000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '108',
                  },
                  {
                    str: '41450589.600000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '109',
                  },
                  {
                    str: '161383042.070000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '110',
                  },
                  {
                    str: '239950803.210000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '111',
                  },
                  {
                    str: '64485657.060000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '112',
                  },
                  {
                    str: '107239323.320000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '113',
                  },
                  {
                    str: '204125009.240000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '114',
                  },
                  {
                    str: '74638817.390000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '115',
                  },
                  {
                    str: '162701859.050000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '116',
                  },
                  {
                    str: '156891249.180000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '117',
                  },
                  {
                    str: '157169135.750000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '118',
                  },
                  {
                    str: '103654696.510000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '119',
                  },
                  {
                    str: '245511901.180000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '120',
                  },
                  {
                    str: '125949810.160000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '121',
                  },
                  {
                    str: '78491770.590000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '122',
                  },
                  {
                    str: '237271214.890000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '123',
                  },
                  {
                    str: '157427661.060000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '124',
                  },
                  {
                    str: '93114605.660000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '125',
                  },
                  {
                    str: '88923727.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '126',
                  },
                  {
                    str: '79005538.930000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '127',
                  },
                  {
                    str: '504165445.640000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '128',
                  },
                  {
                    str: '96955692.200000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '129',
                  },
                  {
                    str: '206601221.420000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '130',
                  },
                  {
                    str: '389890035.270000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '131',
                  },
                  {
                    str: '157697147.380000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '132',
                  },
                  {
                    str: '600479991.390000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '133',
                  },
                  {
                    str: '89390683.850000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '134',
                  },
                  {
                    str: '147085025.680000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '135',
                  },
                  {
                    str: '263929667.280000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '136',
                  },
                  {
                    str: '586409941.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '137',
                  },
                  {
                    str: '372246441.970000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '138',
                  },
                  {
                    str: '170972904.710000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '139',
                  },
                  {
                    str: '239002722.620000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '140',
                  },
                  {
                    str: '172331568.000000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '141',
                  },
                  {
                    str: '152638921.390000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '142',
                  },
                  {
                    str: '41000612.660000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '143',
                  },
                  {
                    str: '247631501.640000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '144',
                  },
                  {
                    str: '172062809.370000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '145',
                  },
                  {
                    str: '249232533.210000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '146',
                  },
                  {
                    str: '220566297.280000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '147',
                  },
                  {
                    str: '121142632.480000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '148',
                  },
                  {
                    str: '92892424.730000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '149',
                  },
                  {
                    str: '95506070.480000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '150',
                  },
                  {
                    str: '128711111.890000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '151',
                  },
                  {
                    str: '202459796.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '152',
                  },
                  {
                    str: '85562550.440000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '153',
                  },
                  {
                    str: '256335683.990000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '154',
                  },
                  {
                    str: '196844536.890000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '155',
                  },
                  {
                    str: '173711947.780000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '156',
                  },
                  {
                    str: '91253408.510000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '157',
                  },
                  {
                    str: '82915949.980000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '158',
                  },
                  {
                    str: '106841945.170000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '159',
                  },
                  {
                    str: '159864307.620000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '160',
                  },
                  {
                    str: '303272302.540000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '161',
                  },
                  {
                    str: '391291064.090000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '162',
                  },
                  {
                    str: '95385760.650000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '163',
                  },
                  {
                    str: '141197937.880000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '164',
                  },
                  {
                    str: '37807934.950000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '165',
                  },
                  {
                    str: '104138367.350000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '166',
                  },
                  {
                    str: '315991340.820000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '167',
                  },
                  {
                    str: '165318066.170000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '168',
                  },
                  {
                    str: '226456325.750000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '169',
                  },
                  {
                    str: '87834505.810000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '170',
                  },
                  {
                    str: '236907637.260000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '171',
                  },
                  {
                    str: '97901626.900000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '172',
                  },
                  {
                    str: '217986557.330000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '173',
                  },
                  {
                    str: '166424179.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '174',
                  },
                  {
                    str: '108800241.270000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '175',
                  },
                  {
                    str: '210174637.640000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '176',
                  },
                  {
                    str: '53992666.750000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '177',
                  },
                  {
                    str: '114304426.840000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '178',
                  },
                  {
                    str: '246918978.650000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '179',
                  },
                  {
                    str: '328035581.760000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '180',
                  },
                  {
                    str: '57429279.870000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '181',
                  },
                  {
                    str: '149062616.450000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '182',
                  },
                  {
                    str: '280154660.150000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '183',
                  },
                  {
                    str: '137429081.110000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '184',
                  },
                  {
                    str: '97123177.930000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '185',
                  },
                  {
                    str: '197949985.180000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '186',
                  },
                  {
                    str: '70817998.620000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '187',
                  },
                  {
                    str: '133633845.830000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '188',
                  },
                  {
                    str: '108589381.610000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '189',
                  },
                  {
                    str: '1008621648.520000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '190',
                  },
                  {
                    str: '220471719.690000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '191',
                  },
                  {
                    str: '123012234.390000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '192',
                  },
                  {
                    str: '318896852.560000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '193',
                  },
                  {
                    str: '153236868.330000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '194',
                  },
                  {
                    str: '96908708.010000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '195',
                  },
                  {
                    str: '126908411.630000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '196',
                  },
                  {
                    str: '112515933.810000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '197',
                  },
                  {
                    str: '167370924.530000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '198',
                  },
                  {
                    str: '134412854.870000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '199',
                  },
                  {
                    str: '109027509.680000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '200',
                  },
                  {
                    str: '179005631.200000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '201',
                  },
                  {
                    str: '278034258.590000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '202',
                  },
                  {
                    str: '375935234.980000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '203',
                  },
                  {
                    str: '125545895.260000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '204',
                  },
                  {
                    str: '114904715.330000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '205',
                  },
                  {
                    str: '304623199.100000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '206',
                  },
                  {
                    str: '157196019.810000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '207',
                  },
                  {
                    str: '140133794.930000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '208',
                  },
                  {
                    str: '150925459.860000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '209',
                  },
                  {
                    str: '60950879.690000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '210',
                  },
                  {
                    str: '137063235.340000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '211',
                  },
                  {
                    str: '545638230.020000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '212',
                  },
                  {
                    str: '204870988.060000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '213',
                  },
                  {
                    str: '90869334.830000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '214',
                  },
                  {
                    str: '80383793.980000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '215',
                  },
                  {
                    str: '198943262.670000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '216',
                  },
                  {
                    str: '73493603.130000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '217',
                  },
                  {
                    str: '120691601.470000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '218',
                  },
                  {
                    str: '359769800.100000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '219',
                  },
                  {
                    str: '100440346.090000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '220',
                  },
                  {
                    str: '230695775.970000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '221',
                  },
                  {
                    str: '287222412.180000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '222',
                  },
                  {
                    str: '157712044.220000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '223',
                  },
                  {
                    str: '132955687.940000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '224',
                  },
                  {
                    str: '559479096.210000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '225',
                  },
                  {
                    str: '557045429.100000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '226',
                  },
                  {
                    str: '231277500.670000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '227',
                  },
                  {
                    str: '425064560.010000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '228',
                  },
                  {
                    str: '247923831.610000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '229',
                  },
                  {
                    str: '126692424.220000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '230',
                  },
                  {
                    str: '153219593.430000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '231',
                  },
                  {
                    str: '81596397.230000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '232',
                  },
                  {
                    str: '272661989.480000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '233',
                  },
                  {
                    str: '209018160.050000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '235',
                  },
                  {
                    str: '205560874.400000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '236',
                  },
                  {
                    str: '350345081.890000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '237',
                  },
                  {
                    str: '367039533.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '238',
                  },
                  {
                    str: '38610913.380000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '239',
                  },
                  {
                    str: '113632568.680000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '240',
                  },
                  {
                    str: '264457250.700000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '241',
                  },
                  {
                    str: '156823489.200000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '242',
                  },
                  {
                    str: '311485342.170000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '243',
                  },
                  {
                    str: '237784887.220000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '244',
                  },
                  {
                    str: '96188858.930000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '245',
                  },
                  {
                    str: '364967097.830000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '246',
                  },
                  {
                    str: '79370170.110000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '247',
                  },
                  {
                    str: '80536428.910000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '248',
                  },
                  {
                    str: '126660209.150000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '249',
                  },
                  {
                    str: '215168205.370000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '250',
                  },
                  {
                    str: '199423351.390000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '251',
                  },
                  {
                    str: '189109885.750000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '252',
                  },
                  {
                    str: '386367075.390000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '253',
                  },
                  {
                    str: '80592818.240000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '254',
                  },
                  {
                    str: '153111297.470000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '255',
                  },
                  {
                    str: '129041776.410000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '256',
                  },
                  {
                    str: '500118930.640000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '257',
                  },
                  {
                    str: '96018313.230000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '258',
                  },
                  {
                    str: '159483148.230000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '259',
                  },
                  {
                    str: '145532070.810000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '260',
                  },
                  {
                    str: '265871087.340000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '261',
                  },
                  {
                    str: '430157006.510000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '262',
                  },
                  {
                    str: '286364187.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '263',
                  },
                  {
                    str: '142762337.350000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '264',
                  },
                  {
                    str: '156996593.380000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '265',
                  },
                  {
                    str: '43259176.200000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '266',
                  },
                  {
                    str: '62164050.610000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '267',
                  },
                  {
                    str: '393993945.100000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '268',
                  },
                  {
                    str: '297871583.020000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '269',
                  },
                  {
                    str: '171706119.850000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '270',
                  },
                  {
                    str: '640262066.780000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '271',
                  },
                  {
                    str: '137652563.980000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '272',
                  },
                  {
                    str: '109463548.320000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '273',
                  },
                  {
                    str: '300301069.010000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '274',
                  },
                  {
                    str: '168679523.070000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '275',
                  },
                  {
                    str: '108665339.710000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '276',
                  },
                  {
                    str: '156412960.730000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '277',
                  },
                  {
                    str: '59432387.320000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '278',
                  },
                  {
                    str: '137838291.520000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '279',
                  },
                  {
                    str: '71995919.940000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '280',
                  },
                  {
                    str: '257728551.110000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '281',
                  },
                  {
                    str: '193601911.830000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '282',
                  },
                  {
                    str: '112963810.910000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '283',
                  },
                  {
                    str: '281738281.700000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '284',
                  },
                  {
                    str: '193797903.000000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '285',
                  },
                  {
                    str: '130619592.520000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '286',
                  },
                  {
                    str: '178769041.470000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '287',
                  },
                  {
                    str: '217005230.640000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '288',
                  },
                  {
                    str: '165033124.260000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '289',
                  },
                  {
                    str: '517622291.530000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '290',
                  },
                  {
                    str: '235377454.940000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '291',
                  },
                  {
                    str: '140409465.850000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '292',
                  },
                  {
                    str: '320821520.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '293',
                  },
                  {
                    str: '97907368.160000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '294',
                  },
                  {
                    str: '93698711.610000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '295',
                  },
                  {
                    str: '465207108.520000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '296',
                  },
                  {
                    str: '75180871.000000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '297',
                  },
                  {
                    str: '57753735.840000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '298',
                  },
                  {
                    str: '163796713.610000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '299',
                  },
                  {
                    str: '149975949.560000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '300',
                  },
                  {
                    str: '119746309.070000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '301',
                  },
                  {
                    str: '546244081.400000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '302',
                  },
                  {
                    str: '265980002.920000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '303',
                  },
                  {
                    str: '470882156.570000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '304',
                  },
                  {
                    str: '120284195.090000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '305',
                  },
                  {
                    str: '239056117.620000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '306',
                  },
                  {
                    str: '177832650.410000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '307',
                  },
                  {
                    str: '265579075.450000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '308',
                  },
                  {
                    str: '564209264.510000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '309',
                  },
                  {
                    str: '219244403.090000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '310',
                  },
                  {
                    str: '169096457.160000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '311',
                  },
                  {
                    str: '83431037.960000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '312',
                  },
                  {
                    str: '109742344.350000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '313',
                  },
                  {
                    str: '119303092.020000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '314',
                  },
                  {
                    str: '358074237.040000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '315',
                  },
                  {
                    str: '57746874.520000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '316',
                  },
                  {
                    str: '397880440.670000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '317',
                  },
                  {
                    str: '51210944.610000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '318',
                  },
                  {
                    str: '128376059.180000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '319',
                  },
                  {
                    str: '300258949.610000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '320',
                  },
                  {
                    str: '215184767.280000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '321',
                  },
                  {
                    str: '241781114.160000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '322',
                  },
                  {
                    str: '61020872.960000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '323',
                  },
                  {
                    str: '210847676.920000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '324',
                  },
                  {
                    str: '125881793.000000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '325',
                  },
                  {
                    str: '361885930.510000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '326',
                  },
                  {
                    str: '188661865.200000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '327',
                  },
                  {
                    str: '152147251.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '328',
                  },
                  {
                    str: '300184239.840000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '329',
                  },
                  {
                    str: '228997520.970000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '330',
                  },
                  {
                    str: '93221593.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '331',
                  },
                  {
                    str: '258127768.930000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '332',
                  },
                  {
                    str: '217998360.590000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '333',
                  },
                  {
                    str: '279173066.540000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '334',
                  },
                  {
                    str: '306275839.300000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '335',
                  },
                  {
                    str: '185861608.100000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '336',
                  },
                  {
                    str: '183553954.030000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '337',
                  },
                  {
                    str: '96378805.520000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '338',
                  },
                  {
                    str: '224414249.390000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '339',
                  },
                  {
                    str: '154333673.320000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '340',
                  },
                  {
                    str: '175626201.030000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '341',
                  },
                  {
                    str: '141864326.800000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '342',
                  },
                  {
                    str: '292710575.150000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '343',
                  },
                  {
                    str: '149133369.980000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '344',
                  },
                  {
                    str: '65498314.400000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '345',
                  },
                  {
                    str: '145396998.710000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '346',
                  },
                  {
                    str: '137101139.680000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '347',
                  },
                  {
                    str: '227191069.130000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '348',
                  },
                  {
                    str: '161843230.350000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '349',
                  },
                  {
                    str: '182889683.040000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '350',
                  },
                  {
                    str: '655168280.480000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '351',
                  },
                  {
                    str: '214385064.090000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '352',
                  },
                  {
                    str: '255245103.530000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '353',
                  },
                  {
                    str: '222495123.070000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '354',
                  },
                  {
                    str: '130129471.840000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '355',
                  },
                  {
                    str: '243744151.200000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '356',
                  },
                  {
                    str: '127665758.780000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '357',
                  },
                  {
                    str: '324159923.580000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '358',
                  },
                  {
                    str: '85228580.460000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '359',
                  },
                  {
                    str: '482060674.250000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '360',
                  },
                  {
                    str: '215181769.750000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '361',
                  },
                  {
                    str: '98058318.820000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '362',
                  },
                  {
                    str: '382964866.590000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '363',
                  },
                  {
                    str: '295955613.720000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '364',
                  },
                  {
                    str: '303880563.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '365',
                  },
                  {
                    str: '94971264.030000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '366',
                  },
                  {
                    str: '152292155.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '367',
                  },
                  {
                    str: '363699647.810000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '368',
                  },
                  {
                    str: '151943574.610000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '369',
                  },
                  {
                    str: '182293182.250000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '370',
                  },
                  {
                    str: '152716860.300000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '371',
                  },
                  {
                    str: '206656697.770000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '372',
                  },
                  {
                    str: '169508114.590000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '373',
                  },
                  {
                    str: '294599008.800000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '374',
                  },
                  {
                    str: '120220694.430000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '375',
                  },
                  {
                    str: '67566456.340000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '376',
                  },
                  {
                    str: '136709248.070000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '377',
                  },
                  {
                    str: '264011668.510000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '378',
                  },
                  {
                    str: '335536092.640000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '379',
                  },
                  {
                    str: '188985863.950000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '380',
                  },
                  {
                    str: '83701640.520000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '381',
                  },
                  {
                    str: '108795826.920000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '382',
                  },
                  {
                    str: '68708603.430000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '383',
                  },
                  {
                    str: '285776862.110000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '384',
                  },
                  {
                    str: '159688777.960000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '385',
                  },
                  {
                    str: '80618388.070000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '386',
                  },
                  {
                    str: '488961464.890000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '387',
                  },
                  {
                    str: '171117065.250000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '388',
                  },
                  {
                    str: '450937822.430000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '389',
                  },
                  {
                    str: '203884522.520000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '390',
                  },
                  {
                    str: '104738154.530000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '391',
                  },
                  {
                    str: '395503243.670000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '392',
                  },
                  {
                    str: '17351865.370000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '393',
                  },
                  {
                    str: '260227303.930000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '394',
                  },
                  {
                    str: '78436949.700000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '395',
                  },
                  {
                    str: '151245433.630000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '396',
                  },
                  {
                    str: '46344841.460000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '397',
                  },
                  {
                    str: '190324617.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '398',
                  },
                  {
                    str: '161688457.020000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '399',
                  },
                  {
                    str: '132639653.050000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '400',
                  },
                  {
                    str: '400256277.330000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '401',
                  },
                  {
                    str: '260976421.400000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '402',
                  },
                  {
                    str: '118369745.340000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '403',
                  },
                  {
                    str: '338110861.460000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '404',
                  },
                  {
                    str: '83769668.880000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '405',
                  },
                  {
                    str: '46819262.510000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '406',
                  },
                  {
                    str: '133263289.500000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '407',
                  },
                  {
                    str: '104284271.160000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '408',
                  },
                  {
                    str: '157776514.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '409',
                  },
                  {
                    str: '76104044.700000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '410',
                  },
                  {
                    str: '154303780.560000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '411',
                  },
                  {
                    str: '80376463.260000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '412',
                  },
                  {
                    str: '158665523.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '413',
                  },
                  {
                    str: '224954475.110000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '414',
                  },
                  {
                    str: '206625783.030000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '415',
                  },
                  {
                    str: '131534416.790000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '416',
                  },
                  {
                    str: '175610883.090000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '419',
                  },
                  {
                    str: '150750408.600000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '420',
                  },
                  {
                    str: '95591976.890000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '421',
                  },
                  {
                    str: '111697873.630000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '423',
                  },
                  {
                    str: '42114085.600000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '424',
                  },
                  {
                    str: '75468963.880000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '425',
                  },
                  {
                    str: '3937652.890000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '426',
                  },
                  {
                    str: '170091310.530000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '427',
                  },
                  {
                    str: '120725205.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '428',
                  },
                  {
                    str: '208204481.310000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '430',
                  },
                  {
                    str: '480726034.930000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '431',
                  },
                  {
                    str: '282557489.900000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '432',
                  },
                  {
                    str: '84347298.510000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '433',
                  },
                  {
                    str: '120052786.580000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '434',
                  },
                  {
                    str: '127977710.960000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '435',
                  },
                  {
                    str: '152056631.790000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '436',
                  },
                  {
                    str: '97670962.380000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '437',
                  },
                  {
                    str: '195770821.760000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '438',
                  },
                  {
                    str: '242925524.110000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '439',
                  },
                  {
                    str: '143608104.010000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '440',
                  },
                  {
                    str: '70484623.930000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '441',
                  },
                  {
                    str: '230823951.500000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '442',
                  },
                  {
                    str: '131521404.390000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '443',
                  },
                  {
                    str: '260623105.700000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '444',
                  },
                  {
                    str: '167105786.540000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '445',
                  },
                  {
                    str: '132392448.170000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '446',
                  },
                  {
                    str: '51787632.540000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '447',
                  },
                  {
                    str: '77634035.950000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '448',
                  },
                  {
                    str: '468236131.800000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '449',
                  },
                  {
                    str: '168256139.660000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '450',
                  },
                  {
                    str: '163227882.560000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '451',
                  },
                  {
                    str: '60338273.970000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '452',
                  },
                  {
                    str: '135890556.230000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '453',
                  },
                  {
                    str: '80344688.390000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '454',
                  },
                  {
                    str: '93488414.330000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '455',
                  },
                  {
                    str: '134623222.430000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '456',
                  },
                  {
                    str: '61150620.100000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '457',
                  },
                  {
                    str: '58689175.590000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '459',
                  },
                  {
                    str: '137467501.140000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '460',
                  },
                  {
                    str: '165509770.630000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '512',
                  },
                  {
                    str: '154248319.290000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '513',
                  },
                  {
                    str: '113303165.420000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '514',
                  },
                  {
                    str: '193204911.600000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '515',
                  },
                  {
                    str: '141109624.520000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '516',
                  },
                  {
                    str: '120645699.670000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '517',
                  },
                  {
                    str: '444895800.590000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '518',
                  },
                  {
                    str: '226598570.960000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '519',
                  },
                  {
                    str: '157086430.130000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '520',
                  },
                  {
                    str: '133052565.860000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '521',
                  },
                  {
                    str: '404447403.620000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '522',
                  },
                  {
                    str: '250898464.910000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '523',
                  },
                  {
                    str: '148346919.830000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '524',
                  },
                  {
                    str: '207869540.310000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '525',
                  },
                  {
                    str: '96834997.550000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '526',
                  },
                  {
                    str: '152471069.300000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '527',
                  },
                  {
                    str: '165220696.910000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '528',
                  },
                  {
                    str: '15045852.260000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '529',
                  },
                  {
                    str: '12801126.470000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '530',
                  },
                  {
                    str: '34337432.910000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '532',
                  },
                  {
                    str: '24907840.070000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '533',
                  },
                  {
                    str: '14982317.380000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '534',
                  },
                  {
                    str: '32394353.220000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '535',
                  },
                  {
                    str: '24176568.780000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '537',
                  },
                  {
                    str: '20140817.930000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '538',
                  },
                  {
                    str: '37746219.080000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '539',
                  },
                  {
                    str: '3728013.180000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '540',
                  },
                  {
                    str: '14991019.800000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '541',
                  },
                  {
                    str: '48233066.620000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '542',
                  },
                  {
                    str: '34085128.650000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '543',
                  },
                  {
                    str: '24117315.590000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '544',
                  },
                  {
                    str: '32926338.730000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '545',
                  },
                  {
                    str: '7873932.060000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '546',
                  },
                  {
                    str: '7368437.360000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '547',
                  },
                  {
                    str: '41321886.890000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '548',
                  },
                  {
                    str: '22892741.850000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '549',
                  },
                  {
                    str: '33236934.750000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '550',
                  },
                  {
                    str: '4476488.970000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '551',
                  },
                  {
                    str: '59552274.680000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '552',
                  },
                  {
                    str: '11579405.490000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '553',
                  },
                  {
                    str: '18929334.600000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '554',
                  },
                  {
                    str: '6342883.450000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '555',
                  },
                  {
                    str: '16827849.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '556',
                  },
                  {
                    str: '11967963.420000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '557',
                  },
                  {
                    str: '10126971.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '558',
                  },
                  {
                    str: '15894450.460000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '559',
                  },
                  {
                    str: '20905855.150000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '560',
                  },
                  {
                    str: '14219682.780000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '561',
                  },
                  {
                    str: '45983855.530000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '562',
                  },
                  {
                    str: '15311443.520000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '563',
                  },
                  {
                    str: '23760790.080000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '564',
                  },
                  {
                    str: '6418065.800000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '565',
                  },
                  {
                    str: '9838247.670000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '566',
                  },
                  {
                    str: '47073080.630000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '567',
                  },
                  {
                    str: '14331816.470000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '568',
                  },
                  {
                    str: '11569467.220000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '569',
                  },
                  {
                    str: '12756278.470000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '570',
                  },
                  {
                    str: '22375661.130000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '571',
                  },
                  {
                    str: '8337270.410000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '572',
                  },
                  {
                    str: '17168802.400000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '573',
                  },
                  {
                    str: '13607806.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '574',
                  },
                  {
                    str: '7920822.410000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '576',
                  },
                  {
                    str: '9810558.820000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '577',
                  },
                  {
                    str: '12086124.550000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '580',
                  },
                  {
                    str: '14250747.310000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '581',
                  },
                  {
                    str: '13112958.630000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '582',
                  },
                  {
                    str: '9940534.510000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '583',
                  },
                  {
                    str: '3921407.600000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '584',
                  },
                  {
                    str: '43243298.580000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '585',
                  },
                  {
                    str: '18505677.190000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '586',
                  },
                  {
                    str: '8462726.500000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '587',
                  },
                  {
                    str: '20895023.720000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '588',
                  },
                  {
                    str: '19490280.810000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '589',
                  },
                  {
                    str: '16304946.950000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '590',
                  },
                  {
                    str: '14199663.880000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '591',
                  },
                  {
                    str: '11122315.030000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '592',
                  },
                  {
                    str: '31637303.920000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '593',
                  },
                  {
                    str: '29239263.200000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '595',
                  },
                  {
                    str: '31974915.690000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '596',
                  },
                  {
                    str: '9175547.480000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '597',
                  },
                  {
                    str: '44058294.460000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '598',
                  },
                  {
                    str: '10553280.600000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '599',
                  },
                  {
                    str: '16261025.780000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '600',
                  },
                  {
                    str: '41402648.490000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '601',
                  },
                  {
                    str: '23697163.910000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '602',
                  },
                  {
                    str: '19697575.150000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '603',
                  },
                  {
                    str: '17864048.870000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '604',
                  },
                  {
                    str: '29582149.720000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '605',
                  },
                  {
                    str: '32459328.590000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '606',
                  },
                  {
                    str: '13564771.760000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '607',
                  },
                  {
                    str: '33636941.840000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '608',
                  },
                  {
                    str: '32111238.170000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '609',
                  },
                  {
                    str: '21389525.300000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '611',
                  },
                  {
                    str: '28350293.260000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '612',
                  },
                  {
                    str: '16596607.110000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '613',
                  },
                  {
                    str: '24438308.140000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '614',
                  },
                  {
                    str: '29454299.450000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '615',
                  },
                  {
                    str: '28825038.720000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '616',
                  },
                  {
                    str: '26294943.210000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '617',
                  },
                  {
                    str: '8971846.780000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '618',
                  },
                  {
                    str: '13884407.380000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '619',
                  },
                  {
                    str: '16381557.210000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '620',
                  },
                  {
                    str: '32764531.570000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '621',
                  },
                  {
                    str: '42805959.830000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '622',
                  },
                  {
                    str: '52051382.350000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '623',
                  },
                  {
                    str: '9391171.920000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '624',
                  },
                  {
                    str: '57417746.630000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '625',
                  },
                  {
                    str: '41578058.450000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '626',
                  },
                  {
                    str: '10647946.850000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '627',
                  },
                  {
                    str: '15462826.930000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '628',
                  },
                  {
                    str: '23891226.210000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '629',
                  },
                  {
                    str: '21384809.200000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '630',
                  },
                  {
                    str: '22014598.040000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '631',
                  },
                  {
                    str: '50425807.000000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '632',
                  },
                  {
                    str: '26187323.120000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '633',
                  },
                  {
                    str: '53831902.400000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '635',
                  },
                  {
                    str: '26343643.480000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '636',
                  },
                  {
                    str: '28416103.490000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '637',
                  },
                  {
                    str: '16538301.130000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '638',
                  },
                  {
                    str: '13650015.700000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '639',
                  },
                  {
                    str: '50689365.600000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '640',
                  },
                  {
                    str: '6912963.410000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '641',
                  },
                  {
                    str: '41281848.110000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '642',
                  },
                  {
                    str: '8131483.940000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '644',
                  },
                  {
                    str: '15208687.130000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '645',
                  },
                  {
                    str: '98506192.680000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '647',
                  },
                  {
                    str: '47113610.700000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '649',
                  },
                  {
                    str: '23870132.060000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '650',
                  },
                  {
                    str: '35196219.510000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '651',
                  },
                  {
                    str: '22234986.010000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '653',
                  },
                  {
                    str: '24172148.700000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '655',
                  },
                  {
                    str: '49297833.060000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '657',
                  },
                  {
                    str: '8817936.430000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '659',
                  },
                  {
                    str: '37909963.140000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '660',
                  },
                  {
                    str: '16279001.600000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '661',
                  },
                  {
                    str: '24503111.790000000000000000',
                  },
                  {},
                ],
              },
              {
                values: [
                  {
                    str: '662',
                  },
                  {
                    str: '2019256.720000000000000000',
                  },
                  {},
                ],
              },
            ],
          },
        },
      },
    };

export const dummyDataBricksAiGenieChatResponse: DataBricksAiGenieChatResponse = isProduction
  ? null
  : {
      text: 'Aquí tienes un listado de todas las tiendas junto con su venta acumulada, calculada a partir de las ventas totales registradas en cada tienda.',
      rawResponse: {
        id: '01efde6414791131b97815ff2b5dbd4a',
        space_id: '01efc9dfddd01b6591241e1ce347725c',
        conversation_id: '01efde6413b511428ccddc9e755462a4',
        user_id: 5906247936159087,
        created_timestamp: 1738170689334,
        last_updated_timestamp: 1738170693638,
        status: 'COMPLETED',
        content: 'quero un listado de dos columnas, una con el nombre de la tienda y la otra con el valor de la venta acumulada',
        attachments: [
          {
            query: {
              query:
                'SELECT `prices_store`.`name` AS `nombre_tienda`, SUM(`sales_sales_per_day`.`sales_total`) AS `venta_acumulada` FROM `prod`.`oxxogas_produ`.`sales_sales_per_day` JOIN `prod`.`oxxogas_produ`.`prices_store` ON `sales_sales_per_day`.`store_id` = `prices_store`.`id` WHERE `prices_store`.`name` IS NOT NULL GROUP BY `prices_store`.`name`',
              description:
                'Aquí tienes un listado de todas las tiendas junto con su venta acumulada, calculada a partir de las ventas totales registradas en cada tienda.',
              ai_function: 'SQL_QUERY',
              id: '01efde6416861bcfa05a543657a92eae',
            },
          },
        ],
        query_result: {
          statement_id: '01efde64-1696-106d-88e7-92339e432c56',
          row_count: 0,
        },
        auto_regenerate_count: 0,
      },
      queryProcessResult: {
        statement_response: {
          statement_id: '01efde64-1696-106d-88e7-92339e432c56',
          status: {
            state: 'SUCCEEDED',
          },
          manifest: {
            format: 'PROTOBUF_ARRAY',
            schema: {
              column_count: 2,
              columns: [
                {
                  name: 'nombre_tienda',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 0,
                },
                {
                  name: 'venta_acumulada',
                  type_text: 'DECIMAL(38,18)',
                  type_name: 'DECIMAL',
                  position: 1,
                  type_precision: 38,
                  type_scale: 18,
                },
              ],
            },
            total_chunk_count: 1,
            chunks: [
              {
                chunk_index: 0,
                row_offset: 0,
                row_count: 565,
                byte_count: 19488,
              },
            ],
            total_row_count: 565,
            total_byte_count: 19488,
            truncated: false,
          },
          result: {
            chunk_index: 0,
            row_offset: 0,
            row_count: 565,
            data_typed_array: [
              {
                values: [
                  {
                    str: 'Hidalgo MTY',
                  },
                  {
                    str: '297569252.830000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Carr. A Mexico AGC',
                  },
                  {
                    str: '294153420.900000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Parque las Américas MTY',
                  },
                  {
                    str: '197142980.740000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Calzada GDN',
                  },
                  {
                    str: '92804482.350000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Venustiano Carranza MTY',
                  },
                  {
                    str: '132708180.760000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Agua Blanca GDN',
                  },
                  {
                    str: '660226442.140000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Valle Alto MTY',
                  },
                  {
                    str: '392491285.970000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'El Salto GDN',
                  },
                  {
                    str: '68304560.210000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'CENTRAL CAMIONERA GDN',
                  },
                  {
                    str: '250601260.100000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Los Pinos MTY',
                  },
                  {
                    str: '436248893.440000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'El Sauz  BJX',
                  },
                  {
                    str: '45880221.320000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Asarco MTY',
                  },
                  {
                    str: '186431940.400000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Aragon MTY',
                  },
                  {
                    str: '99945242.540000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'El Saucito CUU',
                  },
                  {
                    str: '398180312.010000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Cristo Rey GDN',
                  },
                  {
                    str: '79871434.070000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Zacatecas AGU',
                  },
                  {
                    str: '87684209.880000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'NIÑOS HEROES CUU',
                  },
                  {
                    str: '134074641.640000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'SAN ISIDRO CUU',
                  },
                  {
                    str: '48328389.190000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Parador Santa Rosa BJX',
                  },
                  {
                    str: '275262842.940000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Rancheria Juarez CUU',
                  },
                  {
                    str: '236059118.850000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Dali BJX',
                  },
                  {
                    str: '52412597.450000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'TORRES LANDA BJX',
                  },
                  {
                    str: '172986061.090000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Santa Maria MTY',
                  },
                  {
                    str: '103238093.120000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Acatlan GDM',
                  },
                  {
                    str: '194717983.330000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Antonio Cardenas SLW',
                  },
                  {
                    str: '156028019.460000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Las Torres MTY',
                  },
                  {
                    str: '1481962576.700000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Servicio Camionero CUU',
                  },
                  {
                    str: '413609588.520000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Bosque Real Mty',
                  },
                  {
                    str: '122400102.340000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Cuauhtemoc Norte MTY',
                  },
                  {
                    str: '93358715.370000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Candiles BJX',
                  },
                  {
                    str: '276806808.010000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Empresario MTY',
                  },
                  {
                    str: '206234349.710000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Lucio Blanco MTY',
                  },
                  {
                    str: '103769927.680000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Central BJX',
                  },
                  {
                    str: '143923588.470000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'ABASTOS BJX',
                  },
                  {
                    str: '78332215.730000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Baeza CUU',
                  },
                  {
                    str: '102460503.670000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Dos Rios MTY',
                  },
                  {
                    str: '180161673.010000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'ACUEDUCTO SUR MTY',
                  },
                  {
                    str: '148788704.400000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Sopena BJX',
                  },
                  {
                    str: '203126884.590000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'AEROPUERTO III MTY',
                  },
                  {
                    str: '317573327.250000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Linares II MTY',
                  },
                  {
                    str: '433257024.290000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Obelisco BJX',
                  },
                  {
                    str: '93187247.570000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Jimenez MTY',
                  },
                  {
                    str: '68086882.890000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'ORO GDN',
                  },
                  {
                    str: '146624267.690000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Puma BJX',
                  },
                  {
                    str: '214171477.610000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Palacio Federal MTY',
                  },
                  {
                    str: '284598958.000000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: '15 de Mayo MTY',
                  },
                  {
                    str: '214326568.650000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Lucha y Esfuerzo CUU',
                  },
                  {
                    str: '135723865.960000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'San Vicente BJX',
                  },
                  {
                    str: '43330482.840000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Huinala MTY',
                  },
                  {
                    str: '181115428.240000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'PERIFERICO MTY',
                  },
                  {
                    str: '287061973.450000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Amigo CUU',
                  },
                  {
                    str: '311227296.040000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Nogales CUU',
                  },
                  {
                    str: '227645455.690000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Los Lermas MTY',
                  },
                  {
                    str: '248808956.050000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Villaferre SLW',
                  },
                  {
                    str: '191421826.360000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Percheron SLW',
                  },
                  {
                    str: '238051183.780000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Poncitlan GDN',
                  },
                  {
                    str: '263213526.020000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Coss SLW',
                  },
                  {
                    str: '229921077.780000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Presa de la Boca MTY',
                  },
                  {
                    str: '229193075.370000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'El Rosario GDN',
                  },
                  {
                    str: '64055454.720000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Cereso SLW',
                  },
                  {
                    str: '345969981.760000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'PARICUTIN MTY',
                  },
                  {
                    str: '234285171.650000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Las Quintas MTY',
                  },
                  {
                    str: '249484885.560000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Angela Peralta MTY',
                  },
                  {
                    str: '185295972.130000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Terminal II',
                  },
                  {
                    str: '106953882.130000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Plaza Mayor BJX',
                  },
                  {
                    str: '467243144.530000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'El 40 GDN',
                  },
                  {
                    str: '772796176.680000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'San Jose',
                  },
                  {
                    str: '173017631.790000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Mirasierra Gas SLW',
                  },
                  {
                    str: '205282185.630000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Pronto El BarrealMTY',
                  },
                  {
                    str: '183232864.470000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'PASEO LAS MARAVILLAS BJX',
                  },
                  {
                    str: '56212056.320000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'El Rosario SLW',
                  },
                  {
                    str: '112227621.420000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Autopista MTY',
                  },
                  {
                    str: '108787316.250000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Ixtlahuacan',
                  },
                  {
                    str: '174318761.990000000000000000',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'Monarca',
                  },
                  {
                    str: '29454299.450000000000000000',
                  },
                ],
              },
            ],
          },
        },
      },
    };

export const dummyDataBricksAiGenieChatResponseChartPie: DataBricksAiGenieChatResponse = isProduction
  ? null
  : {
      text: 'Este análisis muestra la participación de ventas de cada producto en relación con el total de ventas durante septiembre de 2023. Se calcularon las ventas totales y las ventas individuales de cada producto, y luego se determinó qué porcentaje del total representaba cada producto. Los resultados están ordenados de mayor a menor participación de ventas.',
      rawResponse: {
        id: '01efde70be5f18f6a97e74e987970d1f',
        space_id: '01efc9dfddd01b6591241e1ce347725c',
        conversation_id: '01efde70933a1134a30322e5a9017788',
        user_id: 5906247936159087,
        created_timestamp: 1738176128341,
        last_updated_timestamp: 1738176145396,
        status: 'COMPLETED',
        content: 'dale, del último mes',
        attachments: [
          {
            query: {
              query:
                'WITH TotalSales AS ( \n    SELECT \n        SUM(`sales_total`) AS `total_sales` \n    FROM `prod`.`oxxogas_produ`.`sales_sales_per_day` \n    WHERE `year` = 2023 AND `month` = 9 \n), \nProductSales AS ( \n    SELECT \n        `item_id`, \n        SUM(`sales_total`) AS `product_sales` \n    FROM `prod`.`oxxogas_produ`.`sales_sales_per_day` \n    WHERE `year` = 2023 AND `month` = 9 \n    GROUP BY `item_id` \n), \nProductShare AS ( \n    SELECT \n        `i`.`name` AS `product_name`, \n        (`ps`.`product_sales` / `ts`.`total_sales`) * 100 AS `sales_share` \n    FROM `ProductSales` `ps` \n    JOIN `prod`.`oxxogas_produ`.`prices_item` `i` ON `ps`.`item_id` = `i`.`id` \n    CROSS JOIN `TotalSales` `ts` \n) \nSELECT \n    `product_name`, \n    `sales_share` \nFROM `ProductShare` \nORDER BY `sales_share` DESC;',
              description:
                'Este análisis muestra la participación de ventas de cada producto en relación con el total de ventas durante septiembre de 2023. Se calcularon las ventas totales y las ventas individuales de cada producto, y luego se determinó qué porcentaje del total representaba cada producto. Los resultados están ordenados de mayor a menor participación de ventas.',
              ai_function: 'SQL_QUERY',
              id: '01efde70c1c510589eb1e750873969df',
            },
          },
        ],
        query_result: {
          statement_id: '01efde70-c1e4-17cb-a755-e8dce6b3a13f',
          row_count: 0,
        },
        auto_regenerate_count: 0,
      },
      queryProcessResult: {
        statement_response: {
          statement_id: '01efde70-c1e4-17cb-a755-e8dce6b3a13f',
          status: {
            state: 'SUCCEEDED',
          },
          manifest: {
            format: 'PROTOBUF_ARRAY',
            schema: {
              column_count: 2,
              columns: [
                {
                  name: 'product_name',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 0,
                },
                {
                  name: 'sales_share',
                  type_text: 'DECIMAL(38,6)',
                  type_name: 'DECIMAL',
                  position: 1,
                  type_precision: 38,
                  type_scale: 6,
                },
              ],
            },
            total_chunk_count: 1,
            chunks: [
              {
                chunk_index: 0,
                row_offset: 0,
                row_count: 3,
                byte_count: 544,
              },
            ],
            total_row_count: 3,
            total_byte_count: 544,
            truncated: false,
          },
          result: {
            chunk_index: 0,
            row_offset: 0,
            row_count: 3,
            data_typed_array: [
              {
                values: [
                  {
                    str: 'MAGNA',
                  },
                  {
                    str: '61.214800',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'DIESEL',
                  },
                  {
                    str: '20.635700',
                  },
                ],
              },
              {
                values: [
                  {
                    str: 'PREMIUM',
                  },
                  {
                    str: '18.149500',
                  },
                ],
              },
            ],
          },
        },
      },
    };

export const dummyDataBricksAiGenieChatResponseChartItems: DataBricksAiGenieChatResponse = isProduction
  ? null
  : {
      text: 'Aquí tienes un listado de todos los productos disponibles que no han sido eliminados.',
      rawResponse: {
        id: '01efdf171f6a164a989f24667eb96a1a',
        space_id: '01efc9dfddd01b6591241e1ce347725c',
        conversation_id: '01efdf171eda198ba43a4fe6fb008726',
        user_id: 5906247936159087,
        created_timestamp: 1738247587608,
        last_updated_timestamp: 1738247592237,
        status: 'COMPLETED',
        content: 'quiero un listado de items con todos sus campos',
        attachments: [
          {
            query: {
              query: 'SELECT * FROM `prod`.`oxxogas_produ`.`prices_item` WHERE NOT `is_deleted`',
              description: 'Aquí tienes un listado de todos los productos disponibles que no han sido eliminados.',
              ai_function: 'SQL_QUERY',
              id: '01efdf1720ab1c26a09d8c331a371e39',
              statement_id: '01efdf17-20c3-15fd-8eb6-76d52377a64d',
            },
          },
        ],
        query_result: {
          statement_id: '01efdf17-20c3-15fd-8eb6-76d52377a64d',
          row_count: 0,
        },
        auto_regenerate_count: 0,
      },
      queryProcessResult: {
        statement_response: {
          statement_id: '01efdf17-20c3-15fd-8eb6-76d52377a64d',
          status: {
            state: 'SUCCEEDED',
          },
          manifest: {
            format: 'PROTOBUF_ARRAY',
            schema: {
              column_count: 33,
              columns: [
                {
                  name: 'id',
                  type_text: 'INT',
                  type_name: 'INT',
                  position: 0,
                },
                {
                  name: 'is_deleted',
                  type_text: 'BOOLEAN',
                  type_name: 'BOOLEAN',
                  position: 1,
                },
                {
                  name: 'created_by',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 2,
                },
                {
                  name: 'created_on',
                  type_text: 'TIMESTAMP',
                  type_name: 'TIMESTAMP',
                  position: 3,
                },
                {
                  name: 'updated_by',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 4,
                },
                {
                  name: 'updated_on',
                  type_text: 'TIMESTAMP',
                  type_name: 'TIMESTAMP',
                  position: 5,
                },
                {
                  name: 'code',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 6,
                },
                {
                  name: 'name',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 7,
                },
                {
                  name: 'short_name',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 8,
                },
                {
                  name: 'ean',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 9,
                },
                {
                  name: 'category_code',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 10,
                },
                {
                  name: 'category_id',
                  type_text: 'INT',
                  type_name: 'INT',
                  position: 11,
                },
                {
                  name: 'base_unit_of_measure_code',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 12,
                },
                {
                  name: 'sales_tax_rate',
                  type_text: 'DECIMAL(38,18)',
                  type_name: 'DECIMAL',
                  position: 13,
                  type_precision: 38,
                  type_scale: 18,
                },
                {
                  name: 'tax',
                  type_text: 'DECIMAL(38,18)',
                  type_name: 'DECIMAL',
                  position: 14,
                  type_precision: 38,
                  type_scale: 18,
                },
                {
                  name: 'purchase_tax_rate',
                  type_text: 'DECIMAL(38,18)',
                  type_name: 'DECIMAL',
                  position: 15,
                  type_precision: 38,
                  type_scale: 18,
                },
                {
                  name: 'item_created_on',
                  type_text: 'TIMESTAMP',
                  type_name: 'TIMESTAMP',
                  position: 16,
                },
                {
                  name: 'discriminator',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 17,
                },
                {
                  name: 'discount',
                  type_text: 'DECIMAL(38,18)',
                  type_name: 'DECIMAL',
                  position: 18,
                  type_precision: 38,
                  type_scale: 18,
                },
                {
                  name: 'minimum_quantity',
                  type_text: 'DECIMAL(38,18)',
                  type_name: 'DECIMAL',
                  position: 19,
                  type_precision: 38,
                  type_scale: 18,
                },
                {
                  name: 'image_url',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 20,
                },
                {
                  name: 'cloned_from_item_id',
                  type_text: 'INT',
                  type_name: 'INT',
                  position: 21,
                },
                {
                  name: 'custom_item_of_store_id',
                  type_text: 'INT',
                  type_name: 'INT',
                  position: 22,
                },
                {
                  name: 'status',
                  type_text: 'INT',
                  type_name: 'INT',
                  position: 23,
                },
                {
                  name: 'clone_prices_from_item_id',
                  type_text: 'INT',
                  type_name: 'INT',
                  position: 24,
                },
                {
                  name: 'price_initialization_method',
                  type_text: 'INT',
                  type_name: 'INT',
                  position: 25,
                },
                {
                  name: 'assortment_published_date',
                  type_text: 'TIMESTAMP',
                  type_name: 'TIMESTAMP',
                  position: 26,
                },
                {
                  name: 'prices_published_date',
                  type_text: 'TIMESTAMP',
                  type_name: 'TIMESTAMP',
                  position: 27,
                },
                {
                  name: 'promotions_published_date',
                  type_text: 'TIMESTAMP',
                  type_name: 'TIMESTAMP',
                  position: 28,
                },
                {
                  name: 'spaces_published_date',
                  type_text: 'TIMESTAMP',
                  type_name: 'TIMESTAMP',
                  position: 29,
                },
                {
                  name: 'supply_published_date',
                  type_text: 'TIMESTAMP',
                  type_name: 'TIMESTAMP',
                  position: 30,
                },
                {
                  name: 'mystery_shopper_published_date',
                  type_text: 'TIMESTAMP',
                  type_name: 'TIMESTAMP',
                  position: 31,
                },
                {
                  name: 'publish_status',
                  type_text: 'INT',
                  type_name: 'INT',
                  position: 32,
                },
              ],
            },
            total_chunk_count: 1,
            chunks: [
              {
                chunk_index: 0,
                row_offset: 0,
                row_count: 3,
                byte_count: 5432,
              },
            ],
            total_row_count: 3,
            total_byte_count: 5432,
            truncated: false,
          },
          result: {
            chunk_index: 0,
            row_offset: 0,
            row_count: 3,
            data_typed_array: [
              {
                values: [
                  {
                    str: '1',
                  },
                  {
                    str: 'false',
                  },
                  {
                    str: 'PRISMA',
                  },
                  {
                    str: '2020-10-21T19:48:35.837Z',
                  },
                  {
                    str: 'Gonzalo Torres',
                  },
                  {
                    str: '2022-07-06T12:06:32.000Z',
                  },
                  {
                    str: '21103',
                  },
                  {
                    str: 'MAGNA',
                  },
                  {},
                  {},
                  {
                    str: 'COMBL01',
                  },
                  {
                    str: '8',
                  },
                  {
                    str: 'LT',
                  },
                  {
                    str: '0.180000000000000000',
                  },
                  {
                    str: '0.484700000000000000',
                  },
                  {
                    str: '0.160000000000000000',
                  },
                  {
                    str: '2017-11-30T12:56:27.860Z',
                  },
                  {
                    str: 'Item',
                  },
                  {
                    str: '0E-18',
                  },
                  {},
                  {},
                  {},
                  {},
                  {
                    str: '1',
                  },
                  {},
                  {
                    str: '2',
                  },
                  {},
                  {
                    str: '2022-07-06T12:06:32.000Z',
                  },
                  {},
                  {},
                  {},
                  {},
                  {
                    str: '0',
                  },
                ],
              },
              {
                values: [
                  {
                    str: '2',
                  },
                  {
                    str: 'false',
                  },
                  {
                    str: 'PRISMA',
                  },
                  {
                    str: '2020-10-21T19:48:35.837Z',
                  },
                  {
                    str: 'Gonzalo Torres',
                  },
                  {
                    str: '2022-07-06T12:11:53.000Z',
                  },
                  {
                    str: '21104',
                  },
                  {
                    str: 'PREMIUM',
                  },
                  {},
                  {},
                  {},
                  {
                    str: '9',
                  },
                  {
                    str: 'LT',
                  },
                  {
                    str: '0.160000000000000000',
                  },
                  {
                    str: '0.591400000000000000',
                  },
                  {
                    str: '0.170000000000000000',
                  },
                  {
                    str: '2017-11-30T12:56:27.860Z',
                  },
                  {
                    str: 'Item',
                  },
                  {
                    str: '0E-18',
                  },
                  {},
                  {},
                  {},
                  {},
                  {
                    str: '0',
                  },
                  {},
                  {
                    str: '2',
                  },
                  {},
                  {
                    str: '2022-07-06T12:11:53.000Z',
                  },
                  {},
                  {},
                  {},
                  {},
                  {
                    str: '0',
                  },
                ],
              },
              {
                values: [
                  {
                    str: '3',
                  },
                  {
                    str: 'false',
                  },
                  {
                    str: 'PRISMA',
                  },
                  {
                    str: '2020-10-21T19:48:35.837Z',
                  },
                  {
                    str: 'Alebandro Mayer',
                  },
                  {
                    str: '2022-08-29T19:33:31.000Z',
                  },
                  {
                    str: '21105',
                  },
                  {
                    str: 'DIESEL',
                  },
                  {},
                  {},
                  {},
                  {
                    str: '10',
                  },
                  {
                    str: 'LT',
                  },
                  {
                    str: '0.180000000000000000',
                  },
                  {
                    str: '0.402200000000000000',
                  },
                  {
                    str: '0.160000000000000000',
                  },
                  {
                    str: '2017-11-30T12:56:27.860Z',
                  },
                  {
                    str: 'Item',
                  },
                  {
                    str: '0E-18',
                  },
                  {},
                  {},
                  {},
                  {},
                  {
                    str: '0',
                  },
                  {},
                  {
                    str: '2',
                  },
                  {},
                  {
                    str: '2022-08-29T19:33:31.000Z',
                  },
                  {},
                  {},
                  {},
                  {},
                  {
                    str: '0',
                  },
                ],
              },
            ],
          },
        },
      },
    };

export const dummyDataBricksAiGenieChatResponseChartDate: DataBricksAiGenieChatResponse = isProduction
  ? null
  : {
      text: 'Aquí tienes el total de ventas diarias para el producto "magna", mostrando la fecha y el valor de las ventas.',
      rawResponse: {
        id: '01efde876b971ab6ae3e7c31634d3320',
        space_id: '01efc9dfddd01b6591241e1ce347725c',
        conversation_id: '01efde876abb1b83953a28f792a8eabf',
        user_id: 5906247936159087,
        created_timestamp: 1738185867882,
        last_updated_timestamp: 1738185883719,
        status: 'COMPLETED',
        content: 'quiero un histórico de ventas del producto magna, que solo tenga dos columnas, fecha y valor',
        attachments: [
          {
            query: {
              query:
                "SELECT CONCAT(`year`, '-', `month`, '-', `day`) AS `fecha`, `sales_total` AS `valor`\nFROM `prod`.`oxxogas_produ`.`sales_sales_per_day`\nWHERE `item_id` = (SELECT `id` FROM `prod`.`oxxogas_produ`.`prices_item` WHERE `name` ILIKE '%magna%')\nAND NOT `is_deleted`",
              description: 'Aquí tienes el total de ventas diarias para el producto "magna", mostrando la fecha y el valor de las ventas.',
              ai_function: 'SQL_QUERY',
              id: '01efde876dc711c09023df4e25b4852f',
            },
          },
        ],
        query_result: {
          statement_id: '01efde87-6de3-14db-bbb8-e6b7b620a53a',
          row_count: 0,
        },
        auto_regenerate_count: 0,
      },
      queryProcessResult: {
        statement_response: {
          statement_id: '01efde87-6de3-14db-bbb8-e6b7b620a53a',
          status: {
            state: 'SUCCEEDED',
          },
          manifest: {
            format: 'PROTOBUF_ARRAY',
            schema: {
              column_count: 2,
              columns: [
                {
                  name: 'fecha',
                  type_text: 'STRING',
                  type_name: 'STRING',
                  position: 0,
                },
                {
                  name: 'valor',
                  type_text: 'DECIMAL(38,18)',
                  type_name: 'DECIMAL',
                  position: 1,
                  type_precision: 38,
                  type_scale: 18,
                },
              ],
            },
            total_chunk_count: 1,
            chunks: [
              {
                chunk_index: 0,
                row_offset: 0,
                row_count: 5000,
                byte_count: 144888,
              },
            ],
            total_row_count: 5000,
            total_byte_count: 144888,
            truncated: true,
          },
          result: {
            chunk_index: 0,
            row_offset: 0,
            row_count: 5000,
            data_typed_array: false
              ? []
              : [
                  {
                    values: [
                      {
                        str: '2022-11-28',
                      },
                      {
                        str: '174599.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-1-17',
                      },
                      {
                        str: '110865.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-7-6',
                      },
                      {
                        str: '0.920000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-8-18',
                      },
                      {
                        str: '178970.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-6-22',
                      },
                      {
                        str: '159502.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-9-24',
                      },
                      {
                        str: '241497.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-11-8',
                      },
                      {
                        str: '113578.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-11-20',
                      },
                      {
                        str: '118818.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-2-2',
                      },
                      {
                        str: '166362.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-4-28',
                      },
                      {
                        str: '173552.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-4-13',
                      },
                      {
                        str: '171414.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-2-13',
                      },
                      {
                        str: '61930.800000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-2-13',
                      },
                      {
                        str: '229950.000000000000000000',
                      },
                    ],
                  },
                  {
                    values: [
                      {
                        str: '2022-2-13',
                      },
                      {
                        str: '94390.600000000000000000',
                      },
                    ],
                  },
                ],
          },
        },
      },
    };
