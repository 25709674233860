import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { _error, _log, _warn } from '@shared/aux_helper_environment';
import { _cloneDeep } from '@shared/aux_helper_functions';
import { PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { GenieColumnType, GenieStatementResponse } from '../services/databricks-genie-ai-chat.models';
import { DataBricksAiGenieChatResponse } from '../services/databricks-genie-ai-chat.service';
import {
  _formatColumnTitle,
  DataBricksAiGenie_AnalyzeSimpleChartData,
  getNumericColumns,
  isDateString,
} from './_aux_dataBricksAiGenieQueryProcessTable';
import { ColumnSelectorDialogComponent } from './column-selector-dialog.component';
import { DataBricksAiGenieQueryProcessChartComponent } from './databricks-genie-ai-query-process-chart.component';

//Test: Quero un listado de dos columnas, una con el nombre de la tienda y la otra con el valor de la venta acumulada en $

export type DataBricksAiGenie_ChartAnalysisResult_ChartType = 'pie' | 'bar' | 'horizontalBar' | 'time_line';

export interface DataBricksAiGenie_ChartAnalysisResult {
  isVisualizationAvailable: boolean;
  visualization?: {
    series: Array<{ label: string; value: number }>;
    numericColumnName: string;
    labelColumnName: string;
    chartType: DataBricksAiGenie_ChartAnalysisResult_ChartType;
  };
  error?: {
    code: string;
    message: string;
    details?: any;
  };
}

@Component({
  selector: 'query-process-result',
  template: `
    <div *ngIf="isDialogMode" class="dialog-header">
      <h1 mat-dialog-title fxFlex>Tabla de Resultados</h1>
      <button mat-icon-button (click)="closeDialog()" style="top: -16px; position: relative;">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <!-- --  -->

    <div *ngIf="initiedTable" class="table-container" [ngClass]="{ 'is-dialog-mode': isDialogMode }">
      <cdk-virtual-scroll-viewport
        [tvsItemSize]="28"
        [headerHeight]="32"
        bufferMultiplier="2"
        class="viewport"
        [ngStyle]="{ height: getViewportHeight() }"
      >
        <div *ngIf="shouldShowSearchInput()" class="search-container">
          <mat-form-field appearance="outline" class="search-field">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput [(ngModel)]="searchValue" (keyup)="applyFilter($event)" placeholder="Buscar..." />
            <button mat-icon-button matSuffix *ngIf="searchValue" (click)="clearSearch()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <table mat-table [dataSource]="dataSource" class="results-table">
          <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef (click)="sortData(column)" class="sortable-header">
              {{ getColumnHeader(column) }}
              <mat-icon *ngIf="sortColumn === column" class="sort-icon">
                {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
              </mat-icon>
            </th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="!dataSource?.filteredData?.length" class="no-data-message">No hay datos disponibles.</div>
      </cdk-virtual-scroll-viewport>

      <div *ngIf="shouldShowFooter()" class="table-footer">
        <span class="record-count">{{ dataSource?.filteredData?.length || 0 }} registros</span>
        <div class="table-footer-buttons">
          <button *ngIf="hasQuery()" mat-icon-button [matMenuTriggerFor]="queryPopover" matTooltip="Ver Query" #queryBtn="matTooltip">
            <mat-icon>code</mat-icon>
          </button>
          <button
            [disabled]="!shouldShowFooterVisualization()"
            mat-icon-button
            (click)="openChartView()"
            [matTooltip]="getChartButtonTooltip()"
          >
            <mat-icon>{{ getChartIcon(chartMetadata?.visualization?.chartType || 'bar') }}</mat-icon>
          </button>
          <button mat-icon-button (click)="exportToCSV()" matTooltip="Exportar a CSV">
            <mat-icon>file_download</mat-icon>
          </button>
          <button mat-icon-button (click)="openDialog()" matTooltip="Ampliar tabla" *ngIf="!isDialogMode">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </div>
      </div>

      <mat-menu #queryPopover="matMenu" class="query-popover">
        <div class="query-container">
          <pre class="query-content">{{ getQuery() }}</pre>
          <button mat-icon-button class="copy-button" (click)="copyQuery()" matTooltip="Copiar Query al Portapapeles">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </mat-menu>
    </div>
  `,
  styles: [
    `
      .search-container {
        padding: 8px;
        background: #fafafa;
      }

      .search-field {
        width: 100%;
        font-size: 14px;
      }

      :host ::ng-deep .search-field .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      :host ::ng-deep .search-field .mat-form-field-infix {
        padding: 6px 0;
        top: -3px;
        left: 4px;
      }

      :host ::ng-deep .search-field .mat-form-field-outline {
        background: white;
      }

      .table-container {
        position: relative;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;
      }

      .dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        background: #fafafa;
      }

      .viewport {
        width: 100%;
        background: #fafafa;
      }

      .table-container.is-dialog-mode .viewport {
        height: calc(90vh - 132px);
      }

      .results-table {
        min-width: 100%;
      }

      th.mat-header-cell,
      td.mat-cell {
        min-width: 65px;
        max-width: 340px;
        padding: 4px 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.7);
      }

      th.mat-header-cell {
        background: #fafafa;
      }

      .table-container.is-dialog-mode th.mat-header-cell,
      .table-container.is-dialog-mode td.mat-cell {
        max-width: 640px;
      }

      :host ::ng-deep tr.mat-row {
        height: 32px !important;
      }

      :host ::ng-deep tr.mat-header-row {
        height: 36px !important;
        top: 0;
        z-index: 1;
        background: #fafafa;
      }

      :host ::ng-deep .cdk-virtual-scroll-content-wrapper {
        width: auto !important;
        min-width: 100%;
      }

      // Estilizar scrollbars
      .viewport::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      .viewport::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      .viewport::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.12);
        border-radius: 16px;
      }

      .table-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 8px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        background: #d0e2ee;
      }

      .table-container.is-dialog-mode .table-footer {
        background: #fafafa;
      }

      .record-count {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.54);
      }

      .mat-icon-button {
        width: 32px;
        height: 32px;
        line-height: 32px;
      }

      .no-data-message {
        text-align: center;
        padding: 16px;
        color: rgba(0, 0, 0, 0.54);
        background: white;
      }

      .table-footer-buttons {
        display: flex;
        gap: 4px;
      }

      .sortable-header {
        cursor: pointer;
        user-select: none;
      }

      .sortable-header:hover {
        background: rgb(235, 235, 235) !important;
      }

      .sort-icon {
        font-size: 16px;
        position: absolute;
        right: 8px;
        top: 60%;
        transform: translateY(-50%);
        opacity: 0.7;
        width: 16px;
        height: 16px;
      }

      .query-popover {
        max-width: none !important;
      }

      .query-container {
        position: relative;
        padding: 8px;
      }

      .query-content {
        max-width: 500px;
        padding: 8px;
        margin: 0;
        white-space: pre-wrap;
        font-family: monospace;
        background: #f5f5f5;
        border-radius: 4px;
        font-size: 12px;
        line-height: 1.3;
        overflow-x: hidden;
        padding-right: 36px; /* Espacio para el botón */
      }

      .copy-button {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        opacity: 0.7;
      }

      .copy-button:hover {
        opacity: 1;
      }

      .copy-button .mat-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataBricksAiGenieQueryProcessTableComponent implements OnInit {
  @ViewChild('queryPopover') queryPopover: MatMenu;

  @Input() queryProcessResult: GenieStatementResponse;
  @Input() readonly message: DataBricksAiGenieChatResponse;

  isDialogMode = false;
  displayedColumns: string[] = [];
  dataSource: TableVirtualScrollDataSource<any>;
  initiedTable = false;
  originalData: any[] = [];
  searchValue = '';

  chartMetadata: DataBricksAiGenie_ChartAnalysisResult = {
    isVisualizationAvailable: false,
  };

  queryProcessResultNumeric = null;

  sortColumn: string = null;
  sortDirection: 'asc' | 'desc' = 'asc';

  private config = this.prismaDynamicEnv.getConf('brandCustomization.dataBricksGenieConfig.TABLE') || {};

  constructor(
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    @Optional() private dialogRef: MatDialogRef<DataBricksAiGenieQueryProcessTableComponent>,
    private prismaDynamicEnv: PrismaDynamicEnv
  ) {
    this.isDialogMode = data?.isDialogMode === true;
    if (this.isDialogMode) this.queryProcessResult = data.queryProcessResult;
  }

  ngOnInit() {
    this.initializeTable();
  }

  private initializeTable() {
    if (!this.queryProcessResult?.statement_response) return;

    this.resetAllCaches();

    const { manifest, result } = this.queryProcessResult.statement_response;
    this.queryProcessResultNumeric = getNumericColumns(this.queryProcessResult);

    // Primero transformamos los datos
    const transformedData = result.data_typed_array.map(row => {
      const transformedRow = {};
      row.values.forEach((value, index) => {
        const columnName = manifest.schema.columns[index].name;
        const columnType = manifest.schema.columns[index];
        const rawValue = value.str || value.num || value.bool;
        if (rawValue !== undefined && rawValue !== null && rawValue !== '') {
          transformedRow[columnName] = this.formatCellValue(rawValue, columnType);
        }
      });
      return transformedRow;
    });

    // Identificamos qué columnas tienen al menos un valor
    const columnsWithValues = new Set<string>();
    transformedData.forEach(row => {
      Object.keys(row).forEach(key => {
        columnsWithValues.add(key);
      });
    });

    // Filtramos las columnas que tienen valores
    this.displayedColumns = manifest.schema.columns.map(col => col.name).filter(colName => columnsWithValues.has(colName));

    // Store original data
    this.originalData = transformedData;

    // Initialize TableVirtualScrollDataSource
    this.dataSource = new TableVirtualScrollDataSource(transformedData);

    this.initiedTable = true;

    this.analyzeDataVisualizationDealyed();
  }

  analyzeDataVisualizationDealyed(time = 16) {
    setTimeout(() => {
      this.analyzeDataVisualization(this.queryProcessResult);
    }, time);
  }

  get localStringFormat(): string {
    return navigator.language || navigator.languages[0] || 'default';
  }

  private formatColumnTitle(columnName: string): string {
    return _formatColumnTitle(columnName);
  }

  getColumnHeader(column: string): string {
    const schema = this.queryProcessResult?.statement_response?.manifest?.schema;
    if (!schema) return null;

    const columnDef = schema.columns.find(col => col.name === column);

    return this.formatColumnTitle(columnDef.name);
  }

  private formatDate(value: string): string {
    try {
      const date = new Date(value);
      if (isNaN(date.getTime())) return value;

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    } catch {
      return value;
    }
  }

  private formatCellValue(value: any, columnType: GenieColumnType): string | number {
    // Si el valor es null o undefined, retornamos el valor sin procesar
    if (value === null || value === undefined) return value;

    // Manejar tipos según el schema
    switch (columnType.type_name.toLowerCase()) {
      case 'timestamp':
      case 'string':
      case 'varchar':
      case 'char':
      case 'text':
        if (isDateString(value)) return this.formatDate(value);
        return value;

      case 'boolean':
        if (this.config.TRANSFORM_BOOLEANS) {
          return value === true || value === 'true' ? 'Si' : 'No';
        }
        return value;

      case 'int':

      case 'integer':
      case 'bigint':
      case 'smallint':
        return Number(value).toLocaleString(this.localStringFormat);

      case 'decimal':
      case 'numeric':
      case 'float':
      case 'double':
        const num = Number(value);
        const hasDecimals = num % 1 !== 0;

        // Solo redondear si es mayor a 10000 Y tiene decimales
        if (Math.abs(num) > 10000 && hasDecimals) {
          return Math.round(num).toLocaleString(this.localStringFormat);
        }

        return num.toLocaleString(this.localStringFormat);

      default:
        return value;
    }
  }

  exportToCSV(): void {
    if (!this.dataSource?.filteredData?.length) return;

    // Crear línea de encabezados
    const headers = this.displayedColumns.map(col => this.getColumnHeader(col));

    // Crear líneas de datos
    const rows = this.dataSource.filteredData.map(row =>
      this.displayedColumns.map(col => {
        const value = row[col];
        // Escapar comas y comillas si es necesario
        return typeof value === 'string' && (value.includes(',') || value.includes('"')) ? `"${value.replace(/"/g, '""')}"` : value;
      })
    );

    // Combinar todo en un string CSV
    const csv = [headers.join(','), ...rows.map(row => row.join(','))].join('\n');

    // Crear y descargar el archivo
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', `datos_${new Date().toISOString().split('T')[0]}.csv`);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  openDialog(): void {
    const config = new MatDialogConfig();

    config.width = '90%';
    config.height = '90%';
    config.data = { queryProcessResult: this.queryProcessResult, isDialogMode: true };
    config.autoFocus = false;

    this.dialog.open(DataBricksAiGenieQueryProcessTableComponent, config);
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private filterQueryResultByDisplayedColumns(queryResult: GenieStatementResponse): GenieStatementResponse {
    if (!queryResult?.statement_response) return queryResult;

    let queryResultLength = queryResult?.statement_response?.manifest?.schema?.columns?.length;
    let displayedColumnsLength = this.displayedColumns?.length;

    //Si no hay columnas filtradas usa el original por precaución
    if (queryResultLength === displayedColumnsLength) return queryResult;

    const original = _cloneDeep(queryResult);
    const { manifest, result } = original.statement_response;

    // Get indices of displayed columns
    const displayedIndices = this.displayedColumns
      .map(colName => manifest.schema.columns.findIndex(col => col.name === colName))
      .filter(idx => idx !== -1);

    // Filter schema columns
    const filteredColumns = displayedIndices.map(idx => manifest.schema.columns[idx]);

    // Filter data rows
    const filteredData = result.data_typed_array.map(row => ({
      values: displayedIndices.map(idx => row.values[idx]),
    }));

    return {
      ...queryResult,
      statement_response: {
        ...original.statement_response,
        manifest: {
          ...manifest,
          schema: {
            ...manifest.schema,
            columns: filteredColumns,
            column_count: filteredColumns.length,
          },
        },
        result: {
          ...result,
          data_typed_array: filteredData,
        },
      },
    };
  }

  analyzeDataVisualization($queryProcessResult) {
    if (!this.shouldShowFooterVisualization()) return;

    // Filter queryProcessResult by displayed columns before analysis
    const filteredQueryResult = this.filterQueryResultByDisplayedColumns($queryProcessResult);
    const chartAnalysis = DataBricksAiGenie_AnalyzeSimpleChartData(filteredQueryResult);

    if (chartAnalysis?.error) {
      _warn('[ChartAnalysis Error]', chartAnalysis.error, $queryProcessResult);
    }

    if (chartAnalysis?.isVisualizationAvailable) {
      this.chartMetadata = chartAnalysis;
      this.cdRef.detectChanges();
      return;
    }

    this.chartMetadata = {
      isVisualizationAvailable: false,
      error: chartAnalysis?.error,
    };
  }

  openChartView(): void {
    if (!this.chartMetadata.isVisualizationAvailable && this.displayedColumns.length > 2) {
      this.openColumnSelector();
      return;
    }

    // Apertura del gráfico
    const config = new MatDialogConfig();
    config.width = '90%';
    config.height = '90%';
    config.data = {
      chartData: _cloneDeep(this.chartMetadata.visualization),
      isDialogMode: true,
    };
    config.autoFocus = false;

    if (this.chartMetadata.visualization == null) {
      _error('No se pudo abrir el gráfico, no hay datos de visualización:', {
        chartMetadata: this.chartMetadata,
        displayedColumns: this.displayedColumns,
      });
      return;
    }

    const dialogRef = this.dialog.open(DataBricksAiGenieQueryProcessChartComponent, config);

    _log(['openChartView'], this.chartMetadata.visualization);

    // Si el chartMetadata fue generado por selección manual de columnas, resetearlo al cerrar
    if (this.displayedColumns.length > 2) {
      dialogRef.afterClosed().subscribe(() => {
        this.chartMetadata = {
          isVisualizationAvailable: false,
        };
        this.cdRef.detectChanges();
      });
    }
  }

  private openColumnSelector(): void {
    const numericColumns = this.queryProcessResultNumeric;

    const dialogRef = this.dialog.open(ColumnSelectorDialogComponent, {
      width: '400px',
      data: {
        columns: this.displayedColumns,
        numericColumns,
        filteredData: this.dataSource?.filteredData,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const filteredResult = this.createFilteredResult(result.numericColumn, result.labelColumn);
        const chartAnalysis = DataBricksAiGenie_AnalyzeSimpleChartData(filteredResult);

        if (chartAnalysis?.isVisualizationAvailable) {
          this.chartMetadata = chartAnalysis;
          this.openChartView();
        } else {
          console.error('[openChartView] fail:', { result, filteredResult, chartAnalysis });
        }
      }
    });
  }

  private createFilteredResult(numericColumn: string, labelColumn: string): GenieStatementResponse {
    const original = _cloneDeep(this.queryProcessResult.statement_response);

    // Encontrar los índices originales
    const numIdx = original.manifest.schema.columns.findIndex(col => col.name === numericColumn);
    const labelIdx = original.manifest.schema.columns.findIndex(col => col.name === labelColumn);

    // Obtener solo las dos columnas necesarias
    const selectedColumns = [original.manifest.schema.columns[numIdx], original.manifest.schema.columns[labelIdx]];

    // Crear nuevo schema con solo las columnas seleccionadas
    const newSchema = {
      ...original.manifest.schema,
      column_count: 2, // Importante: actualizar el contador de columnas
      columns: selectedColumns,
    };

    // Crear nueva data solo con los valores seleccionados
    const newData = original.result.data_typed_array.map(row => ({
      values: [
        row.values[numIdx], // Valor numérico primero
        row.values[labelIdx], // Etiqueta después
      ],
    }));

    return {
      ...this.queryProcessResult,
      statement_response: {
        ...original,
        manifest: {
          ...original.manifest,
          schema: newSchema,
        },
        result: {
          ...original.result,
          data_typed_array: newData,
        },
      },
    };
  }

  private getChartButtonTooltip(): string {
    if (!this.shouldShowFooterVisualization()) {
      return 'Con los datos disponibles actualmente, no es posible generar el gráfico';
    }

    if (!this.chartMetadata.isVisualizationAvailable) {
      return this.displayedColumns.length > 2
        ? 'Ver Gráfico, seleccionar columnas para visualizar'
        : 'Con los datos disponibles actualmente, no es posible generar el gráfico';
    }

    return 'Ver gráfico ' + this.getChartTypeLabel(this.chartMetadata.visualization.chartType);
  }

  private getChartTypeLabel(type: DataBricksAiGenie_ChartAnalysisResult_ChartType): string {
    switch (type) {
      case 'pie':
        return 'de torta';
      case 'time_line':
        return 'de serie lineal';
      default:
        return 'de barras';
    }
  }

  private getChartIcon(type: DataBricksAiGenie_ChartAnalysisResult_ChartType): string {
    switch (type) {
      case 'pie':
        return 'pie_chart';
      case 'time_line':
        return 'timeline';
      default:
        return 'bar_chart';
    }
  }

  private getViewportHeight(): string {
    if (!this.dataSource?.filteredData) return '300px';

    if (this.originalData.length < 2) return '86px';

    return this.isDialogMode ? 'calc(90vh - 132px)' : '300px';
  }

  private shouldShowFooter(): boolean {
    return this.originalData?.length >= 3;
  }

  _cacheShouldShowFooterVisualization = null;
  private shouldShowFooterVisualization(): boolean {
    if (this._cacheShouldShowFooterVisualization !== null) return this._cacheShouldShowFooterVisualization;

    if (this.displayedColumns?.length < 2 || this.dataSource?.filteredData?.length < 2) return false;

    const numericColumns = this.queryProcessResultNumeric;

    if (!(numericColumns?.length > 0)) return false;

    let rv = Boolean(numericColumns.some(col => this.displayedColumns.includes(col)));

    this._cacheShouldShowFooterVisualization = rv;

    return rv;
  }

  resetCacheShouldShowFooterVisualization() {
    this._cacheShouldShowFooterVisualization = null;
  }

  resetAllCaches() {
    this.resetCacheShouldShowFooterVisualization();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    if (!this.originalData) return;

    const filteredData = this.originalData.filter(row => {
      return Object.values(row).some(value => value?.toString().toLowerCase().includes(filterValue));
    });

    // Apply current sort if exists
    if (this.sortColumn) {
      this.dataSource.data = filteredData;
      this.sortData(this.sortColumn);
    } else {
      this.dataSource.data = filteredData;
    }
  }

  clearSearch() {
    this.searchValue = '';
    if (this.sortColumn) {
      this.dataSource.data = this.originalData;
      this.sortData(this.sortColumn);
    } else {
      this.dataSource.data = this.originalData;
    }
  }

  shouldShowSearchInput(): boolean {
    return this.originalData?.length > 6;
  }

  sortData(column: string): void {
    // If clicking the same column, toggle direction
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }

    // Sort the data
    const sortedData = [...this.dataSource.data].sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      // Handle null/undefined values
      if (valueA === null || valueA === undefined) return 1;
      if (valueB === null || valueB === undefined) return -1;

      // Handle dates - Check both original date format and our formatted dd/mm/yyyy
      if (this.isFormattedDate(valueA) || isDateString(valueA)) {
        if (this.isFormattedDate(valueB) || isDateString(valueB)) {
          const dateA = this.parsePossibleDate(valueA);
          const dateB = this.parsePossibleDate(valueB);

          if (dateA && dateB) {
            return this.sortDirection === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
          }
        }
      }

      // Handle numbers (including string representations with locale formats)
      const numA = typeof valueA === 'string' ? this.parseLocaleNumber(valueA) : valueA;
      const numB = typeof valueB === 'string' ? this.parseLocaleNumber(valueB) : valueB;

      if (!isNaN(numA) && !isNaN(numB)) {
        return this.sortDirection === 'asc' ? numA - numB : numB - numA;
      }

      // Handle strings and others
      const compareResult = String(valueA).localeCompare(String(valueB));
      return this.sortDirection === 'asc' ? compareResult : -compareResult;
    });

    this.dataSource.data = sortedData;
  }

  private isFormattedDate(value: any): boolean {
    if (typeof value !== 'string') return false;
    return /^\d{2}\/\d{2}\/\d{4}$/.test(value);
  }

  private parsePossibleDate(value: string): Date | null {
    try {
      // First check if it's our formatted date (dd/mm/yyyy)
      if (this.isFormattedDate(value)) {
        const [day, month, year] = value.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        return isNaN(date.getTime()) ? null : date;
      }

      // Then try standard date parsing (for original format)
      const date = new Date(value);
      return isNaN(date.getTime()) ? null : date;
    } catch {
      return null;
    }
  }

  private parseLocaleNumber(stringNumber: string): number {
    try {
      // Get the current locale's decimal separator
      const format = new Intl.NumberFormat(this.localStringFormat);
      const parts = format.formatToParts(1.1);
      const decimalSeparator = parts.find(part => part.type === 'decimal')?.value || '.';
      const thousandsSeparator = parts.find(part => part.type === 'group')?.value || ',';

      // Remove thousand separators and normalize decimal separator to '.'
      const normalized = stringNumber.replace(new RegExp(`\\${thousandsSeparator}`, 'g'), '').replace(decimalSeparator, '.');

      return parseFloat(normalized);
    } catch (e) {
      return NaN;
    }
  }

  hasQuery(): boolean {
    return !!this.message?.rawResponse?.attachments?.[0]?.query;
  }

  getQuery(): string {
    return this.message?.rawResponse?.attachments?.[0]?.query?.query || '';
  }

  copyQuery(): void {
    const query = this.getQuery();
    if (!query) return;

    navigator.clipboard.writeText(query).then(
      () => {
        // Opcional: Mostrar un mensaje de éxito usando un snackbar o tooltip
        _log('Query copiado al portapapeles');
      },
      err => {
        console.error('Error al copiar el query:', err);
      }
    );
  }
}
