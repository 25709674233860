import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { _log } from '@shared/aux_helper_environment';
import { _cloneDeep } from '@shared/aux_helper_functions';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexTheme,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import {
  DataBricksAiGenie_ChartAnalysisResult,
  DataBricksAiGenie_ChartAnalysisResult_ChartType,
} from './databricks-genie-ai-query-process-table.component';

type ApexChartType = 'bar' | 'pie' | 'line';

export interface ChartOptions {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  theme: ApexTheme;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  labels: string[];
  colors: any;
  stroke?: {
    curve?: string;
    width?: number;
  };
  markers?: {
    size: number;
    hover: {
      size: number;
    };
  };
}

interface ChartDialogData {
  chartData: DataBricksAiGenie_ChartAnalysisResult['visualization'];
  isDialogMode: boolean;
}

const chartConfig: Omit<ChartOptions, 'series'> = {
  chart: {
    height: 500,
    type: 'bar' as ApexChartType,
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '70%',
    },
    pie: {
      donut: {
        size: '65%',
      },
    },
  },
  xaxis: {
    type: 'category',
    labels: {
      rotate: -45,
      trim: true,
      maxHeight: 100,
    },
  },
  yaxis: {
    labels: {
      formatter: val => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
  },
  theme: {
    mode: 'light',
  },
  title: {
    text: '',
    align: 'center',
  },
  tooltip: {
    y: {
      formatter: val => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    },
  },
  legend: {
    show: true,
    position: 'right',
    fontSize: '14px',
  },
  labels: [],
  colors: ['#007bee', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
};

@Component({
  selector: 'query-process-chart',
  template: `
    <div *ngIf="isDialogMode" class="dialog-header">
      <h1 mat-dialog-title>{{ getChartTitle() }}</h1>
      <div class="dialog-actions">
        <mat-slide-toggle
          *ngIf="showRegistrosToggle"
          [(ngModel)]="showRegistrosData"
          (change)="onToggleChange()"
          class="registros-toggle"
          matTooltip="Mostrar valores agrupados"
        >
          Mostrar valores agrupados (Otros)
        </mat-slide-toggle>
        <button mat-icon-button (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div class="chart-container">
      <div *ngIf="!isUpdating && chartData?.series?.length > 0 && chartConfig?.chart">
        <apx-chart
          #chart
          [series]="chartData.series"
          [chart]="chartConfig.chart"
          [dataLabels]="chartConfig.dataLabels"
          [plotOptions]="chartConfig.plotOptions"
          [xaxis]="chartConfig.xaxis"
          [yaxis]="chartConfig.yaxis"
          [theme]="chartConfig.theme"
          [tooltip]="chartConfig.tooltip"
          [labels]="chartConfig.labels"
          [stroke]="chartConfig.stroke"
          [markers]="chartConfig.markers"
          [colors]="chartConfig.colors"
        ></apx-chart>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        background: #fafafa;
      }

      .dialog-header h1 {
        margin: 0;
        flex: 1;
      }

      .dialog-actions {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .registros-toggle {
        margin: 0;
      }

      .chart-container {
        padding: 16px;
        height: calc(100% - 30px);
        overflow: auto;
        overflow-x: hidden;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataBricksAiGenieQueryProcessChartComponent implements OnInit, AfterViewInit {
  @ViewChild('chart') chart: ChartComponent;

  @Input() chartMetadata: DataBricksAiGenie_ChartAnalysisResult;
  isDialogMode = false;

  chartData: { series: ApexAxisChartSeries | ApexNonAxisChartSeries } = {
    series: [],
  };

  chartConfig = _cloneDeep(chartConfig);

  showRegistrosToggle = false;
  showRegistrosData = true;
  originalSeries: any[] = [];
  registrosColumnIndex = -1;
  isUpdating = false;

  acummRegisterColumnPartialString = 'registros.)';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChartDialogData,
    private dialogRef: MatDialogRef<DataBricksAiGenieQueryProcessChartComponent>,
    private cdRef: ChangeDetectorRef
  ) {
    this.isDialogMode = data?.isDialogMode === true;
  }

  get localStringFormat(): string {
    return navigator.language || navigator.languages[0] || 'default';
  }

  ngOnInit() {
    setTimeout(() => {
      this.initializeChart();
      this.cdRef.detectChanges();
    }, 256);
  }

  ngAfterViewInit() {
    window.addEventListener('resize', () => {
      const newHeight = this.calculateChartHeight();
      if (this.chart?.chart) {
        (this.chart.chart as any).updateOptions({ chart: { height: newHeight } });
      }
    });
  }

  getChartTitle(): string {
    const numericName = this.data.chartData.numericColumnName;
    const labelName = this.data.chartData.labelColumnName;

    return `Gráfico:
    ${this.formatColumnTitle(numericName)} /
    ${this.formatColumnTitle(labelName)}`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private calculateChartHeight(): number {
    const viewportHeight = window.innerHeight;
    const headerHeight = 130;
    const availableHeight = viewportHeight * 0.9 - headerHeight;

    let rv = Math.max(400, availableHeight);

    return rv;
  }

  private initializeChart(): void {
    const { chartType, series } = this.data.chartData;

    // Store original series data
    this.originalSeries = series;

    // Find the registros column index
    this.registrosColumnIndex = series.findIndex(item => item.label.includes(this.acummRegisterColumnPartialString));

    // Check if we need to show the toggle
    this.showRegistrosToggle = chartType === 'horizontalBar' && this.registrosColumnIndex !== -1;

    // Log for debugging
    _log('Series data:', series);
    _log('Registros column index:', this.registrosColumnIndex);

    // Configurar altura dinámica
    this.chartConfig.chart = {
      ...this.chartConfig.chart,
      height: this.calculateChartHeight(),
      type: this.getApexChartType(chartType),
    };

    // Configurar tipo de gráfico
    this.chartConfig.chart.type = this.getApexChartType(chartType);

    // Configurar orientación para barras
    if (chartType === 'horizontalBar') {
      this.chartConfig.plotOptions.bar.horizontal = true;
    }

    // Configurar datos según tipo de gráfico
    if (chartType === 'pie') {
      // Para gráficos tipo pie, los valores van en series y las etiquetas en labels
      this.chartData.series = series.map(item => item.value);
      this.chartConfig.labels = series.map(item => item.label);

      this.chartConfig.chart = {
        ...this.chartConfig.chart,
        type: 'pie' as ApexChartType,
      };

      // Calcular el total y determinar si los valores son porcentajes
      const total = series.reduce((sum, item) => sum + item.value, 0);
      const isPercentageData = Math.abs(total - 100) < 0.1; // Tolerancia de 0.1 para considerar como 100%

      // Configuración específica para pie con porcentajes
      this.chartConfig.plotOptions.pie = {
        donut: {
          size: '65%',
          labels: {
            show: true,
            name: {
              show: true,
              formatter: (val: string) => val,
            },
            value: {
              show: true,
              formatter: (val: string) => {
                const numValue = Number(val);
                if (isPercentageData) return `${numValue.toFixed(1)}%`;

                const percentage = ((numValue / total) * 100).toFixed(1);
                return `${numValue.toLocaleString(this.localStringFormat)} (${percentage}%)`;
              },
            },
          },
        },
      };

      // Configurar tooltip para pie con porcentajes
      this.chartConfig.tooltip = {
        y: {
          formatter: (val: number): string => {
            if (isPercentageData) return `${val.toFixed(1)}%`;

            const percentage = ((val / total) * 100).toFixed(1);
            return `${val.toLocaleString(this.localStringFormat)} (${percentage}%)`;
          },
        },
      };

      // Ajustar leyenda para incluir porcentajes
      this.chartConfig.legend = {
        ...this.chartConfig.legend,
        formatter: (seriesName: string, opts: any) => {
          const value = opts.w.globals.series[opts.seriesIndex];
          if (isPercentageData) {
            return `${seriesName}: ${value.toFixed(1)}%`;
          }
          const percentage = ((value / total) * 100).toFixed(1);
          return `${seriesName}: ${value.toLocaleString(this.localStringFormat)} (${percentage}%)`;
        },
      };
    } else if (chartType === 'time_line') {
      const sortedSeries = series;

      this.chartData.series = [
        {
          name: this.data.chartData.numericColumnName,
          data: sortedSeries.map(item => item.value),
        },
      ];

      this.chartConfig.chart = {
        ...this.chartConfig.chart,
        type: 'line',
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
      };

      // Configuración específica para línea temporal
      this.chartConfig.stroke = {
        curve: 'smooth',
        width: 3, // Ancho de línea más razonable
      };

      this.chartConfig.markers = {
        size: 5,
        hover: {
          size: 7,
        },
      };

      // Configuración mejorada para el eje X
      this.chartConfig.xaxis = {
        type: 'datetime',
        categories: series.map(item => new Date(item.label).getTime()),
        labels: {
          formatter: val => new Date(val).toLocaleDateString(this.localStringFormat),
          style: {
            fontSize: '12px',
          },
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      };

      // Configuración mejorada para el eje Y
      this.chartConfig.yaxis = {
        ...this.chartConfig.yaxis,
        labels: {
          formatter: val => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          style: {
            fontSize: '12px',
          },
        },
      };
    } else {
      // Configuración para gráfico de barras (sin cambios)
      this.updateChartData();
    }

    // Configurar título
    this.chartConfig.title.text = this.getChartTitle();
  }

  onToggleChange(): void {
    this.updateChartData();
    this.cdRef.detectChanges();
  }

  private updateChartData(): void {
    this.isUpdating = true;
    this.cdRef.detectChanges();

    setTimeout(() => {
      let filteredSeries = _cloneDeep(this.originalSeries);

      if (this.registrosColumnIndex !== -1 && !this.showRegistrosData) {
        filteredSeries.splice(this.registrosColumnIndex, 1);
      }

      const newSeries = [
        {
          name: this.data.chartData.numericColumnName,
          data: filteredSeries.map(item => item.value),
        },
      ];

      const newCategories = filteredSeries.map(item => item.label);

      // Crear nueva configuración
      this.chartConfig = {
        ..._cloneDeep(chartConfig),
        chart: {
          ...chartConfig.chart,
          type: 'bar',
          height: this.calculateChartHeight(),
        },
        plotOptions: {
          ...chartConfig.plotOptions,
          bar: {
            horizontal: true,
            columnWidth: '70%',
          },
        },
        xaxis: {
          type: 'category',
          categories: newCategories,
          labels: {
            rotate: -45,
            trim: true,
            maxHeight: 100,
          },
        },
      };

      this.chartData = {
        series: newSeries,
      };

      _log('Chart update with:', {
        filteredLength: filteredSeries.length,
        originalLength: this.originalSeries.length,
        categories: newCategories,
        series: newSeries,
      });

      this.isUpdating = false;
      this.cdRef.detectChanges();
    }, 100);
  }

  private getApexChartType(type: DataBricksAiGenie_ChartAnalysisResult_ChartType): ApexChartType {
    switch (type) {
      case 'pie':
        return 'pie';
      case 'time_line':
        return 'line';
      case 'bar':
      case 'horizontalBar':
        return 'bar';
      default:
        return 'bar';
    }
  }

  private formatColumnTitle(columnName: string): string {
    return (
      columnName
        // Separar por underscore y otros caracteres especiales
        .split(/[_\s]+/)
        // Capitalizar primera letra de cada palabra
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        // Unir con espacios
        .join(' ')
    );
  }
}
