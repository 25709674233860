import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { _logTap, _useDummyData } from '@shared/aux_helper_environment';
import { _get, _handleErrorWithDummyData, _ms } from '@shared/aux_helper_functions';
import { PromotionsV2Service } from 'app/promotionsv2/promotionsv2.service';
import { GenericEntity } from 'core/models/generic-entity.model';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

export enum PromotionActionTypeV2 {
  Discount = 'Discount',
  BuyAPayB = 'BuyAPayB',
  BuyAGetDiscountInXUnit = 'BuyAGetDiscountInXUnit',
  Surcharge = 'Surcharge',
  FixPrice = 'FixPrice',
  Gift = 'Gift',
  Wholesaler = 'Wholesaler',
  ImageCommunication = 'ImageCommunication',
}

const _aux_getGenericTagsTypeOptionsMap = (data): GenericEntity => {
  return data;
};

export const _aux_getEnumStringByIdPromotionTypes = (id, upcase = true) => {
  const _enum = dummyGenericPromotionTypesDataTypeOptions.find(x => x.id === id);
  const trad = _enum.value.toString();
  return upcase ? trad.toUpperCase() : trad;
};

export const _aux_getIdByEnumStringPromotionTypes = value => {
  const _enum = dummyGenericPromotionTypesDataTypeOptions.find(x => x.value === value);
  return _enum.id;
};

export const dummyGenericPromotionTypesDataTypeOptions = [
  { id: 1, value: PromotionActionTypeV2.Discount },
  { id: 2, value: PromotionActionTypeV2.BuyAPayB },
  { id: 3, value: PromotionActionTypeV2.BuyAGetDiscountInXUnit },
  { id: 4, value: PromotionActionTypeV2.Surcharge },
  { id: 5, value: PromotionActionTypeV2.FixPrice },
  { id: 6, value: PromotionActionTypeV2.Gift },
  { id: 7, value: PromotionActionTypeV2.Wholesaler },
  { id: 8, value: PromotionActionTypeV2.ImageCommunication },
];

@Injectable({
  providedIn: 'root',
})
export class GenericPromotionMasterTypeService {
  private configBase = _get(environment, 'apiBaseUrl_prices');
  private config = _get(environment, 'promotionv2.api');
  cachedGenericTagsTypeOptions = null;

  constructor(
    public translate: IanTranslateService,
    private http: HttpClient,
    private permissionService: NgxPermissionsService,
    private promotionsV2Service: PromotionsV2Service
  ) {}

  getGenericTypesOptions(): Observable<Array<GenericEntity>> {
    const withoutService = true;
    this.cachedGenericTagsTypeOptions = withoutService
      ? _aux_getGenericTagsTypeOptionsMap(dummyGenericPromotionTypesDataTypeOptions)
      : this.cachedGenericTagsTypeOptions;

    if (this.cachedGenericTagsTypeOptions) {
      return new Observable(data => {
        data.next(this.cachedGenericTagsTypeOptions);
        data.complete();
      });
    }

    // este endpoint por ahora no va a ser creado se utiliza dummyGenericPromotionTypesDataTypeOptions
    const url = this.configBase + this.config.resources.getDataTypeOptions;
    return this.http.get<Array<GenericEntity>>(url).pipe(
      map(data => _aux_getGenericTagsTypeOptionsMap(data)),
      map(data => (this.cachedGenericTagsTypeOptions = data)),
      tap(data => _logTap(`${GenericPromotionMasterTypeService.name}::getGenericPromotionTypesOptions (tap)\n\tdata: %o`, data)),
      catchError(error => GenericPromotionMasterTypeService.typeOptions(error, this.cachedGenericTagsTypeOptions))
    );
  }

  static typeOptions(error, cachedGenericTagsTypeOptions) {
    const dummyGenericTagsDataTypeOptionsObs = _handleErrorWithDummyData(
      true && _useDummyData(),
      error,
      GenericPromotionMasterTypeService,
      _aux_getGenericTagsTypeOptionsMap(dummyGenericPromotionTypesDataTypeOptions),
      'dummyGenericTagsDataTypeOptions'
    );
    dummyGenericTagsDataTypeOptionsObs.subscribe(data => (cachedGenericTagsTypeOptions = data));
    return dummyGenericTagsDataTypeOptionsObs;
  }

  getItemsTypesOxxo(): Observable<any> {
    return this.promotionsV2Service.getPromotionTypeList().pipe(
      map(data => {
        if (__itemsTypesOxxo == null && data?.length > 0) __itemsTypesOxxo = data;
        return __itemsTypesOxxo;
      })
    );
  }

  getItemspromotionTypeIdByCodeOrId(code): Observable<any> {
    return this.getItemsTypesOxxo().pipe(
      map(data => {
        return data.find(
          item => (item?.id != null && String(item.id) === String(code)) || data.find(item => String(item.code) === String(code))
        );
      })
    );
  }
}

let __itemsTypesOxxo = null;
export const __getItemsTypesOxxo = () => {
  return __itemsTypesOxxo;
};

const itemsTypesOxxoDummy = [
  {
    id: 110,
    value: 'Nacionales',
    name: 'Nacionales',
    code: 'NACIONALES',
    minimumCoverage: null,
    customFields: null,
  },
];
