import { _cloneDeep } from '@shared/aux_helper_functions';
import { GenieStatementResponse } from '../services/databricks-genie-ai-chat.models';
import {
  DataBricksAiGenie_ChartAnalysisResult,
  DataBricksAiGenie_ChartAnalysisResult_ChartType,
} from './databricks-genie-ai-query-process-table.component';

export const isDateString = (value: string): boolean => {
  const datePatterns = [
    // /^\d{1,2}-\d{1,2}-\d{4}$/, // D-M-YYYY o DD-MM-YYYY
    /^\d{4}-\d{1,2}-\d{1,2}$/, // YYYY-M-D o YYYY-MM-DD
    /^\d{1,2}\/\d{1,2}\/\d{4}$/, // D/M/YYYY o DD/MM/YYYY
    /^\d{4}-\d{1,2}-\d{1,2}T\d{2}:\d{2}:\d{2}/, // ISO format con dígitos flexibles
    /^\d{13}$/, // Timestamp
  ];

  // Si coincide con algún patrón, verificar que sea una fecha válida
  if (datePatterns.some(pattern => pattern.test(value))) {
    const date = new Date(value);
    return !isNaN(date.getTime()); // Verificación adicional de fecha válida
  }

  return false;
};

export const determineChartType = (
  seriesData: Array<{ label: string; value: number }>
): DataBricksAiGenie_ChartAnalysisResult_ChartType => {
  // Verificar si las etiquetas son fechas
  const areLabelsDate = seriesData.every(item => {
    let rv = isDateString(item.label);
    return rv;
  });

  if (areLabelsDate) return 'time_line';

  // Resto de la lógica existente
  const recordCount = seriesData.length;
  const maxLabelLength = Math.max(...seriesData.map(item => item.label.length));

  if (recordCount <= 8) {
    return 'pie'; // Gráfico de torta para pocos registros
  } else if (maxLabelLength > 15 || recordCount > 12) {
    return 'horizontalBar'; // Barras horizontales para etiquetas largas o muchos registros
  } else {
    return 'bar'; // Barras verticales como caso por defecto
  }
};

export const isNumericColType = (colType: string): boolean => {
  const type = String(colType || '').toLowerCase();
  return type.includes('int') || type.includes('double') || type.includes('decimal') || type.includes('number');
};

export const getNumericColumns = (queryResponse: GenieStatementResponse, intensiveCheck = true): string[] => {
  const { manifest, result } = queryResponse.statement_response;

  return manifest.schema.columns
    .filter(col => {
      const colType = String(col.type_text || col.type_name).toLowerCase();

      const isNumericType =
        colType.includes('int') || colType.includes('double') || colType.includes('decimal') || colType.includes('number');

      if (!isNumericType) return false;

      if (!intensiveCheck) return true;

      // Si es un tipo numérico declarado, validar los valores
      const colIndex = manifest.schema.columns.findIndex(c => c.name === col.name);

      const columnValues = result.data_typed_array.map(row => {
        const value = row.values[colIndex];
        const numValue = value?.num !== undefined ? Number(value.num) : value?.str !== undefined ? Number(value.str) : NaN;
        return numValue;
      });

      const allNoNaN = columnValues.every(val => !isNaN(val));

      const someNonZero = columnValues.some(val => val !== 0);

      return allNoNaN && someNonZero && isNumericType;
    })
    .map(col => col.name);
};

export const DataBricksAiGenie_AnalyzeSimpleChartData = (
  $queryProcessResult: GenieStatementResponse
): DataBricksAiGenie_ChartAnalysisResult => {
  let queryProcessResult = _cloneDeep($queryProcessResult);

  // Validación inicial
  if (!queryProcessResult?.statement_response?.manifest?.schema || !queryProcessResult?.statement_response?.result) {
    return {
      isVisualizationAvailable: false,
      error: {
        code: 'INVALID_RESPONSE',
        message: 'Respuesta inválida o incompleta',
        details: { queryProcessResult },
      },
    };
  }

  const { manifest, result } = queryProcessResult.statement_response;
  const columns = manifest.schema.columns;

  // Verificar si hay exactamente 2 columnas
  if (columns.length !== 2) {
    return {
      isVisualizationAvailable: false,
      error: {
        code: 'INVALID_COLUMN_COUNT',
        message: `Se esperaban 2 columnas, pero se encontraron ${columns.length}`,
        details: { columns },
      },
    };
  }

  // Encontrar la columna numérica usando la función mejorada
  const numericColumns = getNumericColumns(queryProcessResult);
  let numericColumn = columns.find(col => numericColumns.includes(col.name));
  let labelColumn = columns.find(col => !numericColumns.includes(col.name));

  // Caso especial donde hay solo dos columnas pero las dos son numericas
  if (true && labelColumn == null && numericColumns.length === 2) {
    labelColumn = columns[0];
    numericColumn = columns[1];
  }

  if (!numericColumn || !labelColumn) {
    return {
      isVisualizationAvailable: false,
      error: {
        code: 'INVALID_COLUMN_TYPES',
        message: 'No se encontró una columna numérica válida y una columna de etiquetas',
        details: {
          numericColumns,
          availableColumns: columns.map(c => ({ name: c.name, type: c.type_text })),
          data: result.data_typed_array.slice(0, 3), // Muestra primeros 3 registros
        },
      },
    };
  }

  // Transformar los datos en formato de serie
  let seriesData = result.data_typed_array
    .map(row => {
      const numericValue = row.values[columns.indexOf(numericColumn)];
      const labelValue = row.values[columns.indexOf(labelColumn)];

      let label = labelValue?.str || '';
      // Asegurarnos de convertir el string a número si es necesario
      let valueNumber = numericValue?.num != null ? Number(numericValue.num) : numericValue?.str != null ? Number(numericValue.str) : null;

      return {
        label,
        value: valueNumber,
      };
    })
    .filter(item => item.value != null && !isNaN(item.value) && item.label !== '');

  // Determinar el tipo de gráfico más apropiado
  const chartType = determineChartType(seriesData);

  // Ordenar por valor de mayor a menor
  if (chartType === 'time_line') {
    // Agrupar por fecha y mantener el valor más alto para cada fecha
    const dateMap = new Map<string, number>();
    seriesData.forEach(item => {
      const dateKey = new Date(item.label).toISOString().split('T')[0]; // Normalizar fecha a YYYY-MM-DD

      if (false) {
        const currentValue = dateMap.get(dateKey) || 0;
        dateMap.set(dateKey, Math.max(currentValue, item.value));
      } else {
        const currentSum = dateMap.get(dateKey) || 0;
        dateMap.set(dateKey, currentSum + item.value); // Sumar valores del mismo día
      }
    });

    // Reconstruir seriesData con valores únicos por fecha
    seriesData.length = 0; // Limpiar array original
    Array.from(dateMap.entries())
      .sort((a, b) => a[0].localeCompare(b[0])) // Ordenar por fecha
      .forEach(([dateStr, value]) => {
        seriesData.push({
          label: dateStr,
          value: value,
        });
      });
  } else {
    seriesData = seriesData.sort((a, b) => Number(b.value) - Number(a.value));
  }

  // Solo setear como graficable si hay datos válidos
  if (seriesData.length > 0) {
    const MAX_VISIBLE_RECORDS = 24;

    // Si hay más registros de los permitidos, tomar los primeros N
    if (chartType !== 'time_line' && seriesData.length > MAX_VISIBLE_RECORDS) {
      const originalLength = seriesData.length;
      seriesData = seriesData.slice(0, MAX_VISIBLE_RECORDS);

      // Agregar un elemento "Otros" con la suma del resto
      const remainingSum = result.data_typed_array
        .map(row => {
          const numericValue = row.values[columns.indexOf(numericColumn)];
          return numericValue?.num != null || numericValue?.str != null ? Number(numericValue?.num || numericValue?.str) : 0;
        })
        .sort((a, b) => b - a)
        .slice(MAX_VISIBLE_RECORDS)
        .reduce((sum, value) => sum + value, 0);

      if (remainingSum > 0) {
        seriesData.push({
          label: `Otros (${originalLength - MAX_VISIBLE_RECORDS} registros.)`,
          value: remainingSum,
        });
      }
    }

    return {
      isVisualizationAvailable: true,
      visualization: {
        series: seriesData,
        numericColumnName: numericColumn.name,
        labelColumnName: labelColumn.name,
        chartType: chartType,
      },
    };
  }

  return {
    isVisualizationAvailable: false,
    error: {
      code: 'NO_VALID_DATA',
      message: 'No se encontraron datos válidos para visualizar',
      details: {
        numericColumn,
        labelColumn,
        sampleData: result.data_typed_array.slice(0, 3),
      },
    },
  };
};

export const _formatColumnTitle = (columnName: string): string => {
  return (
    columnName
      // Separar por underscore y otros caracteres especiales
      .split(/[_\s]+/)
      // Capitalizar primera letra de cada palabra
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      // Unir con espacios
      .join(' ')
  );
};
